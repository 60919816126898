import * as t from 'io-ts'
import { HTTPError } from 'ky'
import logger from '../logger'

export type ErrorObject = {
  status: number
  status_name: string
  title: string
  source: string
  date: Date
}

export async function fromApi(
  url: string,
  error: HTTPError,
): Promise<ErrorObject> {
  const source = makeSourceUrl(url)

  logger.error('[HTTP fromApi]', url, error)

  try {
    const data = await error.response.json()

    return {
      status: error.response.status,
      status_name: data.status,
      title: data.message,
      source,
      date: data.date !== undefined ? new Date(data.date) : new Date(),
    }
  } catch {
    return {
      status: 0,
      status_name: 'APPLICATION_ERROR',
      title: 'Unknown error',
      source,
      date: new Date(),
    }
  }
}
export function fromValidation(url: string, _errors: t.Errors): ErrorObject {
  const source = makeSourceUrl(url)

  logger.error('[HTTP fromValidation]', url, _errors)

  return {
    status: -1,
    status_name: 'VALIDATION_ERROR',
    title: 'Validation error',
    source,
    date: new Date(),
  }
}

export function fromUnknown(url: string, _errors: any): ErrorObject {
  const source = makeSourceUrl(url)

  logger.error('[HTTP fromUnknown]', url, _errors)

  return {
    status: 0,
    status_name: 'APPLICATION_ERROR',
    title: 'Unknown error',
    source,
    date: new Date(),
  }
}

function makeSourceUrl(url: string) {
  return new URL(url, window.location.origin).href
}
