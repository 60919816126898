import { UIText } from '@hermes/design-system'
import React, { FC, useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import { Clickable } from '~/components/clickable'
import { Link } from '~/components/link'
import styled from '~/design/styled'
import { useAuth } from '~/shared/contexts/authContext'
import { useAdministrator } from '~/shared/contexts/AdministratorContext'
import { CenteredContentContainer } from '~/components/CenteredContentContainer'
import { Version } from '~/components/version/version'

export const HEADER_HEIGHT = 88

export const Layout: FC = ({ children }) => {
  const {
    data: { currentUser },
  } = useAdministrator()
  const auth = useAuth()
  const history = useHistory()
  const goHome = useCallback(() => {
    history.push('/sites')
  }, [history])
  return (
    <LayoutContainer>
      <Header>
        <TitleContainer onClick={goHome}>
          <TitleRegular>
            FLUX <TitleBold>TIRÉ</TitleBold>
          </TitleRegular>
          <HeaderText>Back-office</HeaderText>
        </TitleContainer>
        <UserContainer>
          <Username>{currentUser.id}</Username>
          {<LogoutLink onClick={auth.logout}>Déconnexion</LogoutLink>}
        </UserContainer>
      </Header>
      <Content>{children}</Content>
      <Footer>
        <Version />
      </Footer>
    </LayoutContainer>
  )
}

const LayoutContainer = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.background,
  fontFamily: theme.fonts.default,
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100%',
  minWidth: '100%',
  position: 'absolute',
}))

const Header = styled.header(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  zIndex: 9999,
  alignItems: 'center',
  backgroundColor: theme.colors.header.background,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: `0px ${theme.layout.l30}`,
  height: HEADER_HEIGHT,
}))

const HeaderText = styled(UIText)(({ theme }) => ({
  color: theme.colors.header.text,
  fontSize: theme.fontSizes.base,
}))

const TitleContainer = styled(Clickable)({
  alignItems: 'baseline',
  display: 'flex',
})

const TitleRegular = styled(HeaderText)(({ theme }) => ({
  fontSize: theme.fontSizes.logo,
}))

const TitleBold = styled(TitleRegular)(({ theme }) => ({
  fontWeight: theme.fontWeights.default.bold,
  marginInlineEnd: theme.layout.l10,
}))

const UserContainer = styled.div({
  alignItems: 'flex-end',
  display: 'flex',
  flexDirection: 'column',
})

const Username = styled(HeaderText)(({ theme }) => ({
  fontWeight: theme.fontWeights.default.medium,
}))

const LogoutLink = styled(Link)(({ theme }) => ({
  color: theme.colors.header.text,
  fontSize: theme.fontSizes.small,
}))

const Content = styled(CenteredContentContainer.withComponent('main'))({
  alignSelf: 'center',
  display: 'flex',
  flexDirection: 'column',
  flex: '1',
  padding: '77px 0',
  marginTop: HEADER_HEIGHT,
})

const Footer = styled.footer(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  padding: `0px ${theme.layout.l30}`,
}))
