import React from 'react'
import { Global, css } from '@emotion/core'
import { ThemeProvider } from 'emotion-theming'
import { theme } from '~/design/theme'

export const DesignSystemProvider: React.FC = ({ children }) => (
  <>
    <ThemeProvider theme={theme}>{children}</ThemeProvider>
    <Global
      styles={css`
        html {
          box-sizing: 'border-box';
        }
        *,
        *:after,
        *:before {
          box-sizing: inherit;
        }
        ${theme.fontImport}
      `}
    />
  </>
)
