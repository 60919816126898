import React, { createContext, useContext, useEffect, useState } from 'react'

import Amplify, { Auth, Hub } from 'aws-amplify'
import getAWSConfig from '~/shared/configs/aws-exports'

const AWSConfig = getAWSConfig()

Amplify.configure(AWSConfig)

const logLevel = sessionStorage.getItem('amplify-log-level')
if (logLevel !== null) {
  Amplify.Logger.LOG_LEVEL = logLevel
}

type AuthContext = {
  logout: () => void
  redirectPath?: string
}
export const AuthContext = createContext<AuthContext | undefined>(undefined)

const localRedirectKey = 'local_redirect'

export const AuthProvider: React.FC = ({ children }) => {
  const [getSession, setSession] = useState(false)
  const [auth, setAuth] = useState<AuthContext | undefined>(undefined)

  useEffect(() => {
    Hub.listen('auth', res => {
      switch (res.payload.event) {
        case 'cognitoHostedUI':
          setSession(true)
          break
        case 'signOut':
          setAuth(undefined)
          break
      }
    })
  }, [])

  useEffect(() => {
    if (AWSConfig !== undefined) {
      Auth.currentAuthenticatedUser()
        .then(() => {
          const redirectPath = localStorage.getItem(localRedirectKey)
          if (redirectPath !== null) {
            localStorage.removeItem(localRedirectKey)
          }
          setAuth({
            logout: () => Auth.signOut(),
            redirectPath: redirectPath || undefined,
          })
        })
        .catch(() => {
          const currentUrl = `${window.location.origin}${window.location.pathname}`.replace(
            /\/$/,
            '',
          )

          const shouldRedirect = [
            window.location.origin,
            AWSConfig.Auth.oauth.redirectSignIn,
            AWSConfig.Auth.oauth.redirectSignOut,
          ].every(url => currentUrl !== url)

          if (shouldRedirect) {
            localStorage.setItem(localRedirectKey, window.location.pathname)
          }

          if (
            !window.location.href.startsWith(
              AWSConfig.Auth.oauth.redirectSignIn,
            )
          ) {
            Auth.federatedSignIn({
              customProvider: AWSConfig.Auth.provider,
            })
          }
        })
    }
  }, [getSession])

  return (
    <AuthContext.Provider value={auth}>
      {auth !== undefined && children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider')
  }

  return context
}
