/*
 * Source: https://github.com/streamich/react-use/blob/master/src/useLockBodyScroll.ts
 */
import { useLayoutEffect } from 'react'

let counter = 0
let originalOverflow: string | null = null

const lock = () => {
  originalOverflow = window.getComputedStyle(document.body).overflow
  document.body.style.overflow = 'hidden'
}

const unlock = () => {
  // @ts-ignore
  document.body.style.overflow = originalOverflow
  originalOverflow = null
}

const increment = () => {
  counter++
  if (counter === 1) {
    lock()
  }
}

const decrement = () => {
  counter--
  if (counter === 0) {
    unlock()
  }
}

const useLockBodyScroll = (enabled: boolean = true) => {
  useLayoutEffect(() => (enabled ? (increment(), decrement) : undefined), [
    enabled,
  ])
}

export default useLockBodyScroll
