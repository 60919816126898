import React from 'react'
import styled from '~/design/styled'
import { LegendText } from '@hermes/design-system'

type DayCellProps = {
  label: string
  status?: 'disabled' | 'highlighted'
  position: 'first' | 'center' | 'last'
}
export const DayCell = ({ label, status, position }: DayCellProps) => (
  <StyledDayCell>
    <DayLabel>{label}</DayLabel>
    <DayCellIndicator status={status} position={position} />
  </StyledDayCell>
)

const StyledDayCell = styled.div({
  display: 'flex',
  flexFlow: 'column nowrap',
  alignItems: 'center',
  overflow: 'hidden',

  '& + & > div': {
    borderLeft: 0,
  },
})
const DayCellIndicator = styled.div<Omit<DayCellProps, 'label'>>(
  ({ status, position, theme }) => ({
    height: '40px',
    width: '100%',
    border: `1px solid ${theme.colors.greys.light20}`,
    borderTopLeftRadius: position === 'first' ? 3 : undefined,
    borderBottomLeftRadius: position === 'first' ? 3 : undefined,
    borderTopRightRadius: position === 'last' ? 3 : undefined,
    borderBottomRightRadius: position === 'last' ? 3 : undefined,
    background: status === 'highlighted' ? theme.colors.orange : undefined,
    backgroundImage:
      status === 'disabled'
        ? `repeating-linear-gradient(40deg,
      transparent,
      transparent 6px,
      ${theme.colors.greys.light20} 6px,
      ${theme.colors.greys.light20} 7px)`
        : undefined,
  }),
)

const DayLabel = styled(LegendText)(({ theme }) => ({
  color: theme.colors.grey,
  marginBottom: theme.space.s30,
}))
