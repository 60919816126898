import { InterpolationWithTheme } from '@emotion/core'
import React, { FC, useState } from 'react'
import Cropper from 'react-easy-crop'

import styled from '~/design/styled'

import { Area } from './ImageCroppingTools'

type Props = Readonly<{
  aspect?: number
  className?: string
  css?: InterpolationWithTheme<any>
  minZoom?: number
  onCropComplete: (croppedArea: Area, croppedAreaPixels: Area) => void
  restrictPosition?: boolean
  source: string
}>

export const ImageCroppingView: FC<Props> = ({
  aspect,
  className,
  css,
  minZoom,
  onCropComplete,
  restrictPosition,
  source,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  return (
    <Container className={className} css={css}>
      <Cropper
        aspect={aspect}
        crop={crop}
        image={source}
        minZoom={minZoom}
        onCropChange={setCrop}
        onCropComplete={onCropComplete}
        onZoomChange={setZoom}
        restrictPosition={restrictPosition}
        showGrid={false}
        zoom={zoom}
      />
    </Container>
  )
}

const Container = styled.div({
  position: 'relative',
})
