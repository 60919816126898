/** @jsx jsx */

import { InterpolationWithTheme, jsx } from '@emotion/core'
import { FC } from 'react'

import { Theme } from '~/design/theme'

export type LinkProps = Readonly<{
  className?: string
  disabled?: boolean
  onClick?: () => void
}>

export const Link: FC<LinkProps> = ({
  children,
  className,
  disabled = false,
  onClick,
}) => (
  <span
    className={className}
    css={css}
    onClick={disabled ? undefined : onClick}
  >
    {children}
  </span>
)

const css: InterpolationWithTheme<Theme> = theme => ({
  cursor: 'pointer',
  fontFamily: theme.fonts.default,
  fontSize: theme.fontSizes.base,
  fontWeight: theme.fontWeights.default.regular,
  textDecoration: 'underline',
})
