import React, { FC, useCallback, useMemo, useState } from 'react'
import styled from '~/design/styled'

import { DeptsArrayType, sortDepartments } from '~/shared/models/models'
import { MaximumQuantityArray } from '~/shared/models/siteProperties'
import { UpdateSitePropertiesFn } from '~/shared/hooks/useSiteProperties'

import { Table, TableCell, TableRow } from '~/components/Table'
import { QuantityIcon } from '~/design/icons/QuantityIcon'
import { ParameterBlockTemplate } from '../ParameterBlockTemplate'
import { UpdateMaximumQuantityPerOfModal } from './UpdateMaximumQuantityPerOfModal'

type Props = Readonly<{
  departments: DeptsArrayType
  maximumQuantityPerOF: MaximumQuantityArray | null
  updateSiteProperties: UpdateSitePropertiesFn
}>

export const MaximumQuantityPerOfBlock: FC<Props> = ({
  departments,
  maximumQuantityPerOF,
  updateSiteProperties,
}) => {
  const [modalVisible, setModalVisible] = useState(false)

  const openModal = useCallback(() => {
    setModalVisible(true)
  }, [])
  const closeModal = useCallback(() => {
    setModalVisible(false)
  }, [])

  const submit = useCallback(
    async (data: MaximumQuantityArray) => {
      await updateSiteProperties({
        maximumQuantityPerOF: data,
        maximumQuantityPerPER: data,
      })
      setModalVisible(false)
    },
    [updateSiteProperties],
  )

  const layout = useMemo(() => [{ flex: 1 }, { marginLeft: 'auto' }], [])

  const sortedDepartments = useMemo(
    () => sortDepartments(departments.departments),
    [departments],
  )

  return (
    <>
      <ParameterBlockTemplate
        buttonLabelType={maximumQuantityPerOF === null ? 'fill' : 'modify'}
        icon={<QuantityIcon />}
        onButtonClick={openModal}
        title="Quantité maximale par OF"
      >
        <Table layout={layout}>
          {sortedDepartments.map(dept => (
            <TableRow key={dept.id}>
              <TableCell>
                <RowLabel>{dept.name}</RowLabel>
              </TableCell>
              <TableCell>
                {getMaximumQuantityLabel(maximumQuantityPerOF)(dept.id)}
              </TableCell>
            </TableRow>
          ))}
        </Table>
      </ParameterBlockTemplate>
      {modalVisible && (
        <UpdateMaximumQuantityPerOfModal
          departments={departments}
          maximumQuantityPerOF={maximumQuantityPerOF}
          onClose={closeModal}
          onSubmit={submit}
        />
      )}
    </>
  )
}

function getMaximumQuantityLabel(
  maximumQuantityPerOF: MaximumQuantityArray | null,
): (deptId: string) => string {
  return deptId =>
    maximumQuantityPerOF
      ?.find(q => q.department.id === deptId)
      ?.value.toString() ?? '-'
}

const RowLabel = styled.div(({ theme }) => ({
  color: theme.colors.text,
  fontWeight: theme.fontWeights.default.medium,
}))
