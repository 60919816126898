import React from 'react'
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom'

import { Toaster } from '~/components/Toaster'
import styled from '~/design/styled'
import SitesScreen from '~/pages/sitesScreen'
import SiteScreen from '~/pages/site/siteScreen'
import { useAuth } from '~/shared/contexts/authContext'

const App: React.FC = () => {
  const auth = useAuth()
  return (
    <AppToaster>
      <Router>
        <Switch>
          <Route path="/sites" component={SitesScreen} />
          <Route path="/site/:id" component={SiteScreen} />
          <Route
            render={() => (
              <Redirect
                to={
                  auth.redirectPath === undefined ? '/sites' : auth.redirectPath
                }
              />
            )}
          />
        </Switch>
      </Router>
    </AppToaster>
  )
}

export default App

const AppToaster = styled(Toaster)(({ theme }) => ({
  bottom: theme.layout.l30,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  left: '0px',
  position: 'fixed',
  right: '0px',
}))
