import React from 'react'
import styled from '~/design/styled'
import {
  PrimaryMediumButton,
  UIText,
  Select,
  LegendText,
} from '@hermes/design-system'
import { Controller, useForm, Control } from 'react-hook-form'
import * as yup from 'yup'

import { Modal } from '~/components/modal'
import { Hint } from '~/components/hint'
import { DeadlineWaterfallStrip } from '~/components/DeadlineWaterfallStrip'
import { DeadlinesType } from '~/shared/models/siteProperties'

const daysInitiallyDisabled = 1
const deadlinesOptions = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
]
const DeadlinesSchema = yup
  .object({
    cutting: yup
      .number()
      .required()
      .moreThan(0),
    supply: yup
      .number()
      .required()
      .moreThan(0),
    picking: yup
      .number()
      .required()
      .moreThan(0),
  })
  .test(
    'total-sum-must-be-lower-than-or-equal-to',
    'La somme des délais doit être inférieure ou égale à 10 jours ouvrés',
    (value: DeadlinesType) =>
      value.cutting + value.supply + value.picking <= 10,
  )

interface Props {
  deadlines: DeadlinesType | null
  onClose: () => void
  onSubmit: (data: DeadlinesType) => Promise<void>
}

const UpdateDeadlinesModal: React.FC<Props> = ({
  deadlines,
  onSubmit,
  onClose,
}) => {
  const { control, handleSubmit, formState, watch } = useForm<DeadlinesType>({
    mode: 'onChange',
    defaultValues: deadlines ?? {
      cutting: 0,
      supply: 0,
      picking: 0,
    },
    validationSchema: DeadlinesSchema,
  })

  const canSubmit =
    formState.dirty && formState.isValid && !formState.isSubmitting
  const submitAndClose = async (data: DeadlinesType) => {
    await onSubmit(data)
    onClose()
  }

  return (
    <Modal
      title={`${deadlines === null ? 'Configurer' : 'Modifier'} les délais`}
      onClose={formState.isSubmitting ? undefined : onClose}
    >
      <Hint>
        La somme des délais doit être inférieure ou égale à 10 jours ouvrés
      </Hint>

      <WaterfallDeadlinesForm onSubmit={handleSubmit(submitAndClose)}>
        <HintText>Dans cet exemple, nous sommes lundi.</HintText>
        {/* Coupe */}
        <DeadlineDaySelect
          gridArea="select-cutting"
          id="select-cutting"
          name="cutting"
          label="Délai coupe (en jours)"
          control={control}
        />
        <DeadlineWaterfallStripInGrid
          gridArea="strip-cutting"
          numberOfFiveDayWeeks={3}
          disabledDaysCount={daysInitiallyDisabled}
          highlightedDaysCount={watch('cutting')}
        />
        {/* Stock */}
        <DeadlineDaySelect
          gridArea="select-supply"
          id="select-supply"
          name="supply"
          label="Délai stock (en jours)"
          control={control}
          disabled={watch('cutting') === 0}
        />
        <DeadlineWaterfallStripInGrid
          gridArea="strip-supply"
          numberOfFiveDayWeeks={3}
          disabledDaysCount={watch('cutting') + daysInitiallyDisabled}
          highlightedDaysCount={watch('supply')}
        />
        {/* Ramassage */}
        <DeadlineDaySelect
          gridArea="select-picking"
          id="select-picking"
          name="picking"
          label="Délai ramassage (en jours)"
          control={control}
          disabled={watch('supply') === 0}
        />
        <DeadlineWaterfallStripInGrid
          gridArea="strip-picking"
          numberOfFiveDayWeeks={3}
          disabledDaysCount={
            watch('cutting') + watch('supply') + daysInitiallyDisabled
          }
          highlightedDaysCount={watch('picking')}
        />
        <Footer>
          <PrimaryMediumButton disabled={!canSubmit}>
            Valider
          </PrimaryMediumButton>
        </Footer>
      </WaterfallDeadlinesForm>
    </Modal>
  )
}

const WaterfallDeadlinesForm = styled.form(() => ({
  display: 'grid',
  gridTemplateAreas: `
  "empty hint"
  "select-cutting strip-cutting"
  "select-supply strip-supply"
  "select-picking strip-picking"
  "footer footer"
  `,
  gridTemplateColumns: '200px 1fr',
  gridColumnGap: '35px',
  gridRowGap: '20px',
  marginTop: '30px',
}))

const HintText = styled(UIText)(({ theme }) => ({
  gridArea: 'hint',
  color: theme.colors.grey,
}))

type DeadlineSelectProps = {
  gridArea: string
  id: string
  name: string
  label: string
  control: Control<DeadlinesType>
  disabled?: boolean
}
const DeadlineDaySelect = ({
  gridArea,
  id,
  name,
  label,
  control,
  disabled,
}: DeadlineSelectProps) => {
  return (
    <StyledDeadlineSelect gridArea={gridArea}>
      <SelectLabel>{label}</SelectLabel>
      <Controller
        as={
          <StyledSelect
            id={id}
            placeholder="Sélectionnez"
            options={deadlinesOptions}
            onChange={() => {}}
            isClearable={false}
            isDisabled={disabled}
          />
        }
        control={control}
        name={name}
        disabled={disabled}
      />
    </StyledDeadlineSelect>
  )
}

const StyledSelect = styled(Select)({
  height: '40px',
  '& > div:first-of-type': { height: '100%' },
})

const StyledDeadlineSelect = styled.div<{ gridArea: string }>(
  ({ gridArea }) => ({
    display: 'flex',
    flexFlow: 'column nowrap',
    gridArea,
  }),
)

const SelectLabel = styled(LegendText)(({ theme }) => ({
  color: theme.colors.grey,
  marginBottom: theme.space.s30,
}))

const DeadlineWaterfallStripInGrid = styled(DeadlineWaterfallStrip)<{
  gridArea: string
}>(({ gridArea }) => ({
  gridArea,
}))

const Footer = styled.div(({ theme }) => ({
  gridArea: 'footer',
  display: 'flex',
  justifyContent: 'center',
  marginTop: theme.space.s80,

  '& > *': {
    flex: 1,
    maxWidth: '220px',
  },
}))
export default UpdateDeadlinesModal
