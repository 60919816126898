import React from 'react'
import styled from '~/design/styled'
import { PrimaryLargeButton, Title2 } from '@hermes/design-system'

type ParameterBlockTemplateProps = {
  title: string
  icon: JSX.Element
  onButtonClick: () => void
  buttonLabelType: 'delete' | 'fill' | 'modify'
}

export const ParameterBlockTemplate: React.FC<ParameterBlockTemplateProps> = ({
  title,
  icon,
  onButtonClick,
  buttonLabelType,
  children,
}) => {
  const buttonLabel = {
    delete: 'Supprimer',
    fill: 'Renseigner',
    modify: 'Modifier',
  }[buttonLabelType]
  return (
    <StyledParameterBlockTemplate>
      <ParameterBlockHeader>
        {icon}
        <BlockTitle>{title}</BlockTitle>
        <ModalButton onClick={onButtonClick}>{buttonLabel}</ModalButton>
      </ParameterBlockHeader>
      {children}
    </StyledParameterBlockTemplate>
  )
}

const StyledParameterBlockTemplate = styled.div(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexFlow: 'column nowrap',
  marginTop: theme.layout.l40,
}))

const ParameterBlockHeader = styled.header(({ theme }) => ({
  display: 'flex',
  flexFlow: 'row nowrap',
  alignItems: 'center',
  marginBottom: theme.layout.l20,
}))

const BlockTitle = styled(Title2)(({ theme }) => ({
  fontSize: '18px',
  marginLeft: theme.layout.l10,
}))

const ModalButton = styled(PrimaryLargeButton)({
  marginLeft: 'auto',
})
