import React, { FC } from 'react'

import styled from '~/design/styled'

type Props = Readonly<{
  className?: string
  color?: string
}>

export const ColorDot: FC<Props> = ({ className, color = 'black' }) => (
  <View className={className} color={color} />
)

const size = '32px'

const View = styled.div<Pick<Props, 'color'>>(({ color }) => ({
  backgroundColor: color,
  borderRadius: '100%',
  height: size,
  width: size,
}))
