export function makeArrayfromRange(start: number, end: number) {
  const array = []
  for (let index = start; index <= end; index += 1) {
    array.push(index)
  }
  return array
}

export function countBy<T extends number | string>(array: T[]): number[] {
  const countObject = array.reduce((countObject, value) => {
    if (countObject.hasOwnProperty(value)) {
      return {
        ...countObject,
        [value]: countObject[value] + 1,
      }
    } else {
      return {
        ...countObject,
        [value]: 1,
      }
    }
  }, {} as Record<T, number>)
  return Object.values(countObject)
}

// asyncMap(el, els) handle each element in sequence
// whereas Promise.all(els.map(el)) handle them all in parallel
export async function asyncMap<A, B>(fn: (el: A) => Promise<B>, els: A[]): Promise<B[]> {
  if (els.length === 0) {
    return Promise.resolve([])
  }
  const el = els[0]
  return [await fn(el), ...await asyncMap(fn, els.slice(1))]
}