import { PrimaryMediumButton } from '@hermes/design-system'
import React, { FC, useMemo } from 'react'
import { Controller, DeepPartial, useForm } from 'react-hook-form'
import { array, object, string } from 'yup'

import { Radio, RadioGroup } from '~/components/Radio'
import { Label } from '~/components/inputs'
import { Modal, ModalProps } from '~/components/modal'
import styled from '~/design/styled'
import { DeptsArrayType, sortDepartments } from '~/shared/models/models'
import {
  MaterialCallStep,
  MaterialCallStepValue,
} from '~/shared/models/siteProperties'

import {
  getMaterialCallStepLabel,
  getMaterialCallStepValues,
} from './materialCallStep'

type Props = Readonly<
  Pick<ModalProps, 'onClose'> & {
    departments: DeptsArrayType
    materialCallStep: MaterialCallStep | null
    onSubmit: (data: MaterialCallStep) => Promise<void>
  }
>

type FormData = Readonly<{
  materialCallStep: ReadonlyArray<string | undefined>
}>

const validationSchema = object<FormData>({
  materialCallStep: array(string()).test(
    'no-undefined',
    "Vous devez spécifier toutes les étapes d'appel de matière",
    (value: FormData['materialCallStep']) => !value.includes(undefined),
  ),
})

export const UpdateMaterialCallStepModal: FC<Props> = ({
  departments,
  materialCallStep,
  onClose,
  onSubmit: propsOnSubmit,
}) => {
  const { control, formState, handleSubmit } = useForm<FormData>({
    defaultValues: getDefaultValues(departments, materialCallStep),
    mode: 'onChange',
    validationSchema,
  })
  const onSubmit = (data: FormData) =>
    propsOnSubmit(transformFormData(departments, data))
  const sortedDepartments = useMemo(
    () => sortDepartments(departments.departments),
    [departments],
  )
  return (
    <Modal
      onClose={formState.isSubmitting ? undefined : onClose}
      title={`${
        materialCallStep === null ? 'Configurer' : 'Modifier'
      } le responsable de l'Appel Matière`}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        {sortedDepartments.map((dept, index) => (
          <Field key={dept.id}>
            <Label>{`Département ${dept.name.toLocaleLowerCase()}`}</Label>
            <Controller
              as={
                <FieldRadioGroup
                  key={`mat-call-step-${dept.id}`}
                  name={`mat-call-step-${dept.id}`}
                >
                  {getMaterialCallStepValues().map(m => (
                    <FieldRadio
                      id={`mat-call-step-${dept.id}-${m}`}
                      key={`mat-call-step-${dept.id}-${m}`}
                      label={getMaterialCallStepLabel(m)}
                      value={m}
                    />
                  ))}
                </FieldRadioGroup>
              }
              control={control}
              name={`materialCallStep[${index}]`}
            />
          </Field>
        ))}
        <SubmitButton
          disabled={
            !formState.dirty || !formState.isValid || formState.isSubmitting
          }
        >
          Valider
        </SubmitButton>
      </Form>
    </Modal>
  )
}

function getDefaultValues(
  departments: DeptsArrayType,
  materialCallStep: MaterialCallStep | null,
): DeepPartial<FormData> {
  return {
    materialCallStep: departments.departments.map(
      dept => materialCallStep?.find(q => q.department.id === dept.id)?.value,
    ),
  }
}

function transformFormData(
  departments: DeptsArrayType,
  data: FormData,
): MaterialCallStep {
  return departments.departments.map((dept, index) => ({
    department: { id: dept.id },
    value: data.materialCallStep[index] as MaterialCallStepValue,
  }))
}

const Form = styled.form({
  display: 'flex',
  flexDirection: 'column',
})

const Field = styled.div(({ theme }) => ({
  borderBlockEnd: `1px solid ${theme.colors.greys.light25}`,
  '&:not(:last-of-type)': {
    marginBlockEnd: theme.layout.l35,
  },
  '& > :first-of-type': {
    marginBlockEnd: theme.space.s55,
  },
}))

const FieldRadioGroup = styled(RadioGroup)({
  '& .radio-group-content': {
    justifyContent: 'flex-start',
  },
})

const FieldRadio = styled(Radio)({
  flex: 1,
})

const SubmitButton = styled(PrimaryMediumButton)(({ theme }) => ({
  alignSelf: 'center',
  marginBlockStart: theme.layout.l55,
  width: '220px',
}))
