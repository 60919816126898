import React from 'react'

type Props = {
  fill?: string
  className?: string
}
const SitesIcon: React.FC<Props> = ({ className, fill = '#ffffff' }) => (
  <svg
    width="19"
    height="21"
    viewBox="0 0 19 21"
    fill={fill}
    className={className}
  >
    <defs>
      <path
        id="siteIcon"
        d="M8.47 0c4.28 0 7.742 3.551 7.803 7.875 0 2.81-1.245 5.868-3.704 9.049-1.705 2.195-3.382 3.661-3.641 3.892l-.033.03A.633.633 0 0 1 8.5 21a.633.633 0 0 1-.395-.154c-.09-.062-1.882-1.575-3.674-3.922C1.972 13.743.727 10.685.727 7.875.727 3.551 4.19 0 8.47 0zm0 1.235c-3.613 0-6.528 2.996-6.528 6.64 0 5.25 5.07 10.346 6.528 11.674 1.457-1.359 6.528-6.424 6.528-11.674 0-3.644-2.915-6.64-6.528-6.64zM8.5 3.5c2.381 0 4.318 1.937 4.318 4.359 0 2.421-1.937 4.391-4.318 4.391-2.381 0-4.318-1.937-4.318-4.359C4.182 5.47 6.119 3.5 8.5 3.5zm0 1.313c-1.669 0-3.023 1.371-3.023 3.062 0 1.69 1.354 3.063 3.023 3.063s3.023-1.372 3.023-3.063c0-1.69-1.354-3.063-3.023-3.063z"
      />
    </defs>
    <use
      fill="inherit"
      fillRule="evenodd"
      transform="translate(1)"
      xlinkHref="#siteIcon"
    />
  </svg>
)

export default SitesIcon
