import React, { FC } from 'react'

type Props = Readonly<{
  className?: string
}>

export const FailureIcon: FC<Props> = ({ className }) => (
  <svg
    className={className}
    fill="white"
    height="24"
    viewBox="0 0 23 24"
    width="23"
  >
    <defs>
      <path
        d="M12.383 12.383v5.734a.883.883 0 0 1-1.766 0v-5.734H4.883a.883.883 0 0 1 0-1.766h5.734V4.883a.883.883 0 0 1 1.766 0v5.734h5.734a.883.883 0 0 1 0 1.766h-5.734z"
        id="failure-icon"
      />
    </defs>
    <use
      fill="inherit"
      fillRule="nonzero"
      transform="rotate(45 11.5 11.5)"
      xlinkHref="#failure-icon"
    />
  </svg>
)
