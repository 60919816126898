import React, {
  ReactElement,
  ReactNode,
  createContext,
  useContext,
  useMemo,
} from 'react'

import useHttp from '~/shared/apis/useHttp'

import {
  getSiteConfiguration,
  getSiteConfigurationMutators,
} from './siteConfiguration'
import { SiteConfiguration } from '~/shared/contexts/SiteConfigurationContext/siteConfigurationTypes'
import { BackOfficeUser } from '~/shared/models/backOfficeUser'
import { ScreenLoader } from '~/components/screenLoader'

type SiteConfigurationProviderProps = Readonly<{
  children: ReactNode
  siteId: string
  status: BackOfficeUser['status']
}>

const SiteConfigurationContext = createContext<SiteConfiguration | undefined>(
  undefined,
)

export function SiteConfigurationProvider({
  children,
  siteId,
  status,
}: SiteConfigurationProviderProps): ReactElement {
  const { data, error, mutate } = useHttp(
    ['configuration', siteId],
    (_, siteId) => getSiteConfiguration(siteId, status),
  )

  const value = useMemo(() => {
    if (data !== undefined) {
      return {
        data,
        mutators: getSiteConfigurationMutators(siteId, mutate, data),
        siteId,
      }
    } else {
      return undefined
    }
  }, [data, mutate, siteId])

  if (error !== undefined) {
    return (
      <div>
        Une erreur s'est produite lors de la récupération de la configuration du
        site
      </div>
    ) // TODO: improve error handling
  } else if (value === undefined) {
    return <ScreenLoader />
  } else {
    return (
      <SiteConfigurationContext.Provider value={value}>
        {children}
      </SiteConfigurationContext.Provider>
    )
  }
}

export function useSiteConfiguration(): SiteConfiguration {
  const context = useContext(SiteConfigurationContext)
  if (context === undefined) {
    throw new Error(
      'useSiteConfiguration must be used within a SiteConfigurationProvider',
    )
  }
  return context
}
