import { responseInterface } from 'swr'
import { ErrorObject } from '~/shared/apis/http-errors'

import {
  getAdmins,
  getDepartments,
  getMaterials,
  getModels,
  getWorkers,
  getWorkshops,
  addAdmin,
  createModel,
  updateModel,
  deleteAdmin,
  deleteModel,
  updateWorker,
  updateWorkshop,
  uploadPicture as apiUploadPicture,
  synchronizeWorkers,
  synchronizeWorkshops,
} from '~/shared/apis/routes'
import {
  SiteConfigurationData,
  SiteConfigurationMutators,
} from '~/shared/contexts/SiteConfigurationContext/siteConfigurationTypes'
import { BackOfficeUser } from '~/shared/models/backOfficeUser'

export async function getSiteConfiguration(
  siteId: string,
  status: BackOfficeUser['status'],
) {
  const [
    admins,
    departments,
    materials,
    models,
    workers,
    workshops,
  ] = await Promise.all([
    status === 'superadmin'
      ? getAdmins(siteId)
      : Promise.resolve({ admins: [] }),
    getDepartments(),
    getMaterials(),
    getModels(siteId),
    getWorkers(siteId),
    getWorkshops(siteId),
  ])

  return {
    admins,
    departments,
    materials,
    models,
    workers,
    workshops,
  }
}

export function getSiteConfigurationMutators(
  siteId: string,
  mutate: responseInterface<SiteConfigurationData, ErrorObject>['mutate'],
  data: SiteConfigurationData,
): SiteConfigurationMutators {
  return {
    addAdmin: async adminId => {
      await addAdmin(siteId, adminId)
      mutate(
        {
          ...data,
          admins: {
            admins: data.admins.admins.concat({ id: adminId }),
          },
        },
        false,
      )
    },
    deleteAdmin: async adminId => {
      const admins = await deleteAdmin(siteId, adminId)
      mutate(
        {
          ...data,
          admins,
        },
        false,
      )
    },
    createModel: async model => {
      const pictureId = await uploadPicture(model.pictureId)
      const newModel = await createModel(siteId, { ...model, pictureId })

      mutate(
        {
          ...data,
          models: {
            ...data.models,
            designs: data.models.designs.concat(newModel),
          },
        },
        false,
      )
    },
    deleteModel: async modelId => {
      await deleteModel(siteId, modelId)
      mutate(
        {
          ...data,
          models: {
            ...data.models,
            designs: data.models.designs.filter(d => d.id !== modelId),
          },
        },
        false,
      )
    },
    updateModel: async (modelId, model) => {
      const prevPictureId = data.models.designs.find(m => m.id === modelId)
        ?.pictureId
      const nextPictureId = await uploadPicture(model.pictureId, prevPictureId)
      const newModel = await updateModel(siteId, modelId, {
        ...model,
        pictureId: nextPictureId,
      })
      const newModels = data.models.designs.map(m =>
        m.id === modelId ? newModel : m,
      )

      mutate(
        {
          ...data,
          models: {
            ...data.models,
            designs: newModels,
          },
        },
        false,
      )
    },
    updateWorker: async (workerId, worker) => {
      const prevPictureId = data.workers.workers.find(w => w.id === workerId)
        ?.pictureId
      const nextPictureId = await uploadPicture(worker.pictureId, prevPictureId)
      const newWorker = await updateWorker(workerId, {
        ...worker,
        pictureId: nextPictureId,
      })
      const newWorkers = data.workers.workers.map(w =>
        w.id === workerId ? newWorker : w,
      )
      mutate(
        {
          ...data,
          workers: {
            ...data.workers,
            workers: newWorkers,
          },
        },
        false,
      )
    },
    updateWorkshop: async (workshopId, workshop) => {
      const prevPictureId = data.workshops.workshops.find(
        w => w.id === workshopId,
      )?.pictureId
      const nextPictureId = await uploadPicture(
        workshop.pictureId,
        prevPictureId,
      )
      const newWorkshop = await updateWorkshop(workshopId, {
        ...workshop,
        pictureId: nextPictureId,
      })
      const newWorkshops = data.workshops.workshops.map(w =>
        w.id === workshopId ? newWorkshop : w,
      )
      mutate(
        {
          ...data,
          workshops: {
            ...data.workshops,
            workshops: newWorkshops,
          },
        },
        false,
      )
    },
    synchronizeWorkers: async () => {
      mutate({ ...data, workers: await synchronizeWorkers(siteId) }, false)
    },
    synchronizeWorkshops: async () => {
      mutate({ ...data, workshops: await synchronizeWorkshops(siteId) }, false)
    },
  }
}

async function uploadPicture(
  nextPictureUrl: string | null,
  prevPictureId?: string | null,
): Promise<string | null> {
  if (nextPictureUrl === null) {
    return null
  } else if (
    typeof prevPictureId === 'string' &&
    nextPictureUrl.includes(prevPictureId)
  ) {
    return prevPictureId
  } else {
    const nextPictureBlob = await (await fetch(nextPictureUrl)).blob()
    return (await apiUploadPicture(nextPictureBlob)).id
  }
}
