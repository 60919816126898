import React, { FC, ReactNode } from 'react'

import styled from '~/design/styled'

type Props = Readonly<{
  className?: string
  placeholder: ReactNode
  source: string | undefined
}>

export const Emblem: FC<Props> = ({ className, placeholder, source }) =>
  source === undefined ? (
    <PlaceholderContainer className={className}>
      {placeholder}
    </PlaceholderContainer>
  ) : (
    <Img alt="" className={className} src={source} />
  )

const size = '44px'

const PlaceholderContainer = styled.div(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.colors.greys.light25,
  borderRadius: '100%',
  display: 'flex',
  flexDirection: 'row',
  height: size,
  justifyContent: 'center',
  width: size,
  '> *': {
    width: '60%',
  },
}))

const Img = styled.img({
  borderRadius: '100%',
  height: size,
  width: size,
})
