import React, { useState, useMemo } from 'react'
import styled from '~/design/styled'
import { useTheme, Theme } from '~/design/theme'
import DayPicker, { DateUtils, DayPickerProps } from 'react-day-picker'
import DatePickerNavigation from '~/components/datepicker/DatePickerNavigation'
import YearMonthSelector from '~/components/datepicker/YearMonthSelector'

const locale = 'fr'
const WEEKDAYS_SHORT = {
  fr: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
}
const FIRST_DAY_OF_WEEK = {
  fr: 1,
}
const MONTHS = {
  fr: [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre',
  ],
}
const NUMBER_OF_SELECTABLE_YEARS = 1

type MultipleDatePickerProps = {
  selectedDays?: Date[]
  onChange?: (date?: Date[]) => void
}
export const MultipleDatePicker = ({
  selectedDays,
  onChange,
}: MultipleDatePickerProps) => {
  const currentMonth = new Date()
  const fromMonth = new Date(currentMonth.getFullYear(), 0)
  const toMonth = new Date(
    currentMonth.getFullYear() + NUMBER_OF_SELECTABLE_YEARS,
    11,
  )

  const theme = useTheme()
  const modifiersStyles = useMemo(() => makeModifierStyles(theme), [theme])

  const initialMonth =
    selectedDays !== undefined
      ? selectedDays[selectedDays.length]
      : currentMonth

  const [displayedMonth, setDisplayedMonth] = useState<Date>(initialMonth)

  const handleDayClick: DayPickerProps['onDayClick'] = (
    day,
    { selected, disabled },
  ) => {
    if (disabled) {
    } else if (selected && selectedDays) {
      const selectedIndex = selectedDays.findIndex(selectedDay =>
        DateUtils.isSameDay(selectedDay, day),
      )
      const daysWithDeselectedDay = selectedDays.filter(
        (_, index) => index !== selectedIndex,
      )
      onChange?.(daysWithDeselectedDay)
    } else {
      onChange?.([...(selectedDays || []), day])
    }
  }

  return (
    <DatePicker
      locale={locale}
      weekdaysShort={WEEKDAYS_SHORT[locale]}
      firstDayOfWeek={FIRST_DAY_OF_WEEK[locale]}
      months={MONTHS[locale]}
      fromMonth={fromMonth}
      toMonth={toMonth}
      selectedDays={selectedDays}
      disabledDays={[{ daysOfWeek: [0, 6] }]}
      month={displayedMonth}
      onMonthChange={setDisplayedMonth}
      modifiersStyles={modifiersStyles}
      onDayClick={handleDayClick}
      navbarElement={DatePickerNavigation}
      captionElement={props => (
        <YearMonthSelector
          {...props}
          onChange={setDisplayedMonth}
          fromMonth={fromMonth}
          toMonth={toMonth}
        />
      )}
    />
  )
}

const DatePicker = styled(DayPicker)(({ theme }) => ({
  color: theme.colors.black,
  fontFamily: theme.fonts.default,
  fontWeight: theme.fontWeights.default.regular,
  fontSize: `${theme.fontSizes.ui} !important`,

  '& .DayPicker-wrapper': {
    outline: 'none',
    paddingBottom: 0,
  },

  '& .DayPicker-Month': {
    margin: 0,
  },

  '& .DayPicker-Weekday': {
    fontSize: 'inherit',
    padding: `${theme.spaces.xsmall} ${theme.spaces.medium}`,
  },

  '& .DayPicker-Week': {
    borderSpacing: theme.spaces.xxsmall,
  },

  '& .DayPicker-Day': {
    padding: theme.spaces.medium,
    cursor: 'pointer',
    outline: 'none',
  },

  '&:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover': {
    backgroundColor: `${theme.colors.greys.light20} !important`,
  },

  '& .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside)': {
    color: `${theme.colors.white} !important`,
    zIndex: 0,

    '&::before': {
      content: "''",
      position: 'absolute',
      top: 4,
      right: 4,
      bottom: 4,
      left: 4,
      backgroundColor: theme.colors.orange,
      borderRadius: '50%',
      zIndex: -1,
    },
  },
}))

const makeModifierStyles = (theme: Theme) => ({
  today: {
    color: theme.colors.orange,
  },
  hover: {
    backgroundColor: theme.colors.greys.light30,
  },
  selected: {
    backgroundColor: 'transparent',
    color: theme.colors.white,
  },
  disabled: {
    color: theme.colors.greys.light30,
    cursor: 'initial',
  },
})
