import React, { FC } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { PrimaryMediumButton } from '@hermes/design-system'
import { object, string } from 'yup'
import styled from '~/design/styled'
import { Label } from '~/components/inputs/Label'
import { RadioGroup, Radio } from '~/components/Radio/index'

type Props = Readonly<{
  onSubmit: (data: FormData) => void
  nbCuttingZones?: number
}>
export type FormData = Readonly<{
  nbCuttingZones: string
}>

const validationSchema = object<FormData>({
  nbCuttingZones: string().required(),
})

export const NbCuttingZonesForm: FC<Props> = ({ onSubmit, nbCuttingZones }) => {
  const { handleSubmit, control, formState } = useForm<FormData>({
    mode: 'onChange',
    defaultValues: {
      nbCuttingZones: nbCuttingZones?.toString(),
    },
    validationSchema,
  })

  const maxCuttingZones = 4
  const radioArray = Array.from(new Array(maxCuttingZones))

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <StyledLabel>Nombre de zones de coupe</StyledLabel>
      <BorderRow>
        <Controller
          name="nbCuttingZones"
          control={control}
          as={
            <RadioGroup className="radio-group" name="nbCuttingZones">
              {radioArray.map((_radioValue, index) => (
                <Radio
                  key={index + 1}
                  id={`nbCuttingZones${index + 1}`}
                  label={`${index + 1} zone${index + 1 > 1 ? 's' : ''}`}
                  value={(index + 1).toString()}
                />
              ))}
            </RadioGroup>
          }
        />
      </BorderRow>
      <StyledButton
        disabled={
          (nbCuttingZones === undefined && !formState.dirty) ||
          !formState.isValid ||
          formState.isSubmitting
        }
      >
        Suivant
      </StyledButton>
    </Form>
  )
}

const Form = styled.form({
  display: 'flex',
  flexDirection: 'column',
})

const BorderRow = styled.div(({ theme }) => ({
  borderBottom: `1px solid ${theme.colors.greys.light20}`,
  padding: `${theme.space.s30} 0`,
  marginBottom: theme.space.s80,
}))

const StyledLabel = styled(Label)(({ theme }) => ({
  marginBottom: theme.space.s30,
}))

const StyledButton = styled(PrimaryMediumButton)(({ theme }) => ({
  alignSelf: 'center',
  width: '34.16%',
  marginTop: theme.space.s80,
}))
