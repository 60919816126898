/** @jsx jsx */

import { InterpolationWithTheme, jsx } from '@emotion/core'
import { Theme } from '@hermes/design-system'
import { CloseIcon, UIText } from '@hermes/design-system'
import { FC, MouseEvent, useCallback, useRef } from 'react'

import { Clickable } from '~/components/clickable'
import BackIcon from '~/design/icons/backIcon'
import styled from '~/design/styled'
import useLockBodyScroll from '~/shared/hooks/useLockBodyScroll'

export type ModalProps = Readonly<{
  backdrop?: boolean
  onClose?: () => void
  size?: Size
  title: string
  onBack?: () => void
}>

type Size = 'small' | 'large'

export const Modal: FC<ModalProps> = ({
  backdrop = true,
  children,
  onClose,
  size = 'large',
  title,
  onBack,
}) => {
  useLockBodyScroll()

  const paperRef = useRef<HTMLDivElement>(null)
  const onBackdropClick = useCallback(
    (event: MouseEvent) => {
      if (
        onClose !== undefined &&
        !paperRef.current?.contains(event.target as Node)
      ) {
        onClose()
      }
    },
    [onClose],
  )

  return (
    <Backdrop onClick={onBackdropClick} visible={backdrop}>
      <Paper ref={paperRef} size={size}>
        <Header size={size}>
          <div>
            {onBack && (
              <Clickable css={css} onClick={onBack}>
                <BackIconStyled />
              </Clickable>
            )}
            <Title size={size}>{title}</Title>
          </div>
          {onClose && (
            <Clickable onClick={onClose}>
              <CloseButton />
            </Clickable>
          )}
        </Header>
        <Content>{children}</Content>
      </Paper>
    </Backdrop>
  )
}

const css: InterpolationWithTheme<Theme> = theme => ({
  display: 'inline-block',
})

const BackIconStyled = styled(BackIcon)(({ theme }) => ({
  marginRight: theme.space.s40,
  fill: theme.colors.black,
  display: 'inline-block',
}))

const Backdrop = styled.div<Readonly<{ visible: boolean }>>(
  {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    zIndex: 99999,
  },
  ({ theme, visible }) => ({
    backgroundColor: visible ? theme.colors.backdrop : theme.colors.transparent,
  }),
)

const Paper = styled.div<Readonly<{ size: Size }>>(
  {
    display: 'flex',
    flexDirection: 'column',
  },
  ({ size, theme }) => {
    const padding = {
      large: `${theme.layout.l45} ${theme.layout.l55}`,
      small: theme.layout.l45,
    }[size]
    const width = {
      large: '800px',
      small: '600px',
    }[size]
    return {
      backgroundColor: theme.colors.white,
      border: `solid 1px ${theme.colors.greys.light20}`,
      borderRadius: theme.borderRadius.small,
      boxShadow: theme.boxShadows.modal,
      padding,
      width,
    }
  },
)

const Header = styled.div<Readonly<{ size: Size }>>(
  {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  ({ size, theme }) => {
    const marginBottom = {
      large: theme.layout.l35,
      small: theme.layout.l30,
    }[size]
    return {
      marginBottom,
    }
  },
)

const Title = styled(UIText)<Readonly<{ size: Size }>>(({ size, theme }) => {
  const fontSize = {
    large: theme.fontSizes.h1,
    small: theme.fontSizes.h2,
  }[size]
  return {
    color: theme.colors.text,
    fontSize,
    fontWeight: theme.fontWeights.default.medium,
  }
})

const CloseButton = styled(CloseIcon)(({ theme }) => ({
  fill: theme.colors.greys.grey,
  height: '18px',
  width: '18px',
}))

const Content = styled.div({
  display: 'flex',
  flexDirection: 'column',
})
