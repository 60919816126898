import * as t from 'io-ts'
import { DateFromISOString } from 'io-ts-types/lib/DateFromISOString'

export const Workshop = t.strict({
  craft: t.string,
  id: t.string,
  pictureUrl: t.union([t.null, t.string]),
  site: t.strict({
    id: t.string,
  }),
})

export const WorkshopsArray = t.strict({
  workshops: t.array(Workshop),
})

export const CuttingZone = t.strict({
  id: t.number,
  workshops: t.array(
    t.strict({
      id: t.string,
    }),
  ),
})

export const CuttingZonesArray = t.array(CuttingZone)

export const Deadlines = t.strict({
  cutting: t.number,
  picking: t.number,
  supply: t.number,
})

export type DeadlinesType = t.TypeOf<typeof Deadlines>

export const ClosedDates = t.array(DateFromISOString)
export type ClosedDatesType = t.TypeOf<typeof ClosedDates>

const MaximumQuantityArray = t.readonlyArray(
  t.readonly(
    t.strict({
      department: t.strict({
        id: t.string,
      }),
      value: t.number,
    }),
  ),
)
export type MaximumQuantityArray = t.TypeOf<typeof MaximumQuantityArray>

const MaterialCallStepValue = t.keyof({ CUTTING: null, TABLE: null })
export type MaterialCallStepValue = t.TypeOf<typeof MaterialCallStepValue>

const MaterialCallStep = t.readonlyArray(
  t.readonly(
    t.strict({
      department: t.strict({ id: t.string }),
      value: MaterialCallStepValue,
    }),
  ),
)
export type MaterialCallStep = t.TypeOf<typeof MaterialCallStep>

const FlowTypesValue = t.keyof({ SAWHORSE: null, BOX: null })
export type FlowTypesValue = t.TypeOf<typeof FlowTypesValue>

const FlowTypes = t.array(
  t.strict({
    material: t.strict({
      id: t.string,
    }),
    value: FlowTypesValue,
  }),
)

export type FlowTypes = t.TypeOf<typeof FlowTypes>

const SitePropertiesObject = {
  closedDates: t.union([ClosedDates, t.null]),
  cuttingZones: t.union([t.array(CuttingZone), t.null]),
  deadlines: t.union([Deadlines, t.null]),
  flowTypes: t.union([FlowTypes, t.null]),
  materialCallStep: t.union([MaterialCallStep, t.null]),
  maximumQuantityPerOF: t.union([MaximumQuantityArray, t.null]),
  maximumQuantityPerPER: t.union([MaximumQuantityArray, t.null]),
  workshopManagerCredentials: t.union([t.string, t.null]),
  isCompleted: t.boolean,
}
export const SiteProperties = t.strict(SitePropertiesObject)
export type SitePropertiesType = t.TypeOf<typeof SiteProperties>
export const PartialSiteProperties = t.partial(SitePropertiesObject)
export type PartialSiteProperties = t.TypeOf<typeof PartialSiteProperties>

export type WorkshopType = t.TypeOf<typeof Workshop>
export type WorkshopsArrayType = t.TypeOf<typeof WorkshopsArray>
export type CuttingZoneType = t.TypeOf<typeof CuttingZone>
export type CuttingZonesArrayType = t.TypeOf<typeof CuttingZonesArray>

export function sortCuttingZones(
  cuttingZones: CuttingZonesArrayType,
): CuttingZonesArrayType {
  return cuttingZones
    .map(cz => ({
      ...cz,
      workshops: cz.workshops.slice().sort((a, b) => b.id.localeCompare(a.id)),
    }))
    .sort((a, b) => a.id - b.id)
}
