import React, { FC, useState, useCallback } from 'react'

import { DepartmentsList } from '~/components/models/departmentsList'
import { AddingBtn } from '~/components/addingBtn'
import { useSiteConfiguration } from '~/shared/contexts/SiteConfigurationContext'
import { ModelCreationModal } from '~/components/models/ModelCreationModal'
import { useToaster } from '~/components/Toaster'
import { CreateModelType } from '~/shared/models/models'

const ModelsDeptsScreen: FC = () => {
  const {
    data: { departments, materials },
    mutators: { createModel },
    siteId,
  } = useSiteConfiguration()
  const { showToast } = useToaster()

  const [modelCreationModalVisible, setModelCreationModalVisible] = useState(
    false,
  )

  const addingModel = () => {
    setModelCreationModalVisible(true)
  }

  const sendingModel = useCallback(
    async (model: CreateModelType) => {
      try {
        await createModel(model)
        setModelCreationModalVisible(false)
        showToast({
          intent: 'success',
          message: 'Votre modèle a bien été ajouté',
        })
      } catch (error) {
        setModelCreationModalVisible(false)
        showToast({
          intent: 'failure',
          message: "Votre modèle n'a pu être ajouté",
        })
      }
    },
    [createModel, showToast],
  )

  return (
    <>
      <AddingBtn label="Ajouter un modèle" onAdding={() => addingModel()} />
      {modelCreationModalVisible && (
        <ModelCreationModal
          departments={departments}
          materials={materials}
          onClose={() => {
            setModelCreationModalVisible(false)
          }}
          onSubmit={sendingModel}
          submitButtonText="Ajouter"
          title="Nouveau modèle"
        />
      )}
      <DepartmentsList {...departments} siteId={siteId} />
    </>
  )
}

export default ModelsDeptsScreen
