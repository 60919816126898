import React, { useMemo, useState } from 'react'
import styled from '~/design/styled'
import { Table, TableCell, TableRow } from '~/components/Table'
import { ParameterBlockTemplate } from '../ParameterBlockTemplate'
import { CuttingZonesModal } from './cuttingZonesModal'
import {
  CuttingZonesArrayType,
  sortCuttingZones,
} from '~/shared/models/siteProperties'
import { WorkshopsArray } from '~/shared/models/models'
import CuttingZonesIcon from '~/design/icons/cuttingZonesIcon'
import { Workshop } from '~/shared/models/models'
import { UpdateSitePropertiesFn } from '~/shared/hooks/useSiteProperties'

type CuttingZonesProps = {
  workshops: WorkshopsArray
  cuttingZones: CuttingZonesArrayType | null
  updateSiteProperties: UpdateSitePropertiesFn
}

export const CuttingZonesBlock = ({
  workshops,
  cuttingZones,
  updateSiteProperties,
}: CuttingZonesProps) => {
  const [isModalOpen, setModalState] = useState<boolean>(false)

  const onClose = () => setModalState(false)

  const onSubmitAssociation = (cuttingZones: CuttingZonesArrayType) =>
    updateSiteProperties({ cuttingZones })

  const getWorkshopName = (workshopId: string) => {
    return workshops.workshops.find(
      (workshop: Workshop) => workshop.id === workshopId,
    )?.name
  }

  const sortedCuttingZones = useMemo(
    () => sortCuttingZones(cuttingZones ?? []),
    [cuttingZones],
  )

  return (
    <div>
      <ParameterBlockTemplate
        title="Binômes Coupe/Table"
        icon={<CuttingZonesIconStyled />}
        onButtonClick={() => setModalState(true)}
        buttonLabelType={cuttingZones === null ? 'fill' : 'modify'}
      >
        <StyledTable layout={[{ flex: 1 }, { marginLeft: 'auto' }]}>
          <TableRow>
            <TableCell>
              <RowLabel>Nombre de zones de coupe</RowLabel>
            </TableCell>
            <TableCell>
              {cuttingZones && cuttingZones.length > 0
                ? `${cuttingZones.length} zone${
                    cuttingZones.length > 1 ? 's' : ''
                  }`
                : '-'}
            </TableCell>
          </TableRow>
        </StyledTable>
        {sortedCuttingZones && (
          <Table layout={[{ flex: 2 }, { flex: 1 }]}>
            {sortedCuttingZones.map(item => {
              return (
                <TableRow key={item.id}>
                  <TableCellStyled>
                    <CuttingDot cuttingId={item.id}></CuttingDot>
                    &nbsp;Zone de coupe C{item.id}
                  </TableCellStyled>
                  <TableCell>
                    <WorkshopList>
                      {item.workshops.map(workshop => (
                        <div key={workshop.id}>
                          {getWorkshopName(workshop.id)}
                        </div>
                      ))}
                    </WorkshopList>
                  </TableCell>
                </TableRow>
              )
            })}
          </Table>
        )}
      </ParameterBlockTemplate>

      {isModalOpen && (
        <CuttingZonesModal
          onSubmitAssociation={onSubmitAssociation}
          cuttingZones={cuttingZones}
          onClose={onClose}
          workshops={workshops}
        />
      )}
    </div>
  )
}

const StyledTable = styled(Table)(({ theme }) => ({
  marginBottom: theme.space.s60,
}))
const TableCellStyled = styled(TableCell)(({ theme }) => ({
  color: theme.colors.text,
  fontWeight: theme.fontWeights.default.medium,
}))

const RowLabel = styled.div(({ theme }) => ({
  fontWeight: theme.fontWeights.default.medium,
  color: theme.colors.text,
}))

const WorkshopList = styled.div({
  display: 'flex',
  width: '100%',

  '& > div:last-of-type': {
    marginLeft: 'auto',
  },
})

const CuttingZonesIconStyled = styled(CuttingZonesIcon)(({ theme }) => ({
  fill: theme.colors.orange,
}))

const getCuttingDotColor = (id: number) => {
  switch (id) {
    case 1:
      return '#2f7f9d'
    case 2:
      return '#f9b126'
    case 3:
      return '#42b52f'
    case 4:
      return '#8d3472'
    default:
      return '#000000'
  }
}

const CuttingDot = styled.div<{ cuttingId: number }>(
  ({ theme, cuttingId }) => ({
    borderRadius: '50%',
    width: '12px',
    height: '12px',
    marginRight: theme.space.s30,
    backgroundColor: getCuttingDotColor(cuttingId),
  }),
)
