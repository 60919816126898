import React, { FC, useMemo, useState } from 'react'

import { Table, TableCell, TableRow } from '~/components/Table'
import { MaterialCallStepIcon } from '~/design/icons/MaterialCallStepIcon'
import styled from '~/design/styled'
import { DeptsArrayType, sortDepartments } from '~/shared/models/models'
import { MaterialCallStep } from '~/shared/models/siteProperties'
import { UpdateSitePropertiesFn } from '~/shared/hooks/useSiteProperties'

import { ParameterBlockTemplate } from '../ParameterBlockTemplate'
import { getMaterialCallStepLabel } from './materialCallStep'
import { UpdateMaterialCallStepModal } from './UpdateMaterialCallStepModal'

type Props = Readonly<{
  departments: DeptsArrayType
  materialCallStep: MaterialCallStep | null
  updateSiteProperties: UpdateSitePropertiesFn
}>

export const MaterialCallStepBlock: FC<Props> = ({
  departments,
  materialCallStep,
  updateSiteProperties,
}) => {
  const [modalVisible, setModalVisible] = useState(false)

  const openModal = () => {
    setModalVisible(true)
  }
  const closeModal = () => {
    setModalVisible(false)
  }

  const submit = async (data: MaterialCallStep) => {
    updateSiteProperties({ materialCallStep: data })
    setModalVisible(false)
  }

  const sortedDepartments = useMemo(
    () => sortDepartments(departments.departments),
    [departments],
  )

  return (
    <>
      <ParameterBlockTemplate
        buttonLabelType={materialCallStep === null ? 'fill' : 'modify'}
        icon={<MaterialCallStepIcon />}
        onButtonClick={openModal}
        title="Responsable de l'Appel Matière"
      >
        <Table layout={[{ flex: 1 }, { marginLeft: 'auto' }]}>
          {sortedDepartments.map(dept => (
            <TableRow key={dept.id}>
              <TableCell>
                <RowLabel>{dept.name}</RowLabel>
              </TableCell>
              <TableCell>
                {getMaterialCallStepLabelOrDefault(materialCallStep, dept.id)}
              </TableCell>
            </TableRow>
          ))}
        </Table>
      </ParameterBlockTemplate>
      {modalVisible && (
        <UpdateMaterialCallStepModal
          departments={departments}
          materialCallStep={materialCallStep}
          onClose={closeModal}
          onSubmit={submit}
        />
      )}
    </>
  )
}

function getMaterialCallStepLabelOrDefault(
  materialCallStep: MaterialCallStep | null,
  deptId: string,
): string {
  const value = materialCallStep?.find(q => q.department.id === deptId)?.value
  return value === undefined ? '-' : getMaterialCallStepLabel(value)
}

const RowLabel = styled.div(({ theme }) => ({
  color: theme.colors.text,
  fontWeight: theme.fontWeights.default.medium,
}))
