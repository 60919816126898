export type Area = Readonly<{
  height: number
  width: number
  x: number
  y: number
}>

type Size = Readonly<{
  height: number
  width: number
}>

export function createImage(url: string): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => {
      resolve(image)
    })
    image.addEventListener('error', error => {
      reject(error)
    })
    image.src = url
  })
}

export function cropImage(
  image: HTMLImageElement,
  crop: Area,
  destSize: Size = crop,
  fillStyle?: CanvasFillStrokeStyles['fillStyle'],
): Promise<Blob> {
  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')
  canvas.width = destSize.width
  canvas.height = destSize.height
  if (context !== null) {
    if (fillStyle !== undefined) {
      context.fillStyle = fillStyle
      context.fillRect(0, 0, canvas.width, canvas.height)
    }
    context.drawImage(
      image,
      crop.x,
      crop.y,
      crop.width,
      crop.height,
      0,
      0,
      destSize.width,
      destSize.height,
    )
  }
  return new Promise((resolve, reject) => {
    canvas.toBlob(
      blob => {
        if (blob === null) {
          reject()
        } else {
          resolve(blob)
        }
      },
      'image/jpeg',
      1,
    )
  })
}
