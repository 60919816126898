import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import AppProviders from '~/shared/contexts/appProviders'

const AppRoot = () => {
  return (
    <AppProviders>
      <App />
    </AppProviders>
  )
}

ReactDOM.render(<AppRoot />, document.getElementById('root'))
