import React, { FC, useMemo } from 'react'

import { Table, TableCell, TableHeader, TableRow } from '~/components/Table'
import { Clickable } from '~/components/clickable'
import { Emblem } from '~/components/Emblem'
import EditIcon from '~/design/icons/editIcon'
import { WorkshopIcon } from '~/design/icons/WorkshopIcon'
import styled from '~/design/styled'
import { Workshop, WorkshopsArray, sortWorkshops } from '~/shared/models/models'
import { CuttingZonesArrayType } from '~/shared/models/siteProperties'

type Props = Readonly<{
  editWorkshop: (workshop: Workshop) => void
  workshops: WorkshopsArray
  cuttingZones?: CuttingZonesArrayType | null
}>

export const WorkshopsList: FC<Props> = ({
  editWorkshop,
  workshops,
  cuttingZones,
}) => {
  const layout = useMemo(
    () => [{ flex: 3 }, { flex: 6 }, { flex: 1, justifyContent: 'flex-end' }],
    [],
  )
  const sortedWorkshops = useMemo(() => sortWorkshops(workshops.workshops), [
    workshops,
  ])
  return (
    <Table layout={layout}>
      <TableHeader>
        <TableCell>Atelier</TableCell>
        <TableCell>Zone de coupe</TableCell>
        <TableCell>Action</TableCell>
      </TableHeader>
      {sortedWorkshops.map(w => (
        <TableRow key={w.id}>
          <TableCell>
            <EmblemStyled
              placeholder={<WorkshopIcon />}
              source={w.pictureUrl ?? undefined}
            />
            <WorkshopName>{w.name}</WorkshopName>
          </TableCell>
          <TableCell>{formatCuttingZone(cuttingZones, w.id)}</TableCell>
          <TableCell>
            <Clickable
              onClick={() => {
                editWorkshop(w)
              }}
            >
              <EditIconStyled />
            </Clickable>
          </TableCell>
        </TableRow>
      ))}
    </Table>
  )
}

function formatCuttingZone(
  cuttingZones: CuttingZonesArrayType | null | undefined,
  workshopId: string,
): string {
  const cuttingZone = cuttingZones?.find(cz =>
    cz.workshops.find(w => w.id === workshopId),
  )
  return cuttingZone === undefined ? '-' : `C${cuttingZone.id}`
}

const EmblemStyled = styled(Emblem)(({ theme }) => ({
  flexShrink: 0,
  marginInlineEnd: theme.space.s40,
}))

const WorkshopName = styled.span(({ theme }) => ({
  color: theme.colors.text,
  fontWeight: theme.fontWeights.default.medium,
  textTransform: 'uppercase',
}))

const EditIconStyled = styled(EditIcon)(({ theme }) => ({
  fill: theme.colors.black,
  flexShrink: 0,
}))
