import React from 'react'
import styled from '~/design/styled'
import { PrimaryMediumButton } from '@hermes/design-system'
import AddIcon from '~/design/icons/addIcon'

interface props {
  className?: string
  label: string
  onAdding: () => void
  disabled?: boolean
}

export const AddingBtn: React.FC<props> = ({
  className,
  onAdding,
  disabled = false,
  label,
}) => {
  return (
    <AddingBtnStyled className={className}>
      <PrimaryMediumButtonStyled
        onClick={() => onAdding()}
        iconLeft={<AddIcon />}
        disabled={disabled}
      >
        {label}
      </PrimaryMediumButtonStyled>
    </AddingBtnStyled>
  )
}

const AddingBtnStyled = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  marginBlockEnd: theme.layout.l20,
  marginBlockStart: theme.layout.l35,
}))

const PrimaryMediumButtonStyled = styled(PrimaryMediumButton)(({ theme }) => ({
  paddingLeft: theme.space.s50,
}))
