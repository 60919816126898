import React from 'react'
import styled from '~/design/styled'
import { NavbarElementProps } from 'react-day-picker'

import { ChevronIcon } from '@hermes/design-system'
import { Clickable } from '~/components/clickable'

const noop = () => {}
type Props = {} & NavbarElementProps
const DatePickerNavigation = ({
  className,
  onPreviousClick = noop,
  onNextClick = noop,
  showPreviousButton,
  showNextButton,
}: Props) => {
  return (
    <StyledNavigation className={className}>
      {showPreviousButton && (
        <Clickable onClick={() => onPreviousClick()}>
          <ArrowIcon direction="left" />
        </Clickable>
      )}
      {showNextButton && (
        <Clickable onClick={() => onNextClick()}>
          <ArrowIcon direction="right" degree={180} />
        </Clickable>
      )}
    </StyledNavigation>
  )
}

const StyledNavigation = styled.nav({
  position: 'relative',
  display: 'block',
})

const ArrowIcon = styled(ChevronIcon)<{ direction: 'left' | 'right' }>(
  ({ direction, theme }) => ({
    width: 24,
    height: 24,
    fill: theme.colors.greys.light30,
    cursor: 'pointer',
    position: 'absolute',
    [direction]: theme.spaces.xsmall,
    top: 3,

    '&:hover': {
      fill: theme.colors.orange,
    },
  }),
)

export default DatePickerNavigation
