import React from 'react'

type Props = {
  fill?: string
  className?: string
}
const BackIcon: React.FC<Props> = ({ className, fill = '#ffffff' }) => (
  <svg
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill={fill}
    className={className}
  >
    <path
      fill="#inherit"
      fillRule="nonzero"
      d="M18.745 8.448H3.525l6.563-6.59c.42-.421.42-1.121 0-1.542-.419-.421-.977-.421-1.396 0L.314 8.729c-.419.42-.419 1.121 0 1.542l8.378 8.413c.419.421 1.116.421 1.535 0 .42-.42.42-.981 0-1.402l-6.562-6.73h15.219c.558 0 1.116-.42 1.116-1.121-.278-.562-.697-1.123-1.255-.983z"
    />
  </svg>
)

export default BackIcon
