import React, { useState } from 'react'
import styled from '~/design/styled'

import { UpdateSitePropertiesFn } from '~/shared/hooks/useSiteProperties'
import { Table, TableCell, TableRow } from '~/components/Table'
import { ParameterBlockTemplate } from '../ParameterBlockTemplate'
import UpdateDeadlinesModal from './UpdateDeadlinesModal'
import { DeadlinesType } from '~/shared/models/siteProperties'
import DeadlinesIcon from '~/design/icons/deadlineIcon'

type DeadlinesBlockProps = {
  deadlines: DeadlinesType | null
  updateSiteProperties: UpdateSitePropertiesFn
}

export const DeadlinesBlock = ({
  deadlines,
  updateSiteProperties,
}: DeadlinesBlockProps) => {
  const [isModalOpen, setModalState] = useState<boolean>(false)
  const closeModal = () => setModalState(false)

  const handleDelaysSubmit = (data: DeadlinesType) =>
    updateSiteProperties({ deadlines: data })

  return (
    <>
      <ParameterBlockTemplate
        title="Délais"
        icon={<DeadlinesIcon />}
        onButtonClick={() => setModalState(true)}
        buttonLabelType={deadlines === null ? 'fill' : 'modify'}
      >
        <Table layout={[{ flex: 1 }, { marginLeft: 'auto' }]}>
          <TableRow>
            <TableCell>
              <RowLabel>Délai coupe</RowLabel>
            </TableCell>
            <TableCell>{getDayLabel(deadlines?.cutting)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <RowLabel>Délai stock</RowLabel>
            </TableCell>
            <TableCell>{getDayLabel(deadlines?.supply)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <RowLabel>Délai ramassage</RowLabel>
            </TableCell>
            <TableCell>{getDayLabel(deadlines?.picking)}</TableCell>
          </TableRow>
        </Table>
      </ParameterBlockTemplate>

      {isModalOpen && (
        <UpdateDeadlinesModal
          deadlines={deadlines}
          onSubmit={handleDelaysSubmit}
          onClose={closeModal}
        />
      )}
    </>
  )
}

const RowLabel = styled.div(({ theme }) => ({
  fontWeight: theme.fontWeights.default.medium,
  color: theme.colors.text,
}))

function getDayLabel(value?: number): string {
  if (value && value > 1) {
    return `${value} jours`
  } else if (value && value <= 1) {
    return `${value} jour`
  } else {
    return '-'
  }
}
