import React from 'react'

import PlaceholderModelIcon from '~/design/icons/placeholderModelIcon'
import PlaceholderFashionIcon from '~/design/icons/placeholderFashionIcon'
import PlaceholderHorseIcon from '~/design/icons/placeholderHorseIcon'
import PlaceholderBeltIcon from '~/design/icons/placeholderBeltIcon'
import PlaceholderLeatherGoodsIcon from '~/design/icons/placeholderLeatherGoodsIcon'

type Props = Readonly<{
  deptId: string
}>

export const ModelPlaceholder: React.FC<Props> = ({ deptId }) => {
  switch (deptId) {
    case 'ACCESSOIRES_DE_MODE':
      return <PlaceholderFashionIcon />
    case 'EQUITATION':
      return <PlaceholderHorseIcon />
    case 'CEINTURES':
      return <PlaceholderBeltIcon />
    case 'PETITES_MAROQUINERIES':
      return <PlaceholderLeatherGoodsIcon />
    case 'SACS_ET_BAGGAGES':
      return <PlaceholderModelIcon />
    default:
      return <PlaceholderModelIcon />
  }
}
