import React from 'react'
import styled from '~/design/styled'
import { Modal } from '~/components/modal'
import {
  PrimaryMediumButton,
  SecondaryMediumButton,
} from '@hermes/design-system'

type Props = {
  onCloseModal: () => void
  onDelAdmin: (adminId: string) => void
  delAdminId: string
}

const RemoveAdminModal: React.FC<Props> = ({
  onCloseModal,
  onDelAdmin,
  delAdminId,
}) => {
  return (
    <Modal title="Retirer l'administrateur">
      <p>Êtes-vous sûr de vouloir retirer l'administrateur {delAdminId} ?</p>
      <RowCenterStyled>
        <SecondaryMediumButtonStyled onClick={() => onCloseModal()}>
          Annuler
        </SecondaryMediumButtonStyled>
        <PrimaryMediumButton
          onClick={() => {
            onDelAdmin(delAdminId)
            onCloseModal()
          }}
        >
          Retirer
        </PrimaryMediumButton>
      </RowCenterStyled>
    </Modal>
  )
}

const RowCenterStyled = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: theme.space.s80,
}))

const SecondaryMediumButtonStyled = styled(SecondaryMediumButton)(
  ({ theme }) => ({
    marginRight: theme.spaces.medium,
  }),
)

export default RemoveAdminModal
