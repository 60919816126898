import React from 'react'

import { AdministratorProvider } from '~/shared/contexts/AdministratorContext'
import { AuthProvider } from '~/shared/contexts/authContext'
import { DesignSystemProvider } from '~/shared/contexts/designSystemContext'

const AppProviders: React.FC = ({ children }) => (
  <DesignSystemProvider>
    <AuthProvider>
      <AdministratorProvider>{children}</AdministratorProvider>
    </AuthProvider>
  </DesignSystemProvider>
)

export default AppProviders
