import React from 'react'
import styled from '~/design/styled'
import { Modal } from '~/components/modal'
import {
  PrimaryMediumButton,
  SecondaryMediumButton,
} from '@hermes/design-system'

type Props = {
  onCloseModal: () => void
  onDelModel: (adminId: string) => void
  delModelData: {
    id: string
    name: string
  }
}

const RemoveModelModal: React.FC<Props> = ({
  onCloseModal,
  onDelModel,
  delModelData,
}) => {
  return (
    <Modal title="Supprimer un modèle">
      <p>
        Êtes-vous sûr·e de vouloir supprimer le modèle {delModelData.name} ?
      </p>
      <RowCenterStyled>
        <SecondaryMediumButtonStyled onClick={() => onCloseModal()}>
          Annuler
        </SecondaryMediumButtonStyled>
        <PrimaryMediumButton
          onClick={() => {
            onDelModel(delModelData.id)
            onCloseModal()
          }}
        >
          Supprimer
        </PrimaryMediumButton>
      </RowCenterStyled>
    </Modal>
  )
}

const RowCenterStyled = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: theme.space.s80,
}))

const SecondaryMediumButtonStyled = styled(SecondaryMediumButton)(
  ({ theme }) => ({
    marginRight: theme.spaces.medium,
  }),
)

export default RemoveModelModal
