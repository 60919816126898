import React, { FC, useMemo } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { PrimaryMediumButton } from '@hermes/design-system'
import styled from '~/design/styled'
import { Table } from '~/components/Table'
import { Hint } from '../../hint'
import { WorkshopsArray, sortWorkshops } from '~/shared/models/models'
import { WorkshopRow } from './workshopRow'
import { TableHead } from './tableHead'
import { CuttingZonesArrayType } from '~/shared/models/siteProperties'
import {
  associateCuttingZonesAndWorkshops,
  CuttingZoneIdByWorkshopId,
  makeCuttingZonesIdByWorkshopIdSchema,
} from './cuttingZonesLogic'

type Props = Readonly<{
  onSubmit: (data: CuttingZoneIdByWorkshopId) => Promise<void>
  nbCuttingZones: number
  workshops: WorkshopsArray
  cuttingZones: CuttingZonesArrayType | null
}>

export const CuttingZonesWorkshopAssociationForm: FC<Props> = ({
  onSubmit,
  nbCuttingZones,
  workshops,
  cuttingZones,
}) => {
  const { handleSubmit, control, formState } = useForm<
    CuttingZoneIdByWorkshopId
  >({
    mode: 'onChange',
    defaultValues: associateCuttingZonesAndWorkshops(
      workshops,
      cuttingZones,
      nbCuttingZones,
    ),
    validationSchema: makeCuttingZonesIdByWorkshopIdSchema(
      workshops.workshops.map(workshop => workshop.id),
      nbCuttingZones,
    ),
  })

  const cuttingZonesArray = useMemo(
    () => Array.from(new Array(nbCuttingZones)),
    [nbCuttingZones],
  )

  const generateTablelayout = () => {
    const cuttingZonesCells = cuttingZonesArray.map(() => {
      return { flex: 1, justifyContent: 'center', display: 'flex' }
    })
    return [
      { flex: 2, justifyContent: 'flex-start', display: 'flex' },
      ...cuttingZonesCells,
      { flex: 1, justifyContent: 'center', display: 'flex' },
    ]
  }

  const sortedWorkshops = useMemo(() => sortWorkshops(workshops.workshops), [
    workshops,
  ])

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <InfoLabelText>Binômes Coupe/Table</InfoLabelText>
        <Hint>Minimum 1 et maximum 2 atelier(s) par zone de coupe</Hint>
      </Row>
      <StyledTable layout={generateTablelayout()}>
        <TableHead nbCell={nbCuttingZones} />
        <ScrollContainer>
          {sortedWorkshops.map(workshop => (
            <Controller
              key={workshop.id}
              name={workshop.id}
              control={control}
              as={
                <WorkshopRow
                  workshopName={workshop.name}
                  workshopId={workshop.id}
                  nbCuttingZones={nbCuttingZones}
                />
              }
            />
          ))}
        </ScrollContainer>
      </StyledTable>
      <StyledButton disabled={!formState.isValid || formState.isSubmitting}>
        Valider
      </StyledButton>
    </Form>
  )
}

const StyledTable = styled(Table)({
  boxShadow: 'inherit',
})

const Row = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.space.s30,
  '> *': {
    flex: 1,
  },
}))

const InfoLabelText = styled.div(({ theme }) => ({
  fontSize: theme.fontSizes.legend,
  fontWeight: theme.fontWeights.default.medium,
  color: theme.colors.grey,
}))

const Form = styled.form({
  display: 'flex',
  flexDirection: 'column',
})

const ScrollContainer = styled.div({
  maxHeight: '400px',
  overflowY: 'auto',
  background: `
		/* Shadow covers */
		linear-gradient(white 20%, rgba(255,255,255,0)),
		linear-gradient(rgba(255,255,255,0), white 80%) 0 100%,

		/* Shadows */
		radial-gradient(farthest-side at 50% 0, rgba(0,0,0,.1), rgba(0,0,0,0)),
		radial-gradient(farthest-side at 50% 100%, rgba(0,0,0,.1), rgba(0,0,0,0)) 0 100%`,
  backgroundRepeat: 'no-repeat',
  backgroundColor: 'white',
  backgroundSize: '100% 20px, 100% 20px, 100% 7px, 100% 7px',

  backgroundAttachment: 'local, local, scroll, scroll',
})
const StyledButton = styled(PrimaryMediumButton)(({ theme }) => ({
  alignSelf: 'center',
  width: '34.16%',
  marginTop: theme.space.s80,
}))
