import { ChevronIcon } from '@hermes/design-system'
import React, { FC, useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import { Table, TableCell, TableRow } from '~/components/Table'
import styled from '~/design/styled'
import {
  DeptType,
  DeptsArrayType,
  sortDepartments,
} from '~/shared/models/models'

type DepartmentsListProps = Readonly<DeptsArrayType & { siteId: string }>

export const DepartmentsList: FC<DepartmentsListProps> = ({
  departments,
  siteId,
}) => {
  const layout = useMemo(() => [{ flex: 1 }, {}], [])
  const sortedDepartments = useMemo(() => sortDepartments(departments), [
    departments,
  ])
  return (
    <Table layout={layout}>
      {sortedDepartments.map(dept => (
        <DepartmentsListItem dept={dept} key={dept.id} siteId={siteId} />
      ))}
    </Table>
  )
}

type DepartmentsListItemProps = Readonly<{
  dept: DeptType
  siteId: string
}>

const DepartmentsListItem: FC<DepartmentsListItemProps> = ({
  dept,
  siteId,
}) => {
  const history = useHistory()
  const handleClick = useCallback(() => {
    history.push(`/site/${siteId}/models-departments/${dept.id}/models`)
  }, [dept, history, siteId])
  return (
    <TableRow onClick={handleClick}>
      <TableCell>
        <DeptImg alt={dept.shortname} src={dept.pictureUrl} />
        <DeptName>{dept.name}</DeptName>
      </TableCell>
      <TableCell>
        <ChevronIconStyled degree={180} />
      </TableCell>
    </TableRow>
  )
}

const DeptImg = styled.img(({ theme }) => ({
  flexShrink: 0,
  height: '44px',
  marginInlineEnd: theme.space.s40,
  width: '44px',
}))

const DeptName = styled.span(({ theme }) => ({
  color: theme.colors.text,
  fontWeight: theme.fontWeights.default.medium,
}))

const ChevronIconStyled = styled(ChevronIcon)(({ theme }) => ({
  fill: theme.colors.greys.light30,
}))
