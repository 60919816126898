import { del, fetch, get, post, put } from '~/shared/apis/http'
import { BackOfficeUser } from '~/shared/models/backOfficeUser'
import { PictureId } from '~/shared/models/misc'
import {
  CreateModelType,
  DeptsArrayType,
  MaterialsArray,
  ModelsArray,
  Model,
  UpdateWorker,
  UpdateWorkshop,
  Worker,
  WorkersArray,
  Workshop,
  WorkshopsArray, BOWorker,
} from '~/shared/models/models'
import { AdminsType } from '~/shared/models/site'
import { SitesArrayType, SiteType } from '~/shared/models/sites'
import {
  SiteProperties,
  PartialSiteProperties,
} from '~/shared/models/siteProperties'
import { APIConfiguration, APIOrderZone } from "~/shared/models/apiSite";

export type CreateSite = Readonly<{
  adminIds: readonly string[]
  id: string
}>

export enum Status {
  configured = 'configured',
  initialized = 'initialized',
  unconfigured = 'unconfigured',
}

export const addAdmin = (siteId: string, adminId: string) =>
  put(`site/${siteId}/admins/add`, { id: adminId }, AdminsType.decode)

export const createModel = (siteId: string, model: CreateModelType) =>
  post(`configuration/${siteId}/model`, model, Model.decode)

export const createSite = (data: CreateSite) =>
  post('site/create', data, SiteType.decode)

export const updateModel = (
  siteId: string,
  modelId: string,
  model: CreateModelType,
) => put(`configuration/${siteId}/model/${modelId}`, model, Model.decode)

export const deleteAdmin = (siteId: string, adminId: string) =>
  del(`site/${siteId}/admin/${adminId}`, AdminsType.decode)

export const deleteModel = (siteId: string, modelId: string) =>
  del(`configuration/${siteId}/model/${modelId}`)

export const getAdmins = (siteId: string) =>
  get(`site/${siteId}/admins`, AdminsType.decode)

export const getCurrentUser = () => get('current-user', BackOfficeUser.decode)

export const getDepartments = () =>
  get('referential/departments', DeptsArrayType.decode)

export const getMaterials = () =>
  get('referential/materials', MaterialsArray.decode)

export const getModels = (siteId: string) =>
  get(`back-office/site/${siteId}/models`, ModelsArray.decode)

export const getSites = (status: Status) =>
  get('sites', SitesArrayType.decode, {
    searchParams: { status: status },
  })

export const getWorkers = (siteId: string) =>
  get(`back-office/site/${siteId}/workers`, WorkersArray.decode)

export const getWorker = (workerId: string) =>
  get(`worker/${workerId}`, BOWorker.decode)

export const getKnownWorker = (workerId: string) =>
  get(`worker/known/${workerId}`, BOWorker.decode)

export const getWorkshops = (siteId: string) =>
  get(`back-office/site/${siteId}/workshops`, WorkshopsArray.decode)

export const synchronizeWorkers = (siteId: string) =>
  fetch(
    `back-office/site/${siteId}/workers/update`,
    { method: 'post' },
    WorkersArray.decode,
  )

export const synchronizeWorkshops = (siteId: string) =>
  fetch(
    `back-office/site/${siteId}/workshops/update`,
    { method: 'post' },
    WorkshopsArray.decode,
  )

export const getSiteProperties = (siteId: string) =>
  get(`back-office/site/${siteId}/properties`, SiteProperties.decode)

export const putSiteProperties = (
  siteId: string,
  body: PartialSiteProperties,
) => put(`back-office/site/${siteId}/properties`, body, SiteProperties.decode)

export const removeSite = (siteId: string) => del(`site/${siteId}`)

export const updateWorker = (workerId: string, body: UpdateWorker) =>
  put(`worker/${workerId}`, body, Worker.decode)

export const updateWorkshop = (workshopId: string, body: UpdateWorkshop) =>
  put(`workshop/${workshopId}`, body, Workshop.decode)

export const uploadPicture = (body: Blob) =>
  fetch('picture/upload', { body, method: 'post' }, PictureId.decode)

export const apiConfiguration = (siteId: string) =>
  get(`configuration/${siteId}`, APIConfiguration.decode)

export const apiOrderZone = (siteId: string) =>
  get(`site/${siteId}/order-zone`, APIOrderZone.decode)