import useSWR, { ConfigInterface, responseInterface } from 'swr'
import { ErrorObject } from './http-errors'

export default function useHttp<T extends any[], Result>(
  params: T | (() => T | null) | null,
  fetcher: (...args: T) => Promise<Result>,
  config?: ConfigInterface<Result, ErrorObject>,
): responseInterface<Result, ErrorObject>

export default function useHttp<T, Result>(
  params: T | (() => T | null) | null,
  fetcher: (args: T) => Promise<Result>,
  config?: ConfigInterface<Result, ErrorObject>,
): responseInterface<Result, ErrorObject>

export default function useHttp<T extends any[], Result>(
  params: T | (() => T | null) | null,
  fetcher: (...args: T) => Promise<Result>,
  config?: ConfigInterface<Result, ErrorObject>,
): responseInterface<Result, ErrorObject> {
  return useSWR<Result, ErrorObject>(params, fetcher, config)
}
