import React, { FC, useMemo } from 'react'

import { Table, TableCell, TableHeader, TableRow } from '~/components/Table'
import { Clickable } from '~/components/clickable'
import { Emblem } from '~/components/Emblem'
import EditIcon from '~/design/icons/editIcon'
import { WorkerIcon } from '~/design/icons/WorkerIcon'
import styled from '~/design/styled'
import {
  Worker,
  WorkersArray,
  WorkshopsArray,
  sortWorkers,
} from '~/shared/models/models'

type Props = Readonly<{
  editWorker: (worker: Worker) => void
  workers: WorkersArray
  workshops: WorkshopsArray
}>

export const WorkersList: FC<Props> = ({ editWorker, workers, workshops }) => {
  const layout = useMemo(
    () => [
      { flex: 3 },
      { flex: 3 },
      { flex: 3 },
      { flex: 1, justifyContent: 'flex-end' },
    ],
    [],
  )
  const sortedWorkers = useMemo(() => sortWorkers(workers.workers), [workers])
  return (
    <Table layout={layout}>
      <TableHeader>
        <TableCell>Artisan</TableCell>
        <TableCell>Trigramme</TableCell>
        <TableCell>Atelier</TableCell>
        <TableCell>Action</TableCell>
      </TableHeader>
      {sortedWorkers.map(w => (
        <TableRow key={w.id}>
          <TableCell>
            <EmblemStyled
              placeholder={<WorkerIcon />}
              source={w.pictureUrl ?? undefined}
            />
            <WorkerName>{w.name}</WorkerName>
          </TableCell>
          <TableCell>{w.trigram ?? '-'}</TableCell>
          <TableCell>
            {workshops.workshops.find(s => s.id === w.associatedWorkshop.id)
              ?.name ?? '-'}
          </TableCell>
          <TableCell>
            <Clickable
              onClick={() => {
                editWorker(w)
              }}
            >
              <EditIconStyled />
            </Clickable>
          </TableCell>
        </TableRow>
      ))}
    </Table>
  )
}

const EmblemStyled = styled(Emblem)(({ theme }) => ({
  flexShrink: 0,
  marginInlineEnd: theme.space.s40,
}))

const WorkerName = styled.span(({ theme }) => ({
  color: theme.colors.text,
  fontWeight: theme.fontWeights.default.medium,
}))

const EditIconStyled = styled(EditIcon)(({ theme }) => ({
  fill: theme.colors.black,
  flexShrink: 0,
}))
