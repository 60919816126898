import React from 'react'
import styled from '~/design/styled'
import {
  PrimaryMediumButton,
  UIText,
  LegendText,
  CloseIcon,
} from '@hermes/design-system'
import { Controller, useForm } from 'react-hook-form'
import isEqual from 'date-fns/isEqual'
import compareDateAsc from 'date-fns/compareAsc'
import { formatHumanDate } from '~/shared/date'

import { Modal } from '~/components/modal'
import { ClosedDatesType } from '~/shared/models/siteProperties'
import { MultipleDatePicker } from '~/components/datepicker'
import { Clickable } from '~/components/clickable'

interface Props {
  closedDates: ClosedDatesType | null
  onClose: () => void
  onSubmit: (data: ClosedDatesType) => Promise<void>
}

type ClosedDatesFormData = {
  closedDates: ClosedDatesType
}
const UpdateClosedDatesModal: React.FC<Props> = ({
  closedDates,
  onSubmit,
  onClose,
}) => {
  const { control, handleSubmit, formState, watch, setValue } = useForm<
    ClosedDatesFormData
  >({
    mode: 'onChange',
    defaultValues: {
      closedDates: closedDates?.sort(compareDateAsc) ?? [],
    },
  })

  const selectedDays = watch('closedDates')

  const removeDate = (date: Date) =>
    setValue(
      'closedDates',
      selectedDays?.filter(selectedDay => !isEqual(selectedDay, date)),
    )

  const canSubmit =
    formState.dirty && formState.isValid && !formState.isSubmitting

  const submitAndClose = async (data: ClosedDatesFormData) => {
    if (data.closedDates !== undefined) {
      await onSubmit(data.closedDates)
      onClose()
    }
  }

  return (
    <Modal
      title={`${
        (closedDates?.length || 0) > 0 ? 'Modifer' : 'Configurer'
      } les dates de fermeture du site`}
      onClose={formState.isSubmitting ? undefined : onClose}
    >
      <ClosedDatesForm onSubmit={handleSubmit(submitAndClose)}>
        <ListingContainer>
          <TitleItem>Dates de fermeture</TitleItem>
          {selectedDays.length > 0 ? (
            <DateListing>
              {selectedDays.map(date => (
                <DateItem
                  key={date.toISOString()}
                  date={date}
                  onClick={removeDate}
                />
              ))}
            </DateListing>
          ) : (
            <DateListingPlaceholder>
              Sélectionnez une ou plusieurs dates à droite
            </DateListingPlaceholder>
          )}
        </ListingContainer>
        <Controller
          name="closedDates"
          control={control}
          as={<MultipleDatePicker />}
          onChange={([dates]) => dates?.sort(compareDateAsc)}
          valueName="selectedDays"
        />

        <Footer>
          <PrimaryMediumButton disabled={!canSubmit}>
            Valider
          </PrimaryMediumButton>
        </Footer>
      </ClosedDatesForm>
    </Modal>
  )
}

const ListingContainer = styled.div({
  display: 'flex',
  flexFlow: 'column nowrap',
})

const TitleItem = styled(LegendText.withComponent('p'))(({ theme }) => ({
  color: theme.colors.grey,
  lineHeight: '31px',
  marginBottom: theme.space.s30,
  flexShrink: 0,
}))

const ClosedDatesForm = styled.form(({ theme }) => ({
  display: 'grid',
  gridTemplateAreas: `"list picker" "footer footer"`,
  gridTemplateColumns: 'auto fit-content(240px)',
  gridTemplateRows: '340px auto',
  gridColumnGap: theme.layout.l10,
  gridRowGap: theme.space.s80,
}))

const DateListing = styled.ul(({ theme }) => ({
  listDecoration: 'none',
  margin: 0,
  padding: `0 ${theme.space.s30} 0 0`,
  overflowY: 'auto',
}))

const DateItem = ({
  date,
  onClick,
}: {
  date: Date
  onClick: (date: Date) => void
}) => (
  <StyledDateItem>
    <UIText>{formatHumanDate(date)}</UIText>
    <RemoveIconWrapper onClick={() => onClick(date)}>
      <RemoveIcon />
    </RemoveIconWrapper>
  </StyledDateItem>
)
const StyledDateItem = styled.li(({ theme }) => ({
  display: 'flex',
  flexFlow: 'row nowrap',
  listStyle: 'none',
  padding: `${theme.space.s30} 0`,
  borderBottom: `1px solid ${theme.colors.greys.light20}`,

  '&:last-of-type': {
    borderBottom: 'none',
  },
}))
const RemoveIconWrapper = styled(Clickable)({ marginLeft: 'auto' })
const RemoveIcon = styled(CloseIcon)(({ theme }) => ({
  fill: theme.colors.grey,

  '&:hover': {
    fill: theme.colors.red,
  },
}))

const DateListingPlaceholder = styled(LegendText)(({ theme }) => ({
  marginTop: theme.space.s30,
}))

const Footer = styled.div({
  gridArea: 'footer',
  display: 'flex',
  justifyContent: 'center',

  '& > *': {
    flex: 1,
    maxWidth: '220px',
  },
})

export default UpdateClosedDatesModal
