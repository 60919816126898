import React, { FC } from 'react'

type Props = Readonly<{
  className?: string
}>

export const QuantityIcon: FC<Props> = ({ className }) => (
  <svg className={className} height="26" viewBox="0 0 26 26" width="26">
    <defs>
      <path
        d="M12.77 18.313c-.477.28-1.063.28-1.54 0L2.1 12.998l3.08-1.791 5.28 3.08c.947.552 2.11.552 3.057 0l5.292-3.08 3.08 1.79-9.12 5.316zm9.142.059l-9.142 5.33c-.477.28-1.063.28-1.54 0L2.1 18.387l3.08-1.79 5.28 3.064c.953.56 2.127.56 3.08 0l5.293-3.08 3.079 1.79zM2.096 7.612l.004-.004 9.13-5.315a1.517 1.517 0 011.54 0l9.13 5.315-9.13 5.315c-.477.28-1.063.28-1.54 0l-9.134-5.31zm21.142 4.608a1.563 1.563 0 00-.575-.586l-2.31-1.332 2.31-1.333a1.585 1.585 0 000-2.725L13.54.934a3.029 3.029 0 00-3.057 0L1.338 6.247a1.584 1.584 0 00-.576 2.139c.137.244.336.446.576.585l2.31 1.333-2.31 1.333a1.582 1.582 0 000 2.724l2.31 1.333-2.31 1.333c-.48.278-.777.797-.778 1.36 0 .56.293 1.079.77 1.36l9.13 5.318c.947.552 2.11.552 3.057 0l9.146-5.319a1.583 1.583 0 000-2.724l-2.31-1.332 2.31-1.333a1.584 1.584 0 00.575-2.138z"
        id="quantity-icon"
      />
    </defs>
    <use
      fill="#FC4D21"
      fillRule="evenodd"
      transform="translate(1)"
      xlinkHref="#quantity-icon"
    />
  </svg>
)
