/** @jsx jsx */

import { InterpolationWithTheme, jsx } from '@emotion/core'
import { FC } from 'react'

type Props = Readonly<{
  className?: string
  css?: InterpolationWithTheme<any>
  fill?: string
}>

export const SyncIcon: FC<Props> = ({ className, css, fill = 'white' }) => (
  <svg
    className={className}
    css={css}
    height="18"
    viewBox="0 0 18 18"
    width="18"
  >
    <defs>
      <path
        id="sync-icon"
        d="M15.46 4.342a.704.704 0 01.683.183 6.3 6.3 0 011.356 2.011v-.018a6.33 6.33 0 01-5.829 8.799H7.92l1.6 1.6a.283.283 0 01-.001.4l-.6.6a.28.28 0 01-.2.083.287.287 0 01-.2-.083l-3.093-3.092a.283.283 0 010-.4l3.094-3.101a.28.28 0 01.4 0l.6.6a.28.28 0 01-.001.4l-1.6 1.6h3.746a4.921 4.921 0 003.48-8.399.707.707 0 01.317-1.184zM9.279 0a.28.28 0 01.2.083l3.094 3.092a.283.283 0 010 .4L9.478 6.678a.283.283 0 01-.4 0l-.6-.6a.283.283 0 01.001-.4l1.6-1.6H6.326a4.925 4.925 0 00-3.484 1.44A4.928 4.928 0 001.4 9a4.89 4.89 0 001.453 3.481.706.706 0 11-.999 1A6.323 6.323 0 016.327 2.683h3.751l-1.6-1.6a.283.283 0 01.001-.4l.6-.6a.278.278 0 01.2-.083z"
      />
    </defs>
    <use fill={fill} fillRule="nonzero" xlinkHref="#sync-icon" />
  </svg>
)
