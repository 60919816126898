import React, {
  ReactElement,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { Toast } from './Toast'

type ToasterProviderProps = Readonly<{
  children?: (toast: Toast | undefined) => ReactNode
}>

type Toaster = Readonly<{
  showToast: (toast: Toast) => void
}>

const ToasterContext = createContext<Toaster | undefined>(undefined)

export function ToasterProvider({
  children,
}: ToasterProviderProps): ReactElement {
  const [toast, setToast] = useState<Toast | undefined>()
  const timeoutIDRef = useRef<number | undefined>()
  const toaster = useMemo<Toaster>(() => ({ showToast: setToast }), [])
  useEffect(() => {
    if (toast !== undefined) {
      clearTimeout(timeoutIDRef.current)
      timeoutIDRef.current = (setTimeout(() => {
        setToast(undefined)
      }, 7_500) as unknown) as number
    }
    return () => {
      clearTimeout(timeoutIDRef.current)
    }
  }, [toast])
  return (
    <ToasterContext.Provider value={toaster}>
      {children?.(toast)}
    </ToasterContext.Provider>
  )
}

export function useToaster(): Toaster {
  const toaster = useContext(ToasterContext)
  if (toaster === undefined) {
    throw new Error('useToaster must be used within a ToasterProvider')
  }
  return toaster
}
