import React, { useMemo } from 'react'
import styled from '~/design/styled'

import setMonth from 'date-fns/setMonth'
import setYear from 'date-fns/setYear'
import { CaptionElementProps, LocaleUtils } from 'react-day-picker'
import { Select, SelectProps } from '@hermes/design-system'
import { makeArrayfromRange } from '~/shared/utils/array'

type Props = {
  onChange: (date: Date) => void
  fromMonth: Date
  toMonth: Date
} & CaptionElementProps
const YearMonthSelector = ({
  date,
  months,
  locale,
  localeUtils,
  onChange,
  fromMonth,
  toMonth,
}: Props) => {
  const monthValues: ReturnType<LocaleUtils['getMonths']> =
    months ?? localeUtils.getMonths(locale)

  const years = useMemo(
    () => makeArrayfromRange(fromMonth.getFullYear(), toMonth.getFullYear()),
    [fromMonth, toMonth],
  )
  const monthOptions = useMemo(
    () =>
      monthValues.map((month, index) => ({
        label: month,
        value: index,
      })),
    [monthValues],
  )
  const yearOptions = useMemo(
    () =>
      years.map(year => ({
        label: `${year}`,
        value: year,
      })),
    [years],
  )

  const handleMonthChange = (month?: number) => {
    if (month !== undefined) {
      onChange(setMonth(date, month))
    }
  }

  const handleYearChange = (year?: number) => {
    if (year !== undefined) {
      onChange(setYear(date, year))
    }
  }

  return (
    <div className="DayPicker-Caption">
      <SelectContainer>
        <FullWidthNumberSelect
          id="month"
          placeholder="Mois"
          value={date.getMonth()}
          options={monthOptions}
          onChange={handleMonthChange}
          isClearable={false}
        />
        <YearSelect
          id="year"
          placeholder="Années"
          value={date.getFullYear()}
          options={yearOptions}
          onChange={handleYearChange}
          isClearable={false}
        />
      </SelectContainer>
    </div>
  )
}

const SelectContainer = styled.div({
  display: 'flex',
  flexFlow: 'row nowrap',
  margin: '0 32px',
})

const SelectNumberProxy = (props: SelectProps<number>) => (
  <Select<number> {...props} />
)
const FullWidthNumberSelect = styled(SelectNumberProxy)(({ theme }) => ({
  width: '100%',
  marginRight: theme.spaces.xxsmall,
}))
const YearSelect = styled(SelectNumberProxy)({
  width: '150px',
})

export default YearMonthSelector
