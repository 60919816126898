import { PrimaryMediumButton, UIText } from '@hermes/design-system'
import React, { FC, useCallback } from 'react'
import { Controller, DeepPartial, useForm } from 'react-hook-form'
import { object, string } from 'yup'

import { ImageResizer } from '~/components/ImageResizer'
import { Label } from '~/components/inputs'
import { Modal, ModalProps } from '~/components/modal'
import { WorkerIcon } from '~/design/icons/WorkerIcon'
import styled from '~/design/styled'
import { UpdateWorker, Worker } from '~/shared/models/models'

type Props = Readonly<
  Pick<ModalProps, 'onClose'> & {
    onSubmit: (worker: Worker, update: UpdateWorker) => Promise<void>
    worker: Worker
  }
>

type FormData = Readonly<{
  pictureId: string | undefined
}>

const validationSchema = object<FormData>({
  pictureId: string(),
})

export const WorkerEditionModal: FC<Props> = ({
  onClose,
  onSubmit: propsOnSubmit,
  worker,
}) => {
  const { control, formState, handleSubmit, setValue } = useForm<FormData>({
    defaultValues: getDefaultValues(worker),
    mode: 'onChange',
    validationSchema,
  })
  const handlePictureIdReset = useCallback(() => {
    setValue('pictureId', undefined, true)
  }, [setValue])
  const onSubmit = useCallback(
    (data: FormData) => propsOnSubmit(worker, transformFormData(data)),
    [propsOnSubmit, worker],
  )
  return (
    <Modal
      onClose={formState.isSubmitting ? undefined : onClose}
      title="Modifier artisan"
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormRow>
          <FormColumn>
            <Field>
              <Label>Nom de l'artisan</Label>
              <FormText>{worker.name}</FormText>
            </Field>
            <Field>
              <Label>Trigramme artisan</Label>
              <FormText>{worker.trigram ?? '-'}</FormText>
            </Field>
          </FormColumn>
          <FormColumn>
            <Controller
              as={
                <FormImageResizer
                  disabled={formState.isSubmitting}
                  hoverMessage="Déposer une photo"
                  message="Glisser-déposer la photo"
                  modalSize="small"
                  modalTitle="Redimensionner la photo"
                  onReset={handlePictureIdReset}
                  placeholder={<WorkerIcon />}
                  title="Photo"
                />
              }
              control={control}
              name="pictureId"
              valueName="source"
            />
          </FormColumn>
        </FormRow>
        <SubmitButton
          disabled={
            !formState.dirty || !formState.isValid || formState.isSubmitting
          }
        >
          Sauvegarder
        </SubmitButton>
      </Form>
    </Modal>
  )
}

function getDefaultValues(worker: Worker): DeepPartial<FormData> {
  return { pictureId: worker.pictureUrl ?? undefined }
}

function transformFormData(data: FormData): UpdateWorker {
  return { pictureId: data.pictureId ?? null }
}

const Form = styled.form({
  display: 'flex',
  flexDirection: 'column',
})

const FormRow = styled.div({
  display: 'flex',
  flexDirection: 'row',
  '> *': {
    flex: 1,
  },
})

const FormColumn = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
})

const Field = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  '> :first-of-type': {
    marginBlockEnd: theme.layout.l10,
  },
}))

const FormText = styled(UIText)(({ theme }) => ({
  color: theme.colors.text,
}))

const FormImageResizer = styled(ImageResizer)({
  alignSelf: 'flex-end',
})

const SubmitButton = styled(PrimaryMediumButton)(({ theme }) => ({
  alignSelf: 'center',
  marginBlockStart: theme.layout.l45,
}))
