import { FlowTypesValue } from '~/shared/models/siteProperties'

const leatherFlow: Record<FlowTypesValue, string> = {
  SAWHORSE: 'Chevalet',
  BOX: 'Caisse',
}

export function getLeatherFlowLabel(key: FlowTypesValue): string {
  return leatherFlow[key]
}

export function getLeatherFlowValues(): ReadonlyArray<FlowTypesValue> {
  return Object.keys(leatherFlow) as ReadonlyArray<FlowTypesValue>
}
