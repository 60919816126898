import React from 'react'

type Props = {
  fill?: string
  className?: string
}

const DeleteIcon: React.FC<Props> = ({ className, fill = '#ffffff' }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={fill}
    className={className}
  >
    <defs>
      <path
        id="deleterIcon"
        d="M1.911 6.276h.193c.423 0 .458.37.461.432v.009l.462 13.037c0 .416.22 1.077 1.124 1.149l.167.006h9.41c.997 0 1.267-1.604 1.29-2.132v-.068l.462-11.992s.006-.385.357-.435l.104-.005h.461c.423 0 .458.37.461.431v.009l-.587 14.268c-.045 1.06-.919 1.932-1.98 2.01l-.153.005H3.903c-1.078 0-2-.824-2.122-1.865l-.012-.15-.587-14.268s.02-.422.461-.44c.158-.008.057-.003.268-.001zm7.204 1.758c.261 0 .4.01.426.243l.004.087v10.454c0 .349-.169.66-.522.66-.318 0-.487-.252-.518-.557l-.005-.103V8.364c0-.286.113-.325.348-.33h.267zm-3.782-.44c.323-.008.43.073.456.343l.006.097.46 11.004c.011.349-.107.43-.46.44-.31 0-.428-.079-.455-.344l-.007-.096-.46-11.004c-.011-.348.107-.43.46-.44zm7.379 0c.318.01.445.076.46.343l.001.097-.461 11.004c-.01.342-.117.44-.461.44-.318-.009-.446-.076-.46-.343l-.001-.097.46-11.004c.011-.348.115-.444.462-.44zM11.636 0c1.046 0 2.091 1.045 2.091 2.09v1.046h3.137c.954 0 1.037.872 1.044 1.82l.001.271H.136c0-1.045 0-2.09 1.046-2.09h3.136V2.09C4.318 1.045 5.364 0 6.41 0zm-1.045 1.045H7.455c-1.046 0-1.046 1.046-1.046 1.046v1.045h5.227V2.091s0-1.046-1.045-1.046z"
      />
    </defs>
    <use
      fill="inherit"
      fillRule="evenodd"
      transform="translate(3)"
      xlinkHref="#deleterIcon"
    />
  </svg>
)

export default DeleteIcon
