import * as yup from 'yup'
import { countBy } from '~/shared/utils/array'
import { CuttingZonesArrayType } from '~/shared/models/siteProperties'
import { WorkshopsArray } from '~/shared/models/models'

export type CuttingZoneIdByWorkshopId = { [workshopId: string]: number }

type WorkshopIdByCuttingZoneId = { [cuttingZoneId: number]: string[] }

export const cuttingZoneIdByWorkshopIdToCuttingZonesArrayType = (
  cuttingZoneIdByWorkshopId: CuttingZoneIdByWorkshopId,
): CuttingZonesArrayType => {
  const workshopIdByCuttingZoneId: WorkshopIdByCuttingZoneId = Object.entries(
    cuttingZoneIdByWorkshopId,
  ).reduce((workshopIdByCuttingZoneIdObject, [workshopId, cuttingZoneId]) => {
    if (workshopIdByCuttingZoneIdObject.hasOwnProperty(cuttingZoneId)) {
      return {
        ...workshopIdByCuttingZoneIdObject,
        [cuttingZoneId]: [
          ...workshopIdByCuttingZoneIdObject[cuttingZoneId],
          workshopId,
        ],
      }
    } else if (cuttingZoneId >= 0) {
      return {
        ...workshopIdByCuttingZoneIdObject,
        [cuttingZoneId]: [workshopId],
      }
    } else {
      return workshopIdByCuttingZoneIdObject
    }
  }, {} as WorkshopIdByCuttingZoneId)

  return Object.entries(workshopIdByCuttingZoneId).map(
    ([cuttingZoneId, workshopIds]) => {
      return {
        id: parseInt(cuttingZoneId),
        workshops: workshopIds.map(id => ({ id })),
      }
    },
  )
}

export const associateCuttingZonesAndWorkshops = (
  workshops: WorkshopsArray,
  cuttingZones: CuttingZonesArrayType | null,
  nbCuttingZones: number,
): CuttingZoneIdByWorkshopId => {
  const cuttingZonesIdByWorkshopId: CuttingZoneIdByWorkshopId = workshops.workshops.reduce(
    (cuttingZonesIdByWorkshopIdObject, workshop) => {
      return {
        ...cuttingZonesIdByWorkshopIdObject,
        [workshop.id]: -1,
      }
    },
    {} as CuttingZoneIdByWorkshopId,
  )

  if (cuttingZones !== null) {
    cuttingZones.forEach(cuttingZone =>
      cuttingZone.workshops.forEach(workshop => {
        if (cuttingZone.id <= nbCuttingZones) {
          cuttingZonesIdByWorkshopId[workshop.id] = cuttingZone.id
        }
      }),
    )
  }

  return cuttingZonesIdByWorkshopId
}

type WorkshopIds = string[]
export const makeCuttingZonesIdByWorkshopIdSchema = (
  workshopIds: WorkshopIds,
  nbCuttingZones: number,
) => {
  return yup
    .object(
      workshopIds.reduce(
        (schemaObject, workshopId) => ({
          ...schemaObject,
          [workshopId]: yup
            .number()
            .min(-1)
            .max(nbCuttingZones),
        }),
        {},
      ),
    )
    .test(
      'one-cutting-zone-per-workshop',
      'Il doit y avoir au moins une coupe par atelier',
      function(value: CuttingZoneIdByWorkshopId) {
        const dedupedValues = Object.values(value).filter(
          (value, index, array) => array.indexOf(value) === index,
        )
        const everyCuttingZoneHasAWorkshop = Array.from(
          new Array(nbCuttingZones),
        ).every((_, index) => dedupedValues.indexOf(index + 1) >= 0)
        return everyCuttingZoneHasAWorkshop
      },
    )
    .test(
      'max-two-cutting-zone-per-workshop',
      'Il doit y avoir au maximum deux coupes par atelier',
      function(value: CuttingZoneIdByWorkshopId) {
        const sortedCuttingZonesValues = Object.values(value)
          .sort()
          .filter(value => value >= 0)
        const countedValues = countBy(sortedCuttingZonesValues)
        const atLeastOneCuttingZoneHasMoreThanTwoWorkshops = countedValues.some(
          value => value > 2,
        )
        return !atLeastOneCuttingZoneHasMoreThanTwoWorkshops
      },
    )
}
