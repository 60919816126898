import React, { FC } from 'react'

type Props = Readonly<{
  className?: string
}>

export const SuccessIcon: FC<Props> = ({ className }) => (
  <svg
    className={className}
    fill="white"
    height="12"
    viewBox="0 0 14 12"
    width="14"
  >
    <path
      d="M12.03.371c.487-.535 1.169-.467 1.611.047.453.526.503 1.343.017 1.923l-7.825 9.316c-.467.463-1.11.463-1.612-.037L.364 6.968C-.133 6.42-.1 5.602.334 5.07c.437-.534 1.123-.59 1.609-.013l3.1 3.692L12.017.387l.173.205-.16-.22z"
      fill="inherit"
      fillRule="nonzero"
    />
  </svg>
)
