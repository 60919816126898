import { InterpolationWithTheme } from '@emotion/core'
import { CloseIcon, UIText } from '@hermes/design-system'
import React, { FC, ReactNode, useCallback, useRef, useState } from 'react'

import { Clickable } from '~/components/clickable'
import { Link } from '~/components/link'
import styled from '~/design/styled'

import { FileDropTarget } from './FileDropTarget'
import {
  ImageCroppingModal,
  ImageCroppingModalProps,
} from './ImageCroppingModal'

type ImageResizerProps = Readonly<{
  className?: string
  css?: InterpolationWithTheme<any>
  disabled?: boolean
  hoverMessage: string
  message: string
  modalSize: ImageCroppingModalProps['size']
  modalTitle: string
  onChange?: (source: string) => void
  onReset?: () => void
  placeholder: ReactNode
  source?: string
  title: string
}>

const accept = 'image/jpeg,image/png'

export const ImageResizer: FC<ImageResizerProps> = ({
  className,
  css,
  disabled = false,
  hoverMessage,
  message,
  modalSize,
  modalTitle,
  onChange,
  onReset,
  placeholder,
  source,
  title,
}) => {
  const [fileObjectUrl, setFileObjectUrl] = useState<string | undefined>()

  const closeModal = useCallback(() => {
    if (fileObjectUrl !== undefined) {
      URL.revokeObjectURL(fileObjectUrl)
    }
    setFileObjectUrl(undefined)
  }, [fileObjectUrl])
  const openModal = useCallback((file: File) => {
    setFileObjectUrl(URL.createObjectURL(file))
  }, [])

  const handleModalCancel = useCallback(() => {
    closeModal()
  }, [closeModal])
  const handleModalConfirm = useCallback(
    (source: string) => {
      if (onChange !== undefined) {
        onChange(source)
      }
      closeModal()
    },
    [closeModal, onChange],
  )

  const fileInputRef = useRef<HTMLInputElement>(null)

  const handleFileBrowse = useCallback(() => {
    fileInputRef.current?.click()
  }, [])
  const handleFileOpen = useCallback(() => {
    if (fileInputRef.current !== null) {
      const file = fileInputRef.current.files?.[0]
      if (file !== undefined) {
        openModal(file)
      }
      fileInputRef.current.value = ''
    }
  }, [openModal])
  const handleFileDrop = useCallback(
    (file: File) => {
      if (accept.split(',').includes(file.type)) {
        openModal(file)
      }
    },
    [openModal],
  )

  const handleReset = useCallback(() => {
    if (onReset !== undefined) {
      onReset()
    }
  }, [onReset])

  return (
    <>
      <Container className={className} css={css}>
        <Title>{title}</Title>
        <ImageDropTarget disabled={disabled} onDrop={handleFileDrop}>
          {hover => (
            <>
              <ImagePreview>
                {source === undefined ? (
                  placeholder
                ) : (
                  <>
                    <Img alt="" src={source} />
                    {disabled ? (
                      <></>
                    ) : (
                      <Clickable onClick={handleReset}>
                        <ResetButton />
                      </Clickable>
                    )}
                  </>
                )}
              </ImagePreview>
              <ImageBrowser>
                <ImageBrowserMessage>
                  {message} ou{' '}
                  <ImageBrowserLink
                    disabled={disabled}
                    onClick={handleFileBrowse}
                  >
                    parcourir
                  </ImageBrowserLink>
                  <HiddenInput
                    accept={accept}
                    onChange={handleFileOpen}
                    ref={fileInputRef}
                    type="file"
                  />
                </ImageBrowserMessage>
              </ImageBrowser>
              {hover ? (
                <ImageDropTargetHover>{hoverMessage}</ImageDropTargetHover>
              ) : (
                <></>
              )}
            </>
          )}
        </ImageDropTarget>
      </Container>
      {fileObjectUrl === undefined ? (
        <></>
      ) : (
        <ImageCroppingModal
          onCancel={handleModalCancel}
          onConfirm={handleModalConfirm}
          size={modalSize}
          source={fileObjectUrl}
          title={modalTitle}
        />
      )}
    </>
  )
}

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
})

const Title = styled(UIText)(({ theme }) => ({
  color: theme.colors.greys.light30,
  fontSize: theme.fontSizes.legend,
  fontWeight: theme.fontWeights.default.bold,
  marginBottom: theme.space.s30,
}))

const ImageDropTarget = styled(FileDropTarget)({
  display: 'flex',
  position: 'relative',
})

const ImagePreview = styled.div(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.colors.greys.light25,
  border: `1px solid ${theme.colors.greys.light25}`,
  borderRadius: `${theme.borderRadius.small} 0px 0px ${theme.borderRadius.small}`,
  borderRight: 'none',
  display: 'flex',
  flexDirection: 'row',
  height: '145px',
  justifyContent: 'center',
  overflow: 'hidden',
  position: 'relative',
  width: '145px',
}))

const Img = styled.img({
  maxHeight: '100%',
  maxWidth: '100%',
})

const ResetButton = styled(CloseIcon)(({ theme }) => ({
  fill: theme.colors.text,
  height: '15px',
  position: 'absolute',
  right: theme.space.s30,
  top: theme.space.s30,
  width: '15px',
}))

const ImageBrowser = styled.div(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.colors.white,
  border: `1px dashed ${theme.colors.greys.light25}`,
  borderLeft: 'none',
  borderRadius: `0px ${theme.borderRadius.small} ${theme.borderRadius.small} 0px`,
  display: 'flex',
  flexDirection: 'row',
  height: '145px',
  padding: `0px ${theme.space.s35}`,
  width: '129px',
}))

const ImageBrowserMessage = styled(UIText)(({ theme }) => ({
  color: theme.colors.grey,
  fontSize: theme.fontSizes.legend,
}))

const ImageBrowserLink = styled(Link)<Readonly<{ disabled?: boolean }>>(
  ({ disabled, theme }) => ({
    color: disabled === true ? theme.colors.grey : theme.colors.orange,
    fontSize: theme.fontSizes.legend,
    fontWeight: theme.fontWeights.default.medium,
  }),
)

const HiddenInput = styled.input({
  display: 'none',
})

const ImageDropTargetHover = styled.div(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.colors.white084,
  border: `2px dashed ${theme.colors.orange}`,
  color: theme.colors.orange,
  display: 'flex',
  fontFamily: theme.fonts.default,
  fontSize: theme.fontSizes.small,
  fontWeight: theme.fontWeights.default.medium,
  height: '100%',
  justifyContent: 'center',
  pointerEvents: 'none',
  position: 'absolute',
  width: '100%',
}))
