import React, { FC } from 'react'

import { Table, TableCell, TableRow } from '~/components/Table'

export const NoError: FC = () => {
  return (
    <Table layout={[
      { flex: 3 },
      { flex: 3 },
      { flex: 3 },
      { flex: 1, justifyContent: 'flex-end' },
    ]}>
        <TableRow>
          <TableCell>
            Pas d'erreur trouvée dans la configuration
          </TableCell>
        </TableRow>
    </Table>
  )
}
