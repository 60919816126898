import { ChevronIcon } from '@hermes/design-system'
import React, { FC, useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import { Table, TableCell, TableRow } from '~/components/Table'
import SitesIcon from '~/design/icons/sitesIcon'
import styled from '~/design/styled'
import { SitesArrayType, SiteType, sortSites } from '~/shared/models/sites'

type SitesListProps = Readonly<{
  sites: SitesArrayType
}>

export const SitesList: FC<SitesListProps> = ({ sites }) => {
  const layout = useMemo(() => [{ flex: 1 }, { flex: 1 }, {}], [])
  const sortedSites = useMemo(() => sortSites(sites), [sites])
  return (
    <Table layout={layout}>
      {sortedSites.map(site => (
        <SitesListItem key={site.id} site={site} />
      ))}
    </Table>
  )
}

type SitesListItemProps = Readonly<{
  site: SiteType
}>

const SitesListItem: FC<SitesListItemProps> = ({ site }) => {
  const history = useHistory()
  const handleClick = useCallback(() => {
    history.push(`/site/${site.id}`)
  }, [history, site])
  return (
    <TableRow onClick={handleClick}>
      <TableCell>
        <SitesIconStyled />
        <SiteName>{site.name}</SiteName>
      </TableCell>
      <TableCell>{site.code}</TableCell>
      <TableCell>
        <ChevronIconStyled degree={180} />
      </TableCell>
    </TableRow>
  )
}

const SiteName = styled.span(({ theme }) => ({
  color: theme.colors.text,
  fontWeight: theme.fontWeights.default.medium,
  textTransform: 'uppercase',
}))

const SitesIconStyled = styled(SitesIcon)(({ theme }) => ({
  fill: theme.colors.orange,
  flexShrink: 0,
  marginInlineEnd: theme.space.s55,
}))

const ChevronIconStyled = styled(ChevronIcon)(({ theme }) => ({
  fill: theme.colors.greys.light30,
}))
