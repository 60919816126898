import React, { FC, useCallback, useState } from 'react'

import { WorkerEditionModal } from '~/components/workers/WorkerEditionModal'
import { WorkersList } from '~/components/workers/WorkersList'
import { SynchronizationControl } from '~/components/SynchronizationControl'
import { useToaster } from '~/components/Toaster'
import styled from '~/design/styled'
import { useSiteConfiguration } from '~/shared/contexts/SiteConfigurationContext'
import { UpdateWorker, Worker } from '~/shared/models/models'

type WorkerEditionModalState = Worker | undefined

const WorkersScreen: FC = () => {
  const {
    data: { workers, workshops },
    mutators: { synchronizeWorkshops, updateWorker, synchronizeWorkers },
  } = useSiteConfiguration()
  const { showToast } = useToaster()

  const [workerEditionModalState, setWorkerEditionModalState] = useState<
    WorkerEditionModalState
  >(undefined)

  const handleSynchronizeSite = useCallback(async () => {
    try {
      await synchronizeWorkshops()
    } catch (error) {
      showToast({
        intent: 'failure',
        message: "La liste des ateliers n'a pu être synchronisée",
      })
      return
    }
    try {
      await synchronizeWorkers()
      showToast({
        intent: 'success',
        message:
          'La liste des ateliers et des artisans a bien été synchronisée',
      })
    } catch (error) {
      showToast({
        intent: 'failure',
        message: "La liste des artisans n'a pu être synchronisée",
      })
    }
  }, [showToast, synchronizeWorkshops, synchronizeWorkers])

  const editWorker = useCallback((worker: Worker) => {
    setWorkerEditionModalState(worker)
  }, [])

  const closeWorkerEditionModal = useCallback(() => {
    setWorkerEditionModalState(undefined)
  }, [])

  const submitWorkerEditionModal = useCallback(
    async (worker: Worker, update: UpdateWorker) => {
      try {
        await updateWorker(worker.id, update)
        setWorkerEditionModalState(undefined)
        showToast({
          intent: 'success',
          message: 'Votre artisan a bien été modifié',
        })
      } catch (error) {
        setWorkerEditionModalState(undefined)
        showToast({
          intent: 'failure',
          message: "Votre artisan n'a pu être modifié",
        })
      }
    },
    [showToast, updateWorker],
  )

  return (
    <>
      <SynchronizationControlStyled
        filename={workers.filename}
        date={workers.lastUpdate}
        label="Synchroniser le site"
        onClick={handleSynchronizeSite}
      />
      <WorkersList
        editWorker={editWorker}
        workers={workers}
        workshops={workshops}
      />
      {workerEditionModalState && (
        <WorkerEditionModal
          onClose={closeWorkerEditionModal}
          onSubmit={submitWorkerEditionModal}
          worker={workerEditionModalState}
        />
      )}
    </>
  )
}

export default WorkersScreen

const SynchronizationControlStyled = styled(SynchronizationControl)(
  ({ theme }) => ({
    marginBlockEnd: theme.layout.l20,
    marginBlockStart: theme.layout.l35,
  }),
)
