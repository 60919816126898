import React from 'react'
import styled from '~/design/styled'
import BackIcon from '~/design/icons/backIcon'
import { Link } from 'react-router-dom'

type Props = {
  pageTitle?: string
  route: string
  className?: string
}

export const BackNav: React.FC<Props> = ({ pageTitle, route, className }) => {
  return (
    <LinkStyled className={className} to={route}>
      <BackIconStyled />
      {pageTitle ?? ''}
    </LinkStyled>
  )
}

const BackIconStyled = styled(BackIcon)(({ theme }) => ({
  marginRight: theme.space.s40,
  fill: theme.colors.black,
}))

const LinkStyled = styled(Link)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
  color: 'inherit',
  fontSize: '24px',
  marginBottom: theme.space.s50,
}))
