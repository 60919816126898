import React from 'react'

type Props = {
  fill?: string
  className?: string
}

const LeatherIcon: React.FC<Props> = ({ className }) => (
  <svg width="26" height="26" viewBox="0 0 26 26" className={className}>
    <defs>
      <path
        id="leatherIcon"
        d="M18.32 17.432c-.207.616-.577 1.174-1.072 1.623-.081-.017-.167-.036-.259-.054-1.257-.255-2.58-.303-3.852-.006-1.247.294-2.318.9-3.136 1.845-.82-.944-1.89-1.55-3.138-1.845-1.272-.297-2.594-.25-3.853.006l-.258.054c-.494-.448-.862-1.006-1.072-1.623 2.486-2.795 2.486-7.444 0-10.239.205-.607.568-1.158 1.052-1.605 2.645.723 4.56-.116 5.626-1.94.287-.492.475-.977.589-1.403.69-.167 1.416-.167 2.108 0 .113.426.3.911.588 1.404 1.065 1.823 2.98 2.662 5.625 1.94.484.445.846.996 1.052 1.605-2.485 2.794-2.485 7.444 0 10.238m1.542-9.958c-.29-1.414-1.078-2.637-2.226-3.476-.268-.198-.625-.248-.941-.134-1.825.655-2.802.264-3.419-.826-.159-.283-.278-.592-.361-.903-.05-.184-.073-.32-.08-.38-.04-.348-.277-.646-.612-.777C10.796.42 9.204.42 7.777.978c-.337.131-.57.43-.611.777-.007.06-.032.196-.081.38-.082.31-.202.62-.36.903-.618 1.09-1.595 1.481-3.42.826-.316-.114-.672-.064-.942.134C1.216 4.838.427 6.06.146 7.43c-.069.352.03.669.259.892 2.083 2.032 2.083 6.033 0 8.066-.228.224-.328.54-.268.847.29 1.415 1.078 2.637 2.226 3.478.265.194.614.246.928.139.093-.032.292-.089.57-.146.909-.19 1.858-.227 2.713-.02 1.13.274 1.98.945 2.515 2.17.34.778 1.482.778 1.822 0 .535-1.225 1.384-1.896 2.514-2.17.856-.207 1.806-.17 2.715.02.277.057.476.114.57.146.313.107.663.055.927-.14 1.147-.84 1.936-2.062 2.216-3.433.07-.35-.03-.667-.258-.891-2.082-2.033-2.082-6.034 0-8.066.228-.223.329-.54.267-.848"
      />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(3 1)">
      <use fill="#FC4D21" xlinkHref="#leatherIcon" />
    </g>
  </svg>
)

export default LeatherIcon
