import { InterpolationWithTheme } from '@emotion/core'
import React, { Children, FC, cloneElement, isValidElement } from 'react'

import styled from '~/design/styled'

import { useTableLayout } from './TableLayoutContext'

export type TableRowProps = Readonly<{
  className?: string
  css?: InterpolationWithTheme<any>
  onClick?: () => void
}>

export const TableRow: FC<TableRowProps> = ({
  children,
  className,
  css,
  onClick,
}) => {
  const layout = useTableLayout()
  return (
    <View className={className} css={css} onClick={onClick}>
      {Children.map(children, (child, index) => {
        if (!isValidElement(child)) {
          return child
        }
        const flexBasis = layout[index]
        const style = typeof flexBasis === 'object' ? flexBasis : { flexBasis }
        return cloneElement(child, { style })
      })}
    </View>
  )
}

const View = styled.div<Pick<TableRowProps, 'onClick'>>(
  ({ onClick, theme }) => ({
    alignItems: 'center',
    color: theme.colors.grey,
    display: 'flex',
    flexDirection: 'row',
    fontFamily: theme.fonts.default,
    fontSize: theme.fontSizes.small,
    fontWeight: theme.fontWeights.default.regular,
    height: '68px',
    paddingInlineEnd: '30px',
    paddingInlineStart: '20px',
    ':not(:last-child)': {
      borderBlockEnd: `1px solid ${theme.colors.greys.light25}`,
    },
    ':hover': {
      cursor: onClick === undefined ? 'unset' : 'pointer',
    },
  }),
)
