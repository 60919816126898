import React, { ChangeEvent, ReactElement, useMemo } from 'react'

import styled from '~/design/styled'

import { useRadioGroup } from './RadioGroupContext'
import { RadioProps } from './RadioProps'

export function Radio<V extends string = string>(
  props: RadioProps<V>,
): ReactElement {
  const radioGroup = useRadioGroup<V>()

  const radioGroupOnChange = radioGroup?.onChange
  const { onChange: propsOnChange, value: propsValue } = props

  const checked =
    props.checked ??
    (propsValue === undefined || radioGroup === undefined
      ? undefined
      : propsValue === radioGroup.value)
  const name = props.name ?? radioGroup?.name
  const handleChange = useMemo(() => {
    if (propsOnChange !== undefined) {
      return (event: ChangeEvent<HTMLInputElement>) => {
        propsOnChange(event.target.checked)
      }
    } else if (propsValue !== undefined && radioGroupOnChange !== undefined) {
      return () => {
        radioGroupOnChange(propsValue)
      }
    } else {
      return undefined
    }
  }, [propsOnChange, propsValue, radioGroupOnChange])

  return (
    <RadioWrapper className={props.className}>
      <StyledRadio>
        <InputRadio
          checked={checked}
          id={props.id}
          name={name}
          onChange={handleChange}
          type="radio"
          value={propsValue}
        />
        <ControlRadio checked={checked} />
      </StyledRadio>
      {props.label && <LabelRadio htmlFor={props.id}>{props.label}</LabelRadio>}
    </RadioWrapper>
  )
}

const RadioWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  fontFamily: theme.fonts.default,
  cursor: 'pointer',
}))

const StyledRadio = styled.div({
  label: 'Radio',
  position: 'relative',
  height: '16px',
  width: '16px',
  flexShrink: 0,
  zIndex: 0,
})

const ControlRadio = styled.div<{ checked: boolean | undefined }>(
  {
    label: 'ControlRadio',
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: -1,
    pointerEvents: 'none',
    transition: 'transform 0.2s',
    borderRadius: '50%',
    borderWidth: '1px',
    borderStyle: 'solid',

    '&::after': {
      content: "''",
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '50%',
      height: '50%',
      borderRadius: '50%',
    },
  },
  ({ checked, theme }) => ({
    backgroundColor: theme.colors.white,
    borderColor: checked ? theme.colors.orange : theme.colors.grey,
    '&::after': {
      backgroundColor: checked ? theme.colors.orange : theme.colors.white,
    },
  }),
)

const InputRadio = styled.input({
  label: 'InputRadio',
  position: 'relative',
  margin: 0,
  padding: 0,
  opacity: 0,
  height: '100%',
  width: '100%',
  display: 'block',
  zIndex: 1,
  cursor: 'pointer',

  '&:disabled': {
    cursor: 'not-allowed',
  },
})

const LabelRadio = styled.label({
  marginLeft: '12px',
})
