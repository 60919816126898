import * as t from 'io-ts'

export const AdminType = t.type({
  id: t.string,
})

export const AdminsType = t.type({
  admins: t.readonlyArray(AdminType),
})

export type AdminsType = t.TypeOf<typeof AdminsType>
export type AdminType = t.TypeOf<typeof AdminType>

export function sortAdmins(admins: readonly AdminType[]): readonly AdminType[] {
  return admins.slice().sort((a, b) => a.id.localeCompare(b.id))
}
