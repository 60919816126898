import React, { FC, useState } from 'react'

import { BinIcon } from '~/design/icons/BinIcon'

import { ParameterBlockTemplate } from '../ParameterBlockTemplate'
import { SiteRemovalModal } from './SiteRemovalModal'

type Props = Readonly<{
  removeSite: () => void
  siteName: string | undefined
}>

export const SiteRemovalBlock: FC<Props> = ({ removeSite, siteName }) => {
  const [modalVisible, setModalVisible] = useState(false)

  const openModal = () => {
    setModalVisible(true)
  }
  const closeModal = () => {
    setModalVisible(false)
  }

  const submit = () => {
    setModalVisible(false)
    removeSite()
  }

  return (
    <>
      <ParameterBlockTemplate
        buttonLabelType="delete"
        icon={<BinIcon />}
        onButtonClick={openModal}
        title="Suppression du site"
      />
      {modalVisible && (
        <SiteRemovalModal
          onCancel={closeModal}
          onClose={closeModal}
          onConfirm={submit}
          siteName={siteName}
        />
      )}
    </>
  )
}
