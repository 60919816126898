import React, { FC, useState } from 'react'
import { Modal } from '~/components/modal'
import { NbCuttingZonesForm } from './nbCuttingZoneForm'
import { CuttingZonesWorkshopAssociationForm } from './cuttingZonesWorkshopAssociationForm'
import { CuttingZonesArrayType } from '~/shared/models/siteProperties'
import { WorkshopsArray } from '~/shared/models/models'
import {
  CuttingZoneIdByWorkshopId,
  cuttingZoneIdByWorkshopIdToCuttingZonesArrayType,
} from './cuttingZonesLogic'

type Props = Readonly<{
  onSubmitAssociation: (data: CuttingZonesArrayType) => Promise<void>
  cuttingZones: CuttingZonesArrayType | null
  onClose: () => void
  workshops: WorkshopsArray
}>

export type FormData = Readonly<{
  nbCuttingZones: string
}>

export const CuttingZonesModal: FC<Props> = ({
  onSubmitAssociation,
  onClose,
  workshops,
  cuttingZones,
}) => {
  const [enableAssociationForm, setEnableAssociationForm] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [nbCuttingZones, setNbCuttingZones] = useState<undefined | number>(
    cuttingZones !== null && cuttingZones.length !== 0
      ? cuttingZones.length
      : undefined,
  )
  const onSubmitNbCuttingZones = (data: FormData) => {
    setNbCuttingZones(parseInt(data.nbCuttingZones))
    setEnableAssociationForm(true)
  }
  const submitAndClose = async (data: CuttingZoneIdByWorkshopId) => {
    try {
      setIsSubmitting(true)
      await onSubmitAssociation(
        cuttingZoneIdByWorkshopIdToCuttingZonesArrayType(data),
      )
      onClose()
    } finally {
      setIsSubmitting(false)
    }
  }

  const messageFormType = cuttingZones === null ? 'Configurer' : 'Modifier'

  return (
    <Modal
      onBack={
        enableAssociationForm
          ? () => setEnableAssociationForm(false)
          : undefined
      }
      onClose={isSubmitting ? undefined : onClose}
      title={
        !enableAssociationForm
          ? `${messageFormType} le nombre de zones de coupe`
          : `${messageFormType} les binômes Coupe/Table`
      }
    >
      {!enableAssociationForm && (
        <NbCuttingZonesForm
          nbCuttingZones={nbCuttingZones}
          onSubmit={onSubmitNbCuttingZones}
        />
      )}

      {enableAssociationForm && nbCuttingZones && (
        <CuttingZonesWorkshopAssociationForm
          nbCuttingZones={nbCuttingZones}
          onSubmit={submitAndClose}
          workshops={workshops}
          cuttingZones={cuttingZones}
        />
      )}
    </Modal>
  )
}
