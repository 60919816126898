import React from 'react'
import styled from '~/design/styled'

export type InputPasswordProps = {
  value?: string
  onChange?: (value: string) => void
  placeholder?: string
  error?: string
  disabled?: boolean
  className?: string
  id?: string
}

export const InputPassword = ({
  className,
  error,
  onChange,
  ...props
}: InputPasswordProps) => {
  return (
    <PasswordWrapper className={className}>
      <StyledInput
        {...props}
        type="password"
        onChange={e => onChange?.((e.target as HTMLInputElement).value)}
        error={error}
      />
      {error && <PasswordErrorText>{error}</PasswordErrorText>}
    </PasswordWrapper>
  )
}

const PasswordWrapper = styled.div({
  position: 'relative',
})

const StyledInput = styled.input<
  Pick<InputPasswordProps, 'error' | 'disabled'>
>(({ error, disabled, theme }) => ({
  fontFamily: theme.fonts.default,
  fontSize: theme.inputs.fontSize,
  color: disabled ? theme.inputs.color.disabled : theme.inputs.color.default,
  padding: theme.inputs.padding,
  width: '100%',
  outline: 'none',
  background: disabled
    ? theme.inputs.background.inputDisabled
    : theme.inputs.background.input,
  borderRadius: theme.inputs.borderRadius,
  border: `1px solid ${
    error ? theme.inputs.borderColor.error : theme.inputs.borderColor.default
  }`,
  height: theme.inputs.height,

  '&:focus-within, &:focus': {
    borderColor: theme.inputs.borderColor.focus,
  },

  '&::placeholder': {
    fontFamily: 'inherit',
    fontWeight: 'inherit',
    color: theme.inputs.color.placeholder,
  },
}))

const PasswordErrorText = styled.span(({ theme }) => ({
  position: 'absolute',
  right: theme.space.s30,
  lineHeight: theme.inputs.height,
  color: theme.colors.red,
  fontSize: theme.fontSizes.legend,
}))
