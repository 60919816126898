import { MaterialCallStepValue } from '~/shared/models/siteProperties'

const materialCallStep: Record<MaterialCallStepValue, string> = {
  CUTTING: "Par l'artisan de coupe",
  TABLE: "Par l'artisan de table",
}

export function getMaterialCallStepLabel(key: MaterialCallStepValue): string {
  return materialCallStep[key]
}

export function getMaterialCallStepValues(): ReadonlyArray<
  MaterialCallStepValue
> {
  return Object.keys(materialCallStep) as ReadonlyArray<MaterialCallStepValue>
}
