import * as t from 'io-ts'
import { DateFromISOString } from 'io-ts-types/lib/DateFromISOString'

export const DeptType = t.strict({
  id: t.string,
  name: t.string,
  pictureUrl: t.string,
  shortname: t.string,
})

export const DeptsArrayType = t.strict({
  departments: t.array(DeptType),
})

const AssociatedMaterials = t.strict({
  id: t.string,
})

const Department = t.strict({
  id: t.string,
})

export const Model = t.strict({
  associatedMaterials: t.readonlyArray(AssociatedMaterials),
  department: Department,
  id: t.string,
  isDisplayedOnSite: t.boolean,
  name: t.string,
  quantities: t.readonlyArray(t.number),
  pictureId: t.union([t.null, t.string]),
  pictureUrl: t.union([t.null, t.string]),
})

export const CreateModel = t.strict({
  associatedMaterials: t.readonlyArray(AssociatedMaterials),
  department: Department,
  isDisplayedOnSite: t.boolean,
  name: t.string,
  quantities: t.readonlyArray(t.string),
  pictureId: t.union([t.null, t.string]),
})

export const ModelsArray = t.strict({
  designs: t.readonlyArray(Model),
})

export const Material = t.strict({
  color: t.string,
  id: t.string,
  name: t.string,
})

export const MaterialsArray = t.strict({
  materials: t.readonlyArray(Material),
})

export const ModelQuantities = [1, 2, 3, 4, 6, 12]

export const Worker = t.readonly(
  t.strict({
    associatedSite: t.readonly(t.strict({ id: t.string })),
    associatedWorkshop: t.readonly(t.strict({ id: t.string })),
    id: t.string,
    name: t.string,
    pictureId: t.union([t.null, t.string]),
    pictureUrl: t.union([t.null, t.string]),
    trigram: t.union([t.null, t.string]),
  }),
  'Worker',
)
export type Worker = t.TypeOf<typeof Worker>

export const BOWorker = t.readonly(
  t.strict({
    associatedSite: t.readonly(t.strict({ id: t.string })),
    associatedWorkshop: t.readonly(t.strict({ id: t.string })),
    id: t.string,
    name: t.string,
    pictureId: t.union([t.null, t.string]),
    pictureUrl: t.union([t.null, t.string]),
    trigram: t.union([t.null, t.string]),
    deleted: t.union([t.null, t.boolean]),
  }),
  'Worker',
)
export type BOWorker = t.TypeOf<typeof BOWorker>

export const WorkersArray = t.readonly(
  t.strict({
    filename: t.string,
    lastUpdate: DateFromISOString,
    workers: t.readonlyArray(Worker),
  }),
  'WorkersArray',
)
export type WorkersArray = t.TypeOf<typeof WorkersArray>

const UpdateWorker = t.readonly(
  t.strict({ pictureId: t.union([t.null, t.string]) }),
)
export type UpdateWorker = t.TypeOf<typeof UpdateWorker>

export const Workshop = t.readonly(
  t.strict({
    craft: t.string,
    id: t.string,
    name: t.string,
    pictureId: t.union([t.null, t.string]),
    pictureUrl: t.union([t.null, t.string]),
    site: t.readonly(t.strict({ id: t.string })),
  }),
)
export type Workshop = t.TypeOf<typeof Workshop>

export const WorkshopsArray = t.readonly(
  t.strict({
    filename: t.string,
    lastUpdate: DateFromISOString,
    workshops: t.readonlyArray(Workshop),
  }),
)
export type WorkshopsArray = t.TypeOf<typeof WorkshopsArray>

const UpdateWorkshop = t.readonly(
  t.strict({ pictureId: t.union([t.null, t.string]) }),
)
export type UpdateWorkshop = t.TypeOf<typeof UpdateWorkshop>

export type DeptType = t.TypeOf<typeof DeptType>
export type DeptsArrayType = t.TypeOf<typeof DeptsArrayType>

export type ModelType = t.TypeOf<typeof Model>
export type ModelsArrayType = t.TypeOf<typeof ModelsArray>

export type CreateModelType = t.TypeOf<typeof CreateModel>

export type MaterialType = t.TypeOf<typeof Material>
export type MaterialsArrayType = t.TypeOf<typeof MaterialsArray>

export type AssociatedMaterialsType = t.TypeOf<typeof AssociatedMaterials>

export function getModelsByDepartment(
  models: ModelsArrayType,
  deptId: string,
): ModelsArrayType {
  return { designs: models.designs.filter(d => d.department.id === deptId) }
}

export function sortDepartments(
  departments: readonly DeptType[],
): readonly DeptType[] {
  return departments.slice().sort((a, b) => a.name.localeCompare(b.name))
}

export function sortModels(models: readonly ModelType[]): readonly ModelType[] {
  return models.slice().sort((a, b) => a.name.localeCompare(b.name))
}

export function sortWorkers(workers: readonly Worker[]): readonly Worker[] {
  return workers.slice().sort((a, b) => a.name.localeCompare(b.name))
}

export function sortWorkshops(
  workshops: readonly Workshop[],
): readonly Workshop[] {
  return workshops.slice().sort((a, b) => a.name.localeCompare(b.name))
}
