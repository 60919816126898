import { useTheme as baseUseTheme } from 'emotion-theming'
import { theme as hermesTheme } from '@hermes/design-system'

export const theme = {
  ...hermesTheme,
  borderRadius: {
    ...hermesTheme.borderRadius,
    small: '6px',
  },
  boxShadows: {
    ...hermesTheme.boxShadows,
    modal: '0 2px 9px 0 rgba(0, 0, 0, 0.3)',
    table: '0 0 8px 0 rgba(184, 195, 220, 0.3)',
  },
  colors: {
    ...hermesTheme.colors,
    backdrop: 'rgba(0, 0, 0, 0.2)',
    background: '#F9FAFC',
    error: 'red', // TODO: The error color should come from the Design System and not be some random red.
    header: {
      background: '#333333',
      text: '#FFFFFF',
    },
    footer: {
      background: '#333333',
      text: '#FFFFFF',
    },
    greys: {
      ...hermesTheme.colors.greys,
      light25: '#DDDDDD',
    },
    text: '#40373A',
    transparent: 'transparent',
    white084: 'rgba(255, 255, 255, 0.84)',
  },
  fontImport:
    "@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');",
  fontSizes: {
    ...hermesTheme.fontSizes,
    logo: '24px',
    h1: '24px',
    h2: '22px',
  },
  fontWeights: {
    ...hermesTheme.fontWeights,
    default: {
      ...hermesTheme.fontWeights.default,
      bold: 700,
      medium: 600,
      regular: 400,
    },
  },
  fonts: {
    ...hermesTheme.fonts,
    default: "'Open Sans', sans-serif",
  },
  layout: {
    ...hermesTheme.layout,
    l35: '40px',
    l45: '60px',
    l55: '80px',
  },
  space: {
    ...hermesTheme.space,
    s35: '10px',
    s55: '20px',
  },
}

export type Theme = typeof theme

export const useTheme = () => baseUseTheme<Theme>()
