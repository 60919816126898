import { UIText } from '@hermes/design-system'
import React, { FC } from 'react'

import { Link, LinkProps } from '~/components/link'
import { SyncIcon } from '~/design/icons/SyncIcon'
import styled from '~/design/styled'
import { useTheme } from '~/design/theme'
import { formatDateTime } from '~/shared/date'

type Props = Readonly<
  Pick<LinkProps, 'disabled' | 'onClick'> & {
    className?: string
    filename: string
    date: Date
    label: string
    sync?: boolean
  }
>

export const SynchronizationControl: FC<Props> = ({
  className,
  filename,
  date,
  disabled,
  label,
  sync = true,
  onClick,
}) => {
  const theme = useTheme()
  return (
    <Container className={className}>
      {sync && (
        <LabelContainer>
          <SyncIcon fill={theme.colors.grey} />
          <Label disabled={disabled} onClick={onClick}>
            {label}
          </Label>
        </LabelContainer>
      )}
      <FilenameString>{filename}</FilenameString>
      <DateString>{formatDateTime(date)}</DateString>
    </Container>
  )
}

const Container = styled.div({
  alignItems: 'flex-end',
  display: 'flex',
  flexDirection: 'column',
  height: '32px',
  justifyContent: 'space-between',
})

const LabelContainer = styled.div(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
}))

const Label = styled(Link)(({ theme }) => ({
  color: theme.colors.text,
  fontSize: theme.fontSizes.small,
  fontWeight: theme.fontWeights.default.medium,
  marginInlineStart: theme.space.s30,
}))

const FilenameString = styled(UIText)(({ theme }) => ({
  color: theme.colors.grey,
  fontSize: theme.fontSizes.legend,
}))

const DateString = styled(UIText)(({ theme }) => ({
  color: theme.colors.grey,
  fontSize: theme.fontSizes.legend,
}))
