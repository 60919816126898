import React, { FC } from 'react'
import { TableHeader, TableCell } from '~/components/Table'
import styled from '~/design/styled'

interface Props {
  nbCell: number
}

export const TableHead: FC<Props> = ({ nbCell }) => {
  const nbCellArray = Array.from(new Array(nbCell))

  return (
    <TableHeaderStyled>
      <TableCell>Atelier de table</TableCell>
      {nbCellArray.map((cell, index) => (
        <TableCell key={`${index + 1}`}>
          <CuttingDot cuttingId={index + 1}>&nbsp;</CuttingDot>coupe c
          {index + 1}
        </TableCell>
      ))}
      <TableCell>Sans coupe</TableCell>
    </TableHeaderStyled>
  )
}

const CuttingDot = styled.div<{ cuttingId: number }>(
  ({ theme, cuttingId }) => ({
    borderRadius: '50%',
    width: '12px',
    height: '12px',
    marginRight: theme.space.s30,
    backgroundColor: getCuttingDotColor(cuttingId),
  }),
)

const getCuttingDotColor = (id: number) => {
  switch (id) {
    case 1:
      return '#2f7f9d'
    case 2:
      return '#f9b126'
    case 3:
      return '#42b52f'
    case 4:
      return '#8d3472'
    default:
      return '#000000'
  }
}

const TableHeaderStyled = styled(TableHeader)({
  fontSize: '10px',
})
