import { fold } from 'fp-ts/lib/Either'
import { pipe } from 'fp-ts/lib/pipeable'
import ky, { HTTPError, Options } from 'ky'
import * as t from 'io-ts'

import { API_VERSION } from '../configs/api.json'
import * as ApiErrors from './http-errors'
import getCredentials from './credentials'

const api = ky.extend({
  hooks: {
    beforeRequest: [
      async request => {
        const cognitoIdToken = await getCredentials()
        if (cognitoIdToken !== undefined) {
          request.headers.set('Authorization', `Bearer ${cognitoIdToken}`)
        }
      },
    ],
  },
})

type Validator<TResponse> = (i: unknown) => t.Validation<TResponse>

/**
 * @throws ErrorObject
 */
export async function fetch<TResponse>(
  url: string,
  options: Options = {},
  validator?: Validator<TResponse>,
): Promise<TResponse> {
  const urlObj = new URL(
    url,
    new URL(`${API_VERSION}/`, process.env.REACT_APP_API_BASE_URL).href,
  )
  const href =
    process.env.NODE_ENV === 'development' ? urlObj.pathname : urlObj.href
  try {
    const data = await api(href, options).json()
    if (validator !== undefined) {
      return pipe(
        validator(data),
        fold(
          (err: t.Errors) => {
            throw ApiErrors.fromValidation(href, err)
          },
          _ => _,
        ),
      )
    } else {
      return data as TResponse
    }
  } catch (e) {
    if (e instanceof HTTPError) {
      throw await ApiErrors.fromApi(href, e)
    } else if (e.status_name === 'VALIDATION_ERROR') {
      throw e
    } else {
      throw ApiErrors.fromUnknown(href, e)
    }
  }
}

/**
 * @throws ErrorObject
 */
export async function get<TResponse>(
  url: string,
  validator?: Validator<TResponse>,
  options: Options = {},
): Promise<TResponse> {
  return fetch(url, { ...options, method: 'get' }, validator)
}

/**
 * @throws ErrorObject
 */
export async function post<TResponse>(
  url: string,
  data: object,
  validator?: Validator<TResponse>,
  options: Options = {},
): Promise<TResponse> {
  return fetch(
    url,
    {
      ...options,
      method: 'post',
      json: data,
    },
    validator,
  )
}

/**
 * @throws ErrorObject
 */
export async function del<TResponse>(
  url: string,
  validator?: Validator<TResponse>,
  options: Options = {},
): Promise<TResponse> {
  return fetch(url, { ...options, method: 'delete' }, validator)
}

/**
 * @throws ErrorObject
 */
export async function put<TResponse>(
  url: string,
  data: object,
  validator?: Validator<TResponse>,
  options: Options = {},
): Promise<TResponse> {
  return fetch(
    url,
    {
      ...options,
      method: 'put',
      json: data,
    },
    validator,
  )
}
