import { PrimaryMediumButton } from '@hermes/design-system'
import React, { FC } from 'react'
import { Controller, useForm, DeepPartial } from 'react-hook-form'
import { array, object, string } from 'yup'

import { Radio, RadioGroup } from '~/components/Radio'
import { Label } from '~/components/inputs'
import { Modal, ModalProps } from '~/components/modal'
import styled from '~/design/styled'
import { MaterialsArrayType } from '~/shared/models/models'
import { FlowTypes, FlowTypesValue } from '~/shared/models/siteProperties'

import { getLeatherFlowLabel, getLeatherFlowValues } from './leatherFlow'

type Props = Readonly<
  Pick<ModalProps, 'onClose'> & {
    materials: MaterialsArrayType
    leatherFlow: FlowTypes | null
    onSubmit: (data: FlowTypes) => Promise<void>
  }
>

type FormData = Readonly<{
  leatherFlow: ReadonlyArray<string | undefined>
}>

const validationSchema = object<FormData>({
  leatherFlow: array(string()).test(
    'no-undefined',
    'Vous devez spécifier tous les types de flux cuir',
    (value: FormData['leatherFlow']) => !value.includes(undefined),
  ),
})

function transformFormData(
  materials: MaterialsArrayType,
  data: FormData,
): FlowTypes {
  return materials.materials.map((mat, index) => ({
    material: { id: mat.id },
    value: data.leatherFlow[index] as FlowTypesValue,
  }))
}

export const LeatherFlowModal: FC<Props> = ({
  onClose,
  materials,
  leatherFlow,
  onSubmit: propsOnSubmit,
}) => {
  const { control, formState, handleSubmit } = useForm<FormData>({
    mode: 'onChange',
    validationSchema,
    defaultValues: getDefaultValues(materials, leatherFlow),
  })
  const onSubmit = (data: FormData) => {
    propsOnSubmit(transformFormData(materials, data))
  }
  return (
    <Modal
      title={`${
        leatherFlow === null ? 'Configurer' : 'Modifier'
      } la distribution de matière`}
      onClose={formState.isSubmitting ? undefined : onClose}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        {materials.materials.map((material, index) => (
          <Field key={material.id}>
            <Label>{material.name}</Label>
            <Controller
              as={
                <FieldRadioGroup
                  key={`leather-flow-${material.id}`}
                  name={`leather-flow-${material.id}`}
                >
                  {getLeatherFlowValues().map(m => (
                    <FieldRadio
                      id={`leather-flow-${material.id}-${m}`}
                      key={`leather-flow-${material.id}-${m}`}
                      label={getLeatherFlowLabel(m)}
                      value={m}
                    />
                  ))}
                </FieldRadioGroup>
              }
              control={control}
              name={`leatherFlow[${index}]`}
            />
          </Field>
        ))}
        <SubmitButton
          disabled={
            !formState.dirty || !formState.isValid || formState.isSubmitting
          }
        >
          Valider
        </SubmitButton>
      </Form>
    </Modal>
  )
}

const getDefaultValues = (
  materials: MaterialsArrayType,
  leatherFlow: FlowTypes | null,
): DeepPartial<FormData> => {
  return {
    leatherFlow: materials.materials.map(
      mat => leatherFlow?.find(l => l.material.id === mat.id)?.value,
    ),
  }
}

const Form = styled.form({
  display: 'flex',
  flexDirection: 'column',
})

const Field = styled.div(({ theme }) => ({
  borderBlockEnd: `1px solid ${theme.colors.greys.light25}`,
  '&:not(:last-of-type)': {
    marginBlockEnd: theme.layout.l35,
  },
  '& > :first-of-type': {
    marginBlockEnd: theme.space.s55,
  },
}))

const FieldRadioGroup = styled(RadioGroup)({
  '& .radio-group-content': {
    justifyContent: 'flex-start',
  },
})

const FieldRadio = styled(Radio)({
  flex: 1,
})

const SubmitButton = styled(PrimaryMediumButton)(({ theme }) => ({
  alignSelf: 'center',
  marginBlockStart: theme.layout.l55,
  width: '220px',
}))
