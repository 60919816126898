import React, {
  ReactElement,
  ReactNode,
  createContext,
  useContext,
  useMemo,
} from 'react'
import { mutate } from 'swr'

import { ScreenLoader } from '~/components/screenLoader'
import {
  CreateSite,
  Status,
  createSite,
  getCurrentUser,
  getSites,
  removeSite as apiRemoveSite,
} from '~/shared/apis/routes'
import useHttp from '~/shared/apis/useHttp'
import { BackOfficeUser } from '~/shared/models/backOfficeUser'
import { SitesArrayType, addSite, removeSite } from '~/shared/models/sites'

type Administrator = Readonly<{
  data: AdministratorData
  mutators: AdministratorMutators
}>

type AdministratorData = Readonly<{
  currentUser: BackOfficeUser
  unconfiguredSites: SitesArrayType
}>

type AdministratorMutators = Readonly<{
  createSite: (site: CreateSite) => Promise<void>
  removeSite: (siteId: string) => Promise<void>
}>

type AdministratorProviderProps = Readonly<{ children: ReactNode }>

const AdministratorContext = createContext<Administrator | undefined>(undefined)

export function AdministratorProvider(
  props: AdministratorProviderProps,
): ReactElement {
  const { children } = props

  const { data: currentUser, error: currentUserError } = useHttp(
    'currentUser',
    () => getCurrentUser(),
  )
  const {
    data: unconfiguredSites,
    error: unconfiguredSitesError,
  } = useHttp('unconfiguredSites', () => getSites(Status.unconfigured))

  const data = useMemo(
    () =>
      currentUser === undefined || unconfiguredSites === undefined
        ? undefined
        : { currentUser, unconfiguredSites },
    [currentUser, unconfiguredSites],
  )

  const mutators = useMemo(
    () => (data === undefined ? undefined : getAdministratorMutators(data)),
    [data],
  )

  const value = useMemo(
    () =>
      data === undefined || mutators === undefined
        ? undefined
        : { data, mutators },
    [data, mutators],
  )

  if (currentUserError !== undefined || unconfiguredSitesError !== undefined) {
    return (
      <div>
        Une erreur s'est produite lors de la récupération des informations
        utilisateur
      </div>
    ) // TODO: improve error handling
  } else if (value === undefined) {
    return <ScreenLoader />
  } else {
    return (
      <AdministratorContext.Provider value={value}>
        {children}
      </AdministratorContext.Provider>
    )
  }
}

export function useAdministrator(): Administrator {
  const context = useContext(AdministratorContext)
  if (context === undefined) {
    throw new Error(
      'useAdministrator must be used within an AdministratorProvider',
    )
  }
  return context
}

const getAdministratorMutators: (
  data: AdministratorData,
) => AdministratorMutators = data => ({
  createSite: async site => {
    const newSite = await createSite(site)
    const newUnconfiguredSites = removeSite(data.unconfiguredSites, newSite)
    const newConfigurableSites = addSite(
      data.currentUser.configurableSites,
      newSite,
    )
    const newCurrentUser = {
      ...data.currentUser,
      configurableSites: newConfigurableSites,
    }
    mutate('currentUser', newCurrentUser, false)
    mutate('unconfiguredSites', newUnconfiguredSites, false)
  },
  removeSite: async siteId => {
    await apiRemoveSite(siteId)
    const site = data.currentUser.configurableSites.find(s => s.id === siteId)
    if (site === undefined) {
      throw new Error(`Cannot remove site "${siteId}".`)
    }
    const newUnconfiguredSites = addSite(data.unconfiguredSites, site)
    const newConfigurableSites = removeSite(
      data.currentUser.configurableSites,
      site,
    )
    const newCurrentUser = {
      ...data.currentUser,
      configurableSites: newConfigurableSites,
    }
    mutate('currentUser', newCurrentUser, false)
    mutate('unconfiguredSites', newUnconfiguredSites, false)
  },
})
