import React from 'react'

type Props = {
  className?: string
}
const DeadlineIcon: React.FC<Props> = ({ className }) => (
  <svg width="26" height="26" viewBox="0 0 26 26" className={className}>
    <defs>
      <path
        id="deadlineIcon"
        d="M13 0c7.17 0 13 5.83 13 13s-5.83 13-13 13S0 20.17 0 13 5.83 0 13 0zm0 1.56C6.682 1.56 1.56 6.682 1.56 13S6.682 24.44 13 24.44 24.44 19.318 24.44 13 19.318 1.56 13 1.56zM13 13V3.12c5.457 0 9.88 4.423 9.88 9.88 0 5.457-4.423 9.88-9.88 9.88a9.86 9.86 0 0 1-3.417-.607L13 13V3.12zm-6.5 5.72a.78.78 0 1 1 0 1.56.78.78 0 0 1 0-1.56zm-2.6-6.24l.106.007a.78.78 0 1 1-.106-.007zm2.6-6.76a.78.78 0 1 1 0 1.56.78.78 0 0 1 0-1.56z"
      />
    </defs>
    <use fill="#FC4D21" fillRule="evenodd" xlinkHref="#deadlineIcon" />
  </svg>
)

export default DeadlineIcon
