import React from 'react'

type Props = {
  fill?: string
  className?: string
}

const EditIcon: React.FC<Props> = ({ className, fill = '#ffffff' }) => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill={fill}
    className={className}
  >
    <path
      fill="inherit"
      fillRule="nonzero"
      d="M11.71.828c1.172-1.104 3.212-1.104 4.382 0 .586.552.908 1.285.908 2.066a2.82 2.82 0 0 1-.908 2.065l-10.39 9.795a.786.786 0 0 1-.36.192l-4.38 1.032a.798.798 0 0 1-.735-.192.709.709 0 0 1-.204-.694l1.094-4.13a.71.71 0 0 1 .203-.339zm-.547 2.58l-8.595 8.104-.73 2.753 2.921-.687 8.594-8.105-2.19-2.065zm3.834-1.547c-.584-.552-1.605-.552-2.19 0l-.547.515 2.19 2.065.547-.516c.291-.276.454-.641.454-1.032 0-.39-.163-.757-.454-1.032z"
    />
  </svg>
)

export default EditIcon
