import React, { FC, useState } from 'react'
import { AddingBtn } from '~/components/addingBtn'
import { AdminsList } from '~/components/site/adminsList'
import AddingAdminModal from '~/components/site/addingAdminModal'
import RemoveAdminModal from '~/components/site/removeAdminModal'
import { useToaster } from '~/components/Toaster'
import { useSiteConfiguration } from '~/shared/contexts/SiteConfigurationContext'

const maxCreatedAdmin = 5

const AdminsScreen: FC = () => {
  const {
    data: { admins },
    mutators,
  } = useSiteConfiguration()
  const { showToast } = useToaster()

  const [adminModal, setAdminModal] = useState<
    'ADD_ADMIN_MODAL' | 'REMOVE_ADMIN_MODAL' | undefined
  >()
  const [delAdminId, setDelAdminId] = useState<string>('')

  const openAddingAdminModal = () => {
    setAdminModal('ADD_ADMIN_MODAL')
  }
  const openRemoveAdminModal = (adminId: string) => {
    setDelAdminId(adminId)
    setAdminModal('REMOVE_ADMIN_MODAL')
  }
  const closeAdminModal = () => {
    setAdminModal(undefined)
  }

  const addAdmin = async (data: { id: string }) => {
    try {
      await mutators.addAdmin(data.id)
      showToast({
        intent: 'success',
        message: `L'administrateur ${data.id} a bien été ajouté`,
      })
    } catch (error) {
      showToast({
        intent: 'failure',
        message: `L'administrateur ${data.id} n'a pu être ajouté`,
      })
    }
  }

  const removeAdmin = async (adminId: string) => {
    try {
      await mutators.deleteAdmin(adminId)
      showToast({
        intent: 'success',
        message: `L'administrateur ${adminId} a bien été supprimé`,
      })
    } catch (error) {
      showToast({
        intent: 'failure',
        message: `L'administrateur ${adminId} n'a pu être supprimé`,
      })
    }
  }

  return (
    <>
      <AddingBtn
        onAdding={openAddingAdminModal}
        disabled={admins.admins.length >= maxCreatedAdmin}
        label="Ajouter un administrateur"
      />
      <AdminsList admins={admins} delAdminCallback={openRemoveAdminModal} />
      {adminModal === 'ADD_ADMIN_MODAL' && (
        <AddingAdminModal
          onSendingAdmin={addAdmin}
          closeAddingModalCallback={closeAdminModal}
        />
      )}
      {adminModal === 'REMOVE_ADMIN_MODAL' && (
        <RemoveAdminModal
          onDelAdmin={removeAdmin}
          onCloseModal={closeAdminModal}
          delAdminId={delAdminId}
        />
      )}
    </>
  )
}

export default AdminsScreen
