import React, { ReactElement } from 'react'

import styled from '~/design/styled'

import { RadioGroupProvider } from './RadioGroupContext'
import { RadioGroupProps } from './RadioGroupProps'

export function RadioGroup<V extends string = string>({
  children,
  className,
  name,
  onChange,
  value,
}: RadioGroupProps<V>): ReactElement {
  return (
    <RadioGroupProvider name={name} onChange={onChange} value={value}>
      <Fieldset className={className}>
        <FieldsetContent className="radio-group-content">
          {children}
        </FieldsetContent>
      </Fieldset>
    </RadioGroupProvider>
  )
}

const Fieldset = styled.fieldset({
  border: 'none',
  margin: 0,
  padding: 0,
})

const FieldsetContent = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
})
