/** @jsx jsx */

import { Interpolation, jsx } from '@emotion/core'
import { UIText } from '@hermes/design-system'
import { FC } from 'react'

import { FailureIcon } from '~/design/icons/FailureIcon'
import { SuccessIcon } from '~/design/icons/SuccessIcon'
import styled from '~/design/styled'

type Props = Readonly<
  Toast & {
    className?: string
  }
>

export type Toast = Readonly<{
  intent: Intent
  message: string
}>

type Intent = 'failure' | 'success'

export const Toast: FC<Props> = ({ className, intent, message }) => {
  const icon = {
    failure: <FailureIcon css={cssIcon} />,
    success: <SuccessIcon css={cssIcon} />,
  }[intent]
  return (
    <Container className={className} intent={intent}>
      {icon}
      <Text>{message}</Text>
    </Container>
  )
}

const Container = styled.div<Pick<Props, 'intent'>>(({ intent, theme }) => {
  const backgroundColor = {
    failure: theme.colors.error,
    success: theme.colors.green,
  }[intent]
  return {
    alignItems: 'center',
    backgroundColor,
    borderRadius: '20px',
    boxShadow: theme.boxShadows.table,
    display: 'flex',
    flexDirection: 'row',
    height: '40px',
    padding: `0px ${theme.space.s55}`,
  }
})

const cssIcon: Interpolation = {
  flexShrink: 0,
}

const Text = styled(UIText)(({ theme }) => ({
  color: theme.colors.white,
  fontSize: theme.fontSizes.legend,
  marginInlineStart: theme.space.s40,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}))
