import { InterpolationWithTheme } from '@emotion/core'
import React, {
  DragEvent,
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react'

import styled from '~/design/styled'

type Props = Readonly<{
  children: (hover: boolean) => ReactNode
  className?: string
  css?: InterpolationWithTheme<any>
  disabled?: boolean
  onDrop: (file: File) => void
}>

const dropEffect = 'copy'

export const FileDropTarget: FC<Props> = ({
  children,
  className,
  disabled = false,
  css,
  onDrop,
}) => {
  const [hover, setHover] = useState(false)

  const handleDragLeave = useCallback(() => {
    if (!disabled) {
      setHover(false)
    }
  }, [disabled])
  const handleDragOver = useCallback(
    (event: DragEvent<HTMLDivElement>) => {
      if (!disabled && event.dataTransfer.types.includes('Files')) {
        event.preventDefault()
        event.dataTransfer.dropEffect = dropEffect
        setHover(true)
      }
    },
    [disabled],
  )
  const handleDrop = useCallback(
    (event: DragEvent<HTMLDivElement>) => {
      if (!disabled) {
        event.preventDefault()
        event.dataTransfer.dropEffect = dropEffect
        setHover(false)
        const file = event.dataTransfer.files[0]
        onDrop(file)
      }
    },
    [disabled, onDrop],
  )

  useEffect(() => {
    if (disabled) {
      setHover(false)
    }
  }, [disabled])

  return (
    <Container
      className={className}
      css={css}
      onDragEnter={handleDragOver}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      {children(hover)}
    </Container>
  )
}

const Container = styled.div({
  display: 'flex',
})
