import React, { FC } from 'react'

type Props = Readonly<{
  className?: string
}>

export const MaterialCallStepIcon: FC<Props> = ({ className }) => (
  <svg className={className} height="26" viewBox="0 0 26 26" width="26">
    <defs>
      <path
        d="M7.384 0h4.968c.437 0 .802.33.855.754l.007.108v1.57c0 .016.009.03.022.038l.022.006h1.817c.437 0 .802.33.855.754l.007.108v.67c0 .016.009.03.022.038l.022.006h2.774c.437 0 .8.33.854.753l.007.108v20.27c-.05.4-.375.754-.764.81l-.108.008H.981c-.437 0-.8-.33-.854-.754l-.007-.108V4.914c0-.438.33-.802.753-.855l.108-.007h2.775c.016 0 .03-.009.038-.022l.006-.022v-.67c0-.437.33-.802.754-.855l.107-.007h1.817c.016 0 .03-.009.038-.022l.006-.022V.862c0-.438.33-.802.754-.855L7.384 0h4.968zM3.702 5.686H1.744a.043.043 0 00-.038.022L1.7 5.73v18.636h16.227V5.73a.044.044 0 00-.021-.038l-.022-.006H15.98a.043.043 0 00-.038.022l-.006.022v1.422c0 .341-.16.6-.42.728a.829.829 0 01-.33.134l-.122.01H4.608a.864.864 0 01-.855-.754l-.007-.108V5.73a.044.044 0 00-.044-.044zM15.2 10.4l1.56 1.56L7.4 20.8l-4.16-4.16 1.56-1.56 2.6 2.6 7.8-7.28zm-.942-6.289H5.479a.045.045 0 00-.038.022l-.006.022v2.136c0 .016.009.03.022.038l.022.006h8.779c.016 0 .03-.009.038-.022l.006-.022V4.155a.044.044 0 00-.044-.044zm-2.722-2.477H8.2a.045.045 0 00-.038.022l-.006.022v.754c0 .016.009.03.022.038l.022.006h3.335c.016 0 .03-.01.038-.022l.006-.022v-.754a.044.044 0 00-.044-.044z"
        id="material-call-step-icon"
      />
    </defs>
    <use
      fill="#FC4D21"
      fillRule="evenodd"
      transform="translate(3)"
      xlinkHref="#material-call-step-icon"
    />
  </svg>
)
