import React, { FC, useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'

import { ModelCreationModal } from '~/components/models/ModelCreationModal'
import { HeaderModels } from '~/components/models/headerModels'
import { ModelsList } from '~/components/models/modelsList'
import RemoveModelModal from '~/components/models/removeModelModal'
import { useToaster } from '~/components/Toaster'
import styled from '~/design/styled'
import { useSiteConfiguration } from '~/shared/contexts/SiteConfigurationContext'
import {
  CreateModelType,
  ModelType,
  getModelsByDepartment,
} from '~/shared/models/models'

type Params = Readonly<{
  deptId: string
}>

type ModalState =
  | Readonly<
      { mode: 'create' | 'delete' } | { mode: 'update'; model: ModelType }
    >
  | undefined

const ModelsScreen: FC = () => {
  const { deptId } = useParams<Params>()

  const [modal, setModal] = useState<ModalState>(undefined)
  const [removeDataModel, setRemoveDataModel] = useState<
    { id: string; name: string } | undefined
  >(undefined)

  const {
    data: { departments, materials, models },
    mutators: { createModel, deleteModel, updateModel },
    siteId,
  } = useSiteConfiguration()
  const { showToast } = useToaster()

  const deptModels = getModelsByDepartment(models, deptId)

  const addingModel = () => {
    setModal({ mode: 'create' })
  }

  const updtModel = (model: ModelType) => {
    setModal({ mode: 'update', model: model })
  }

  const sendingModel = useCallback(
    async (model: CreateModelType) => {
      try {
        await createModel(model)
        setModal(undefined)
        showToast({
          intent: 'success',
          message: 'Votre modèle a bien été ajouté',
        })
      } catch (error) {
        setModal(undefined)
        showToast({
          intent: 'failure',
          message: `Votre modèle n'a pu être ajouté`,
        })
      }
    },
    [createModel, showToast],
  )

  const sendingUpdateModel = useCallback(
    async (model: CreateModelType) => {
      if (modal?.mode === 'update') {
        try {
          await updateModel(modal.model.id, model)
          setModal(undefined)
          showToast({
            intent: 'success',
            message: 'Votre modèle a bien été modifié',
          })
        } catch (error) {
          setModal(undefined)
          showToast({
            intent: 'failure',
            message: `Votre modèle n'a pu être modifié`,
          })
        }
      }
    },
    [updateModel, modal, showToast],
  )

  const backLink = {
    url: `/site/${siteId}/models-departments`,
    label: departments?.departments.find(item => item.id === deptId)?.name,
  }

  const removeModel = useCallback(
    async (modelId: string) => {
      try {
        await deleteModel(modelId)
        showToast({
          intent: 'success',
          message: 'Votre modèle a bien été supprimé',
        })
      } catch (error) {
        showToast({
          intent: 'failure',
          message: `Votre modèle n'a pu être supprimé`,
        })
      }
    },
    [deleteModel, showToast],
  )

  const removeModelModal = (data: { id: string; name: string }) => {
    setModal({ mode: 'delete' })
    setRemoveDataModel({
      id: data.id,
      name: data.name,
    })
  }

  return (
    <>
      <HeaderModelsStyled
        backLink={backLink}
        addModelsDisabled={false}
        onAddModelsClicked={() => addingModel()}
      />
      {modal?.mode === 'create' && (
        <ModelCreationModal
          defaultDeptId={deptId}
          departments={departments}
          materials={materials}
          onClose={() => {
            setModal(undefined)
          }}
          onSubmit={sendingModel}
          submitButtonText="Ajouter"
          title="Nouveau modèle"
        />
      )}
      {modal?.mode === 'update' && (
        <ModelCreationModal
          departments={departments}
          materials={materials}
          model={modal.model}
          onClose={() => {
            setModal(undefined)
          }}
          onSubmit={sendingUpdateModel}
          submitButtonText="Sauvegarder"
          title="Modifier modèle"
        />
      )}
      {deptModels.designs.length > 0 ? (
        <>
          <ModelsList
            materials={materials}
            models={deptModels}
            removeModel={removeModelModal}
            updateModel={updtModel}
            deptId={deptId}
          />

          {modal?.mode === 'delete' && removeDataModel !== undefined && (
            <RemoveModelModal
              onCloseModal={() => {
                setModal(undefined)
              }}
              onDelModel={removeModel}
              delModelData={removeDataModel}
            />
          )}
        </>
      ) : (
        <p>Aucun modèle dans ce département</p>
      )}
    </>
  )
}

const HeaderModelsStyled = styled(HeaderModels)(() => ({
  marginBottom: 0,
}))

export default ModelsScreen
