import React, { FC } from 'react'

import { Table, TableCell, TableRow } from '~/components/Table'
import { SiteError } from "~/shared/models/siteErrors";
import styled from "~/design/styled";

type Props = {
  errors: SiteError[]
}

export const SiteErrorsList: FC<Props> = ({errors}) => {
  return (
    <Table layout={[
      { flex: 3 },
      { flex: 3 },
      { flex: 3 },
      { flex: 1, justifyContent: 'flex-end' },
    ]}>
      {errors.map((error, index) => (
        <TableRow key={index}>
          <TableCell>
            <SiteErrorMessage>{error.message}</SiteErrorMessage>
          </TableCell>
        </TableRow>
      ))}
    </Table>
  )
}

const SiteErrorMessage = styled.span({
  whiteSpace: 'normal',
})
