import React, {
  CSSProperties,
  ReactElement,
  ReactNode,
  createContext,
  useContext,
} from 'react'

type TableLayoutProviderProps = Readonly<{
  children?: ReactNode
  layout: TableLayout
}>

export type TableLayout = readonly (CSSProperties | string)[]

const TableLayoutContext = createContext<TableLayout | undefined>(undefined)

export function TableLayoutProvider(
  props: TableLayoutProviderProps,
): ReactElement {
  return (
    <TableLayoutContext.Provider value={props.layout}>
      {props.children}
    </TableLayoutContext.Provider>
  )
}

export function useTableLayout(): TableLayout {
  const layout = useContext(TableLayoutContext)
  if (layout === undefined) {
    throw new Error('useTableLayout must be used within a TableLayoutProvider')
  }
  return layout
}
