import React, { FC } from 'react'

import styled from '~/design/styled'

import { TableRow, TableRowProps } from './TableRow'

type Props = Pick<TableRowProps, 'className' | 'css'>

export const TableHeader: FC<Props> = ({ children, className, css }) => (
  <TableRowStyled className={className} css={css}>
    {children}
  </TableRowStyled>
)

const TableRowStyled = styled(TableRow)(({ theme }) => ({
  fontWeight: theme.fontWeights.default.medium,
  height: '64px',
  textTransform: 'uppercase',
}))
