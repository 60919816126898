import { CloseIcon } from '@hermes/design-system'
import React, { FC, useCallback, useMemo } from 'react'

import { Clickable } from '~/components/clickable'
import { Table, TableCell, TableHeader, TableRow } from '~/components/Table'
import styled from '~/design/styled'
import { AdminType, AdminsType, sortAdmins } from '~/shared/models/site'

type AdminsListProps = Readonly<{
  admins: AdminsType
  delAdminCallback: (adminId: string) => void
}>

export const AdminsList: FC<AdminsListProps> = ({
  admins,
  delAdminCallback,
}) => {
  const layout = useMemo(() => [{ flex: 1 }, {}], [])
  const sortedAdmins = useMemo(() => sortAdmins(admins.admins), [admins])
  return (
    <Table layout={layout}>
      <TableHeader>
        <TableCell>Administrateur</TableCell>
        <TableCell>Action</TableCell>
      </TableHeader>
      {sortedAdmins.map(admin => (
        <AdminsListItem
          admin={admin}
          key={admin.id}
          onDelete={delAdminCallback}
        />
      ))}
    </Table>
  )
}

type AdminsListItemProps = Readonly<{
  admin: AdminType
  onDelete: (adminId: string) => void
}>

const AdminsListItem: FC<AdminsListItemProps> = ({ admin, onDelete }) => {
  const handleDelete = useCallback(() => {
    onDelete(admin.id)
  }, [admin, onDelete])
  return (
    <TableRow key={admin.id}>
      <TableCell>
        <Username>{admin.id}</Username>
      </TableCell>
      <TableCell>
        <Clickable onClick={handleDelete}>
          <CloseIconStyled />
        </Clickable>
      </TableCell>
    </TableRow>
  )
}

const Username = styled.span(({ theme }) => ({
  color: theme.colors.text,
  fontWeight: theme.fontWeights.default.medium,
}))

const CloseIconStyled = styled(CloseIcon)(({ theme }) => ({
  fill: theme.colors.text,
}))
