import React, { FC, SyntheticEvent, useState } from 'react'
import styled from '~/design/styled'

import { UIText, CloseIcon } from '@hermes/design-system'
import HintIcon from '~/design/icons/hintIcon'
import { HEADER_HEIGHT } from '~/components/layout'
import { CenteredContentContainer } from '~/components/CenteredContentContainer'
import { Clickable } from '~/components/clickable'

type Props = Readonly<{
  onClick?: () => void
}>

export const ParametersBanner: FC<Props> = ({ children, onClick }) => {
  const [hide, setHideState] = useState(true)
  const hideBanner = (event: SyntheticEvent) => {
    event.stopPropagation()
    setHideState(false)
  }
  if (hide) {
    return (
      <StyledParametersBanner>
        <BannerContainer onClick={onClick}>
          <StyledHintIcon fill="#ffffff" />
          <InformationText>{children}</InformationText>
          <ClickableHideBannerIcon onClick={hideBanner}>
            <HideBannerIcon />
          </ClickableHideBannerIcon>
        </BannerContainer>
      </StyledParametersBanner>
    )
  } else {
    return null
  }
}

const StyledParametersBanner = styled.div(({ theme }) => ({
  position: 'fixed',
  zIndex: 9990,
  left: 0,
  right: 0,
  top: HEADER_HEIGHT,
  background: theme.colors.orange,
}))

const BannerContainer = styled(CenteredContentContainer)<
  Pick<Props, 'onClick'>
>(({ onClick, theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexFlow: 'row nowrap',
  height: '60px',
  ':hover': {
    cursor: onClick === undefined ? 'initial' : 'pointer',
  },
}))

const StyledHintIcon = styled(HintIcon)({
  flexShrink: 0,
})

const InformationText = styled(UIText)(({ theme }) => ({
  color: theme.colors.white,
  fontWeight: theme.fontWeights.default.regular,
  marginLeft: theme.space.s40,
}))

const ClickableHideBannerIcon = styled(Clickable)(({ theme }) => ({
  paddingLeft: theme.space.s40,
  marginLeft: 'auto',
}))

const HideBannerIcon = styled(CloseIcon)(({ theme }) => ({
  fill: theme.colors.white,
}))
