import React, { FC, useCallback, useState } from 'react'

import { WorkshopEditionModal } from '~/components/workshops/WorkshopEditionModal'
import { WorkshopsList } from '~/components/workshops/WorkshopsList'
import { SynchronizationControl } from '~/components/SynchronizationControl'
import { useToaster } from '~/components/Toaster'
import styled from '~/design/styled'
import { useSiteConfiguration } from '~/shared/contexts/SiteConfigurationContext'
import { UpdateWorkshop, Workshop } from '~/shared/models/models'
import { CuttingZonesArrayType } from '~/shared/models/siteProperties'

type WorkshopEditionModalState = Workshop | undefined

type Props = {
  cuttingZones?: CuttingZonesArrayType | null
}
const WorkshopsScreen: FC<Props> = ({ cuttingZones }) => {
  const {
    data: { workers, workshops },
    mutators: { updateWorkshop },
  } = useSiteConfiguration()
  const { showToast } = useToaster()

  const [workshopEditionModalState, setWorkshopEditionModalState] = useState<
    WorkshopEditionModalState
  >(undefined)

  const editWorkshop = useCallback((workshop: Workshop) => {
    setWorkshopEditionModalState(workshop)
  }, [])

  const closeWorkshopEditionModal = useCallback(() => {
    setWorkshopEditionModalState(undefined)
  }, [])

  const submitWorkshopEditionModal = useCallback(
    async (workshop: Workshop, update: UpdateWorkshop) => {
      try {
        await updateWorkshop(workshop.id, update)
        setWorkshopEditionModalState(undefined)
        showToast({
          intent: 'success',
          message: 'Votre atelier a bien été modifié',
        })
      } catch (error) {
        setWorkshopEditionModalState(undefined)
        showToast({
          intent: 'failure',
          message: "Votre atelier n'a pu être modifié",
        })
      }
    },
    [showToast, updateWorkshop],
  )

  return (
    <>
      <SynchronizationControlStyled
        filename={workers.filename}
        date={workers.lastUpdate}
        label="Synchroniser la liste des ateliers"
        sync={false}
      />
      <WorkshopsList
        editWorkshop={editWorkshop}
        workshops={workshops}
        cuttingZones={cuttingZones}
      />
      {workshopEditionModalState && (
        <WorkshopEditionModal
          onClose={closeWorkshopEditionModal}
          onSubmit={submitWorkshopEditionModal}
          workshop={workshopEditionModalState}
        />
      )}
    </>
  )
}

export default WorkshopsScreen

const SynchronizationControlStyled = styled(SynchronizationControl)(
  ({ theme }) => ({
    marginBlockEnd: theme.layout.l20,
    marginBlockStart: theme.layout.l35,
  }),
)
