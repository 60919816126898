import * as t from 'io-ts'

import { SitesArrayType } from '~/shared/models/sites'

const Status = t.keyof({
  admin: null,
  superadmin: null,
  unknown: null,
})

export const BackOfficeUser = t.readonly(
  t.strict({
    configurableSites: SitesArrayType,
    id: t.string,
    status: Status,
  }),
)
export type BackOfficeUser = t.TypeOf<typeof BackOfficeUser>
