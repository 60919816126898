const {
  REACT_APP_AUTH_OAUTH_DOMAIN = '',
  REACT_APP_AUTH_OAUTH_REDIRECT_SIGN_IN = '',
  REACT_APP_AUTH_OAUTH_REDIRECT_SIGN_OUT = '',
  REACT_APP_AUTH_PROVIDER = '',
  REACT_APP_AUTH_USER_POOL_ID = '',
  REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID = '',
} = process.env

export default function() {
  return {
    Auth: {
      oauth: {
        domain: REACT_APP_AUTH_OAUTH_DOMAIN,
        redirectSignIn: new URL(
          REACT_APP_AUTH_OAUTH_REDIRECT_SIGN_IN,
          window.location.origin,
        ).href,
        redirectSignOut: new URL(
          REACT_APP_AUTH_OAUTH_REDIRECT_SIGN_OUT,
          window.location.origin,
        ).href,
        responseType: 'code',
        scope: ['openid'],
      },
      provider: REACT_APP_AUTH_PROVIDER,
      mandatorySignIn: true,
      userPoolId: REACT_APP_AUTH_USER_POOL_ID,
      userPoolWebClientId: REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID,
    },
  }
}
