import React from 'react'

type Props = {
  className?: string
}
const LockIcon: React.FC<Props> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="26"
    height="26"
    viewBox="0 0 26 26"
    className={className}
  >
    <defs>
      <path
        id="lockIcon"
        d="M10 0c3.285 0 5.72 2.895 5.72 6.24v4.16h1.087c1.408 0 2.553 1.167 2.553 2.6v10.4c0 1.433-1.145 2.6-2.553 2.6H3.193C1.785 26 .64 24.833.64 23.4V13c0-1.433 1.145-2.6 2.553-2.6H4.28V6.24C4.28 2.895 6.715 0 10 0zm6.76 11.96H3.24c-.47 0-1.04.562-1.04 1.04v10.4c0 .478.57 1.04 1.04 1.04h13.52c.47 0 1.04-.562 1.04-1.04V13c0-.478-.57-1.04-1.04-1.04zM10 14.04c1.436 0 2.6 1.187 2.6 2.652 0 .998-.541 1.867-1.34 2.32l.438 3.576c.02.16-.095.292-.254.292H8.556c-.16 0-.273-.131-.254-.292l.439-3.576c-.8-.453-1.341-1.322-1.341-2.32 0-1.465 1.164-2.652 2.6-2.652zm0-12.48c-2.346 0-4.16 2.29-4.16 4.68v4.16h8.32V6.24c0-2.39-1.814-4.68-4.16-4.68z"
      />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(3)">
      <use fill="#FC4D21" xlinkHref="#lockIcon" />
    </g>
  </svg>
)

export default LockIcon
