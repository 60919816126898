import React from 'react'
import styled from '~/design/styled'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'

import { PrimaryMediumButton } from '@hermes/design-system'
import { Label, InputPassword } from '~/components/inputs'

import { Modal } from '~/components/modal'
import { Hint } from '~/components/hint'

const passwordRules = {
  lowercase: '(?=.*[a-z])',
  uppercase: '(?=.*[A-Z])',
  numeric: '(?=.*[0-9])',
  special: '(?=.*[!@#$%^&*])',
  length: '(?=.{8,})',
}
const passwordRegEx = new RegExp(`^${Object.values(passwordRules).join('')}`)

const passwordSchema = yup.object({
  password: yup
    .string()
    .required('Le mot de passe est requis')
    .matches(
      new RegExp(passwordRules.lowercase),
      'Au moins une minuscule est requise',
    )
    .matches(
      new RegExp(passwordRules.uppercase),
      'Au moins une majuscule est requise',
    )
    .matches(
      new RegExp(passwordRules.numeric),
      'Au moins un chiffre est requis',
    )
    .matches(
      new RegExp(passwordRules.special),
      'Au moins un caractère spécial (!@#$%^&*) est requis',
    )
    .matches(
      new RegExp(passwordRules.length),
      'Au moins 8 caractères sont requis',
    )
    .matches(passwordRegEx, "Le mot de passe n'est pas valide"),
  confirmationPassword: yup
    .string()
    .required()
    .oneOf([yup.ref('password')], 'Les mots de passe doivent correspondre'),
})

interface Props {
  formType: 'fill' | 'modify'
  onClose: () => void
  onSubmit: (password: string) => Promise<void>
}

type PasswordFormData = {
  password: string
  confirmationPassword: string
}

const UpdatePasswordModal: React.FC<Props> = ({
  formType,
  onSubmit,
  onClose,
}) => {
  const { control, handleSubmit, formState, errors } = useForm<
    PasswordFormData
  >({
    mode: 'onChange',
    validationSchema: passwordSchema,
    defaultValues: {
      password: '',
      confirmationPassword: '',
    },
  })

  const canSubmit =
    formState.dirty && formState.isValid && !formState.isSubmitting

  const submitAndClose = async (data: PasswordFormData) => {
    if (data.password !== undefined) {
      await onSubmit(data.password)
      onClose()
    }
  }

  return (
    <Modal
      title={`${
        formType === 'fill' ? 'Configurer' : 'Modifier'
      } le mot de passe responsable d'atelier`}
      onClose={formState.isSubmitting ? undefined : onClose}
    >
      <ClosedDatesForm onSubmit={handleSubmit(submitAndClose)}>
        <Label htmlFor="password">Mot de passe</Label>
        <Controller
          name="password"
          as={
            <InputPassword
              disabled={formState.isSubmitting}
              id="password"
              placeholder="Nouveau mot de passe"
              error={errors.password?.message}
            />
          }
          control={control}
        />
        <Controller
          name="confirmationPassword"
          as={
            <InputPassword
              id="password_confirm"
              disabled={formState.isSubmitting}
              placeholder="Confirmer le nouveau mot de passe"
              error={
                (errors.confirmationPassword?.type === 'oneOf' &&
                  errors.confirmationPassword?.message) ||
                undefined
              }
            />
          }
          control={control}
        />
        <Footer>
          <Hint>
            Le mot de passe doit contenir au moins 8 caractères dont 1
            majuscule, 1 minuscule, 1 chiffre, un caractère spécial (!@#$%^&*)
          </Hint>
          <SubmitButton disabled={!canSubmit}>Valider</SubmitButton>
        </Footer>
      </ClosedDatesForm>
    </Modal>
  )
}

const ClosedDatesForm = styled.form(({ theme }) => ({
  display: 'grid',
  gridRowGap: theme.layout.l10,
}))

const Footer = styled.div(({ theme }) => ({
  paddingTop: theme.layout.l10,
  display: 'grid',
  gridRowGap: theme.layout.l10,
  justifyItems: 'center',
}))

const SubmitButton = styled(PrimaryMediumButton)({
  minWidth: '220px',
})

export default UpdatePasswordModal
