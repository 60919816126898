import React from 'react'
import styled from '~/design/styled'
import { Title2 } from '@hermes/design-system'
import { AddingBtn } from '~/components/addingBtn'

type Props = Readonly<{
  addSiteStatus: 'disabled' | 'enabled' | 'hidden'
  onAddSiteClicked: () => void
}>

export const HeaderSites: React.FC<Props> = ({
  addSiteStatus,
  onAddSiteClicked,
}) => {
  return (
    <HeaderSitesStyled>
      <Title2Styled>Maroquineries</Title2Styled>
      {addSiteStatus !== 'hidden' && (
        <AddingBtnStyled
          label="Ajouter une nouvelle maroquinerie"
          onAdding={onAddSiteClicked}
          disabled={addSiteStatus === 'disabled'}
        />
      )}
    </HeaderSitesStyled>
  )
}

const HeaderSitesStyled = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.layout.l50,
}))

const Title2Styled = styled(Title2)({
  fontSize: '24px',
})

const AddingBtnStyled = styled(AddingBtn)({
  margin: 0,
})
