import React from 'react'
import styled from '~/design/styled'
import { Modal } from '~/components/modal'
import { InputText, PrimaryMediumButton } from '@hermes/design-system'
import { Controller, useForm } from 'react-hook-form'

type FormData = {
  id: string
}
interface Props {
  closeAddingModalCallback: () => void
  onSendingAdmin: (data: FormData) => void
}

const AddingAdminModal: React.FC<Props> = ({
  closeAddingModalCallback,
  onSendingAdmin,
}) => {
  const { control, handleSubmit, formState } = useForm<FormData>({
    mode: 'onChange',
  })
  const onSubmit = (data: FormData) => {
    onSendingAdmin(data)
    closeAddingModalCallback()
  }
  const closeAddingModal = () => {
    closeAddingModalCallback()
  }

  return (
    <Modal title="Ajouter un administrateur" onClose={closeAddingModal}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          as={<InputText placeholder="Renseignez un compte Atlas" />}
          control={control}
          name="id"
          rules={{ required: true }}
        />
        <RowCenter>
          <PrimaryMediumButton
            disabled={
              !formState.dirty || !formState.isValid || formState.isSubmitting
            }
          >
            Ajouter
          </PrimaryMediumButton>
        </RowCenter>
      </form>
    </Modal>
  )
}

const RowCenter = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: theme.space.s80,
}))
export default AddingAdminModal
