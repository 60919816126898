import React, {
  ReactElement,
  ReactNode,
  createContext,
  useContext,
  useMemo,
} from 'react'

import { RadioGroupProps } from './RadioGroupProps'

type RadioGroupProviderProps<V extends string> = Readonly<
  RadioGroup<V> & { children?: ReactNode }
>

type RadioGroup<V extends string> = Pick<
  RadioGroupProps<V>,
  'name' | 'onChange' | 'value'
>

const RadioGroupContext = createContext<RadioGroup<any> | undefined>(undefined)

export function RadioGroupProvider<V extends string>({
  children,
  name,
  onChange,
  value,
}: RadioGroupProviderProps<V>): ReactElement {
  const providerValue = useMemo(() => ({ name, onChange, value }), [
    name,
    onChange,
    value,
  ])
  return (
    <RadioGroupContext.Provider value={providerValue}>
      {children}
    </RadioGroupContext.Provider>
  )
}

export function useRadioGroup<V extends string>(): RadioGroup<V> | undefined {
  return useContext(RadioGroupContext) as RadioGroup<V> | undefined
}
