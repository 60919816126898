import { PrimaryMediumButton, UIText } from '@hermes/design-system'
import React, { FC, useCallback } from 'react'
import { Controller, DeepPartial, useForm } from 'react-hook-form'
import { object, string } from 'yup'

import { ImageResizer } from '~/components/ImageResizer'
import { Hint } from '~/components/hint'
import { Label } from '~/components/inputs'
import { Modal, ModalProps } from '~/components/modal'
import { WorkshopIcon } from '~/design/icons/WorkshopIcon'
import styled from '~/design/styled'
import { UpdateWorkshop, Workshop } from '~/shared/models/models'

type Props = Readonly<
  Pick<ModalProps, 'onClose'> & {
    onSubmit: (workshop: Workshop, update: UpdateWorkshop) => Promise<void>
    workshop: Workshop
  }
>

type FormData = Readonly<{
  pictureId: string | undefined
}>

const validationSchema = object<FormData>({
  pictureId: string(),
})

export const WorkshopEditionModal: FC<Props> = ({
  onClose,
  onSubmit: propsOnSubmit,
  workshop,
}) => {
  const { control, formState, handleSubmit, setValue } = useForm<FormData>({
    defaultValues: getDefaultValues(workshop),
    mode: 'onChange',
    validationSchema,
  })
  const handlePictureIdReset = useCallback(() => {
    setValue('pictureId', undefined, true)
  }, [setValue])
  const onSubmit = useCallback(
    (data: FormData) => propsOnSubmit(workshop, transformFormData(data)),
    [propsOnSubmit, workshop],
  )
  return (
    <Modal
      onClose={formState.isSubmitting ? undefined : onClose}
      title="Modifier atelier"
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormRow>
          <FormColumn>
            <Field>
              <Label>Nom de l'atelier</Label>
              <FormText>{workshop.name}</FormText>
            </Field>
            <Hint>
              Pour modifier le nom d'un atelier, vous devez accéder à M3
            </Hint>
          </FormColumn>
          <FormColumn>
            <Controller
              as={
                <FormImageResizer
                  disabled={formState.isSubmitting}
                  hoverMessage="Déposer une image"
                  message="Glisser-déposer l'image"
                  modalSize="small"
                  modalTitle="Redimensionner l'image"
                  onReset={handlePictureIdReset}
                  placeholder={<WorkshopIcon />}
                  title="Image de l'atelier"
                />
              }
              control={control}
              name="pictureId"
              valueName="source"
            />
          </FormColumn>
        </FormRow>
        <SubmitButton
          disabled={
            !formState.dirty || !formState.isValid || formState.isSubmitting
          }
        >
          Sauvegarder
        </SubmitButton>
      </Form>
    </Modal>
  )
}

function getDefaultValues(workshop: Workshop): DeepPartial<FormData> {
  return { pictureId: workshop.pictureUrl ?? undefined }
}

function transformFormData(data: FormData): UpdateWorkshop {
  return { pictureId: data.pictureId ?? null }
}

const Form = styled.form({
  display: 'flex',
  flexDirection: 'column',
})

const FormRow = styled.div({
  display: 'flex',
  flexDirection: 'row',
  '> *': {
    flex: 1,
  },
})

const FormColumn = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
})

const Field = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  '> :first-of-type': {
    marginBlockEnd: theme.layout.l10,
  },
}))

const FormText = styled(UIText)(({ theme }) => ({
  color: theme.colors.text,
}))

const FormImageResizer = styled(ImageResizer)({
  alignSelf: 'flex-end',
})

const SubmitButton = styled(PrimaryMediumButton)(({ theme }) => ({
  alignSelf: 'center',
  marginBlockStart: theme.layout.l45,
}))
