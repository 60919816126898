/** @jsx jsx */

import { InterpolationWithTheme, jsx } from '@emotion/core'
import { FC } from 'react'

type Props = Readonly<{
  className?: string
  css?: InterpolationWithTheme<any>
}>

const PlaceholderLeatherGoodsIcon: FC<Props> = ({ className, css }) => (
  <svg
    width="88"
    height="76"
    viewBox="0 0 88 76"
    className={className}
    css={css}
  >
    <path
      fill="#FFF"
      d="M8 0C3.393 0 0 3.322 0 8v61c0 4.089 2.974 7 7 7h73.667C84.693 76 88 72.642 88 68.553V20c0-4.089-3.974-8-8-8h-5V2c0-1.114-.903-2-2-2H8zm0 4h63v8H8c-2.356 0-4-1.75-4-4s1.644-4 4-4zM4 15c1.24.737 2.47 1 4 1 23.71-.021 51.622 0 72 0 1.786 0 4 2.174 4 3.995V30H53c-2.88 0-5 2.063-5 5v18c0 3 2 5 5 5h31v11c0 1.821-1.214 3-3 3H7c-1.786 0-3-1.179-3-3V15zm49 19h31v20H53c-.628 0-1-.379-1-1V35c0-.621.372-1 1-1zm12 5c-2.738 0-5 2.262-5 5s2.262 5 5 5 5-2.262 5-5-2.262-5-5-5zm0 3c1.152 0 2 .848 2 2 0 1.152-.848 2-2 2-1.152 0-2-.848-2-2 0-1.152.848-2 2-2z"
    />
  </svg>
)

export default PlaceholderLeatherGoodsIcon
