import React from 'react'
import styled from '~/design/styled'
import { Title2 } from '@hermes/design-system'
import { Props } from 'io-ts'

export const Version: React.FC<Props> = () => {
  return (
    <VersionStyled>
      <Title2Styled>{process.env.REACT_APP_VERSION}</Title2Styled>
    </VersionStyled>
  )
}

const VersionStyled = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'right',
  marginBottom: theme.layout.l50,
}))

const Title2Styled = styled(Title2)({
  fontSize: '12px',
  color: 'gray',
})
