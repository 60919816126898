import React, { useMemo } from 'react'
import styled from '~/design/styled'
import { DayCell } from './DayCell'

const labeledDaysInAWeek: string[] = ['L', 'M', 'M', 'J', 'V']
const labeledDaysInAWeekCount = labeledDaysInAWeek.length

type DeadlineWaterfallStripProps = {
  numberOfFiveDayWeeks?: number
  disabledDaysCount?: number
  highlightedDaysCount?: number
  className?: string
}

export const DeadlineWaterfallStrip = ({
  numberOfFiveDayWeeks = 1,
  disabledDaysCount = 0,
  highlightedDaysCount = 0,
  className,
}: DeadlineWaterfallStripProps) => {
  const weekStripsArray = useMemo(
    () => Array.from(new Array(numberOfFiveDayWeeks)),
    [numberOfFiveDayWeeks],
  )

  return (
    <StyledDeadlineWaterfallStrip className={className}>
      {weekStripsArray.map((_, weekIndex) => (
        <WeekStripWrapper key={weekIndex}>
          {labeledDaysInAWeek.map((labeledDay, dayIndex) => {
            const currentDayInStrip =
              weekIndex * labeledDaysInAWeekCount + dayIndex
            const disabledStatus =
              currentDayInStrip < disabledDaysCount ? 'disabled' : undefined
            const highlightedStatus =
              currentDayInStrip < disabledDaysCount + highlightedDaysCount
                ? 'highlighted'
                : undefined

            const position =
              weekIndex === 0 && dayIndex === 0
                ? 'first'
                : weekIndex === numberOfFiveDayWeeks - 1 &&
                  dayIndex === labeledDaysInAWeekCount - 1
                ? 'last'
                : 'center'
            return (
              <DayCell
                key={`week-${weekIndex}-day-${dayIndex}`}
                label={labeledDay}
                status={disabledStatus ?? highlightedStatus}
                position={position}
              />
            )
          })}
        </WeekStripWrapper>
      ))}
    </StyledDeadlineWaterfallStrip>
  )
}

const StyledDeadlineWaterfallStrip = styled.div(({ theme }) => ({
  display: 'flex',
  flexFlow: 'row nowrap',

  '& > div': {
    flex: 1,
  },
  '& > div:not(:last-of-type)': {
    marginRight: theme.space.s20,
  },
}))

const WeekStripWrapper = styled.div({
  display: 'grid',
  gridTemplateRows: 'auto',
  gridTemplateColumns: `repeat(${labeledDaysInAWeek.length}, minmax(20px, 1fr))`,
})
