import * as t from 'io-ts'
import { DateFromISOString } from "io-ts-types/lib/DateFromISOString";

export const NullableLinkedId = t.strict({
  id: t.union([t.null, t.string]),
})
export type NullableLinkedId = t.TypeOf<typeof NullableLinkedId>

export const LinkedId = t.strict({
  id: t.string,
})
export type LinkedId = t.TypeOf<typeof LinkedId>

export const Worker = t.strict({
  id: t.string,
  name: t.string,
  associatedWorkshop: LinkedId
})
export type Worker = t.TypeOf<typeof Worker>

export const Design = t.strict({
  id: t.string,
  name: t.string,
})
export type Design = t.TypeOf<typeof Design>

export const APIConfiguration = t.strict({
  designs: t.array(Design),
  materials: t.array(LinkedId),
  workers: t.array(Worker),
  workshops: t.array(LinkedId),
})
export type APIConfiguration = t.TypeOf<typeof APIConfiguration>

export const AssemptyOrderItem = t.strict({
  model: LinkedId,
  material: LinkedId,
})
export type AssemptyOrderItem = t.TypeOf<typeof AssemptyOrderItem>

export const AssemblyOrder = t.strict({
  creationDate: DateFromISOString,
  id: t.string,
  cuttingOrder: NullableLinkedId,
  item: AssemptyOrderItem,
  replacement: t.union([t.null, AssemptyOrderItem]),
  workerReporter: LinkedId,
  workshopReporter: LinkedId,
  status: t.union([t.literal('PENDING'), t.literal('RETRIEVED'), t.literal('CANCELLED')])
})
export type AssemblyOrder = t.TypeOf<typeof AssemblyOrder>

export const CuttingOrder = t.strict({
  id: t.string,
  status: t.string,
  assemblyOrders: t.array(LinkedId),
  sawhorse: t.union([t.null, LinkedId]),
})
export type CuttingOrder = t.TypeOf<typeof CuttingOrder>

export const Sawhorse = t.strict({
  id: t.string,
  cuttingOrders: t.array(LinkedId),
})
export type Sawhorse = t.TypeOf<typeof Sawhorse>

export const APIOrderZone = t.strict({
  assemblyOrders: t.array(AssemblyOrder),
  cuttingOrders: t.array(CuttingOrder),
  sawhorses: t.array(Sawhorse),
})
export type APIOrderZone = t.TypeOf<typeof APIOrderZone>
