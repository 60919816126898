/** @jsx jsx */

import { InterpolationWithTheme, jsx } from '@emotion/core'
import {
  PrimaryMediumButton,
  SecondaryMediumButton,
  Text,
} from '@hermes/design-system'
import { FC } from 'react'

import { Modal, ModalProps } from '~/components/modal'
import { Theme } from '~/design/theme'
import styled from '~/design/styled'

type Props = Readonly<
  Pick<ModalProps, 'onClose'> & {
    onCancel: () => void
    onConfirm: () => void
    siteName: string | undefined
  }
>

export const SiteRemovalModal: FC<Props> = ({
  onCancel,
  onClose,
  onConfirm,
  siteName,
}) => {
  return (
    <Modal onClose={onClose} title="Suppression du site">
      <ModalText>
        Êtes-vous sûr de vouloir supprimer le site {siteName ?? ''} ?
      </ModalText>
      <ModalActions>
        <SecondaryMediumButton css={cssModalAction} onClick={onCancel}>
          Annuler
        </SecondaryMediumButton>
        <PrimaryMediumButton css={cssModalAction} onClick={onConfirm}>
          Supprimer
        </PrimaryMediumButton>
      </ModalActions>
    </Modal>
  )
}

const ModalText = styled(Text)(({ theme }) => ({
  color: theme.colors.text,
}))

const ModalActions = styled.div(({ theme }) => ({
  alignItems: 'center',
  alignSelf: 'center',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginBlockStart: theme.space.s80,
}))

const cssModalAction: InterpolationWithTheme<Theme> = theme => ({
  margin: `0px ${theme.layout.l10}`,
  width: '140px',
})
