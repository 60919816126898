import React, { useState } from 'react'
import styled from '~/design/styled'

import { Table, TableCell, TableRow } from '~/components/Table'
import { ParameterBlockTemplate } from '../ParameterBlockTemplate'
import { ClosedDatesType } from '~/shared/models/siteProperties'
import CalendarIcon from '~/design/icons/calendarIcon'
import UpdateClosedDatesModal from '~/components/parameters/closed-dates-block/UpdateClosedDatesModal'
import { UpdateSitePropertiesFn } from '~/shared/hooks/useSiteProperties'

type DeadlinesBlockProps = {
  closedDates: ClosedDatesType | null
  updateSiteProperties: UpdateSitePropertiesFn
}

export const ClosedDatesBlock = ({
  closedDates,
  updateSiteProperties,
}: DeadlinesBlockProps) => {
  const [isModalOpen, setModalState] = useState<boolean>(false)
  const closeModal = () => setModalState(false)

  const handleClosedDatesSubmit = (data: ClosedDatesType) =>
    updateSiteProperties({ closedDates: data })

  return (
    <>
      <ParameterBlockTemplate
        title="Dates de fermeture du site"
        icon={<CalendarIcon />}
        onButtonClick={() => setModalState(true)}
        buttonLabelType={closedDates === null ? 'fill' : 'modify'}
      >
        <Table layout={[{ flex: 1 }, { marginLeft: 'auto' }]}>
          <TableRow>
            <TableCell>
              <RowLabel>Dates de fermeture du site</RowLabel>
            </TableCell>
            <TableCell>{getClosedDatesLabel(closedDates)}</TableCell>
          </TableRow>
        </Table>
      </ParameterBlockTemplate>

      {isModalOpen && (
        <UpdateClosedDatesModal
          closedDates={closedDates}
          onSubmit={handleClosedDatesSubmit}
          onClose={closeModal}
        />
      )}
    </>
  )
}

const RowLabel = styled.div(({ theme }) => ({
  fontWeight: theme.fontWeights.default.medium,
  color: theme.colors.text,
}))

function getClosedDatesLabel(value: ClosedDatesType | null): string {
  const datesCount = value?.length
  if (datesCount && datesCount > 1) {
    return `${datesCount} dates renseignées`
  } else if (datesCount && datesCount === 1) {
    return `${datesCount} date renseignée`
  } else {
    return '-'
  }
}
