import { PrimaryMediumButton, Select } from '@hermes/design-system'
import React, { FC, useCallback, useMemo } from 'react'
import { Controller, DeepPartial, useForm } from 'react-hook-form'
import { array, number, object } from 'yup'

import { Label } from '~/components/inputs'
import { Modal, ModalProps } from '~/components/modal'
import styled from '~/design/styled'
import { DeptsArrayType, sortDepartments } from '~/shared/models/models'
import { MaximumQuantityArray } from '~/shared/models/siteProperties'

type Props = Readonly<
  Pick<ModalProps, 'onClose'> & {
    departments: DeptsArrayType
    maximumQuantityPerOF: MaximumQuantityArray | null
    onSubmit: (data: MaximumQuantityArray) => Promise<void>
  }
>

type FormData = Readonly<{
  maximumQuantityPerOF: ReadonlyArray<number | undefined>
}>

const maxQuantityOptions = [
  { label: '-', value: undefined },
  { label: '6', value: 6 },
  { label: '12', value: 12 },
  { label: '24', value: 24 },
]

export const UpdateMaximumQuantityPerOfModal: FC<Props> = ({
  departments,
  maximumQuantityPerOF,
  onClose,
  onSubmit: propsOnSubmit,
}) => {
  const { control, formState, handleSubmit } = useForm<FormData>({
    defaultValues: getDefaultValues(departments, maximumQuantityPerOF),
    mode: 'onChange',
  })
  const onSubmit = useCallback(
    (data: FormData) => propsOnSubmit(transformFormData(departments, data)),
    [departments, propsOnSubmit],
  )
  const sortedDepartments = useMemo(
    () => sortDepartments(departments.departments),
    [departments],
  )
  return (
    <Modal
      title={`${
        maximumQuantityPerOF === null ? 'Configurer' : 'Modifier'
      } la quantité maximale par OF`}
      onClose={formState.isSubmitting ? undefined : onClose}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        {sortedDepartments.map((dept, index) => (
          <Field key={dept.id}>
            <Label>{`Département ${dept.name.toLocaleLowerCase()} (en nombre)`}</Label>
            <Controller
              as={
                <Select<number>
                  id={`max-quantity-${dept.id}`}
                  isClearable={false}
                  isDisabled={formState.isSubmitting}
                  options={maxQuantityOptions}
                  placeholder="Sélectionnez"
                />
              }
              control={control}
              name={`maximumQuantityPerOF[${index}]`}
            />
          </Field>
        ))}
        <SubmitButton
          disabled={
            !formState.dirty || !formState.isValid || formState.isSubmitting
          }
        >
          Valider
        </SubmitButton>
      </Form>
    </Modal>
  )
}

function getDefaultValues(
  departments: DeptsArrayType,
  maximumQuantityPerOF: MaximumQuantityArray | null,
): DeepPartial<FormData> {
  return {
    maximumQuantityPerOF: departments.departments.map(
      dept =>
        maximumQuantityPerOF?.find(q => q.department.id === dept.id)?.value,
    ),
  }
}

function transformFormData(
  departments: DeptsArrayType,
  data: FormData,
): MaximumQuantityArray {
  return departments.departments
    .map((dept, index) => ({
      department: { id: dept.id },
      value: data.maximumQuantityPerOF[index]!,
    }))
    .filter(quantity => quantity.value != null)
}

const Form = styled.form({
  display: 'flex',
  flexDirection: 'column',
})

const Field = styled.div(({ theme }) => ({
  '&:not(:last-of-type)': {
    marginBlockEnd: theme.layout.l30,
  },
  '& > :first-of-type': {
    marginBlockEnd: theme.space.s30,
  },
}))

const SubmitButton = styled(PrimaryMediumButton)(({ theme }) => ({
  alignSelf: 'center',
  marginBlockStart: theme.layout.l55,
  width: '220px',
}))
