import React, { FC } from 'react'

import { Table, TableCell, TableRow } from '~/components/Table'

export const CheckingError: FC = () => {
  return (
    <Table layout={[
      { flex: 3 },
      { flex: 3 },
      { flex: 3 },
      { flex: 1, justifyContent: 'flex-end' },
    ]}>
        <TableRow>
          <TableCell>
            Validation du site (recherche d'erreurs dans la configuration)…
          </TableCell>
        </TableRow>
    </Table>
  )
}
