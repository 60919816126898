/** @jsx jsx */

import { InterpolationWithTheme, jsx } from '@emotion/core'
import { FC } from 'react'

type Props = Readonly<{
  className?: string
  css?: InterpolationWithTheme<any>
  fill?: string
}>

export const WorkerIcon: FC<Props> = ({ className, css, fill = 'white' }) => (
  <svg
    className={className}
    css={css}
    height="68"
    viewBox="0 0 56 68"
    width="56"
  >
    <path
      fill={fill}
      fillRule="evenodd"
      d="M26.31 40C10.572 40 0 50.215 0 65.419 0 66.845 1.025 68 2.291 68s2.217-1.155 2.217-2.581c0-14.95 11.778-20.748 21.801-20.748h3.38c10.024 0 21.864 5.798 21.864 20.748 0 1.426.89 2.581 2.156 2.581S56 66.845 56 65.419C56 50.214 45.426 40 29.689 40h-3.38zM40 16.91C40 25.23 34.842 32 28.5 32S17 25.23 17 16.91C17 9.187 21.622 4 28.5 4S40 9.188 40 16.91zm5 .512C45 7.165 38.216 0 28.5 0 18.784 0 12 7.165 12 17.422 12 28.217 19.402 37 28.5 37 37.598 37 45 28.217 45 17.422z"
    />
  </svg>
)
