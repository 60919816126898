import {
  CheckboxWithLabel,
  InputText,
  PrimaryMediumButton,
  Select,
  Toggler,
  UIText,
} from '@hermes/design-system'
import React, { FC, useCallback, useMemo } from 'react'
import { Controller, DeepPartial, useForm } from 'react-hook-form'
import { array, boolean, object, string } from 'yup'

import {
  CreateModelType,
  DeptsArrayType,
  MaterialsArrayType,
  ModelQuantities,
  ModelType,
  sortDepartments,
} from '~/shared/models/models'
import { ImageResizer } from '~/components/ImageResizer'
import { ColorDot } from '~/components/colorDot'
import { Label } from '~/components/inputs'
import { Modal, ModalProps } from '~/components/modal'
import styled from '~/design/styled'

import { ModelPlaceholder } from '~/components/models/ModelPlaceholder'

type Props = Readonly<
  Pick<ModalProps, 'onClose'> & {
    defaultDeptId?: string
    departments: DeptsArrayType
    materials: MaterialsArrayType
    model?: ModelType
    onSubmit: (data: CreateModelType) => Promise<void>
    submitButtonText: string
    title: string
  }
>

type FormData = Readonly<{
  associatedMaterials: ReadonlyArray<boolean>
  department: Readonly<{ id: string }>
  isDisplayedOnSite: boolean
  name: string
  pictureId: string | undefined
  quantities: ReadonlyArray<boolean>
}>

const validationSchema = object<FormData>({
  associatedMaterials: array(boolean()).test(
    'at-least-one-material',
    'Vous devez sélectionner au moins une matière',
    (value: FormData['associatedMaterials']) => value.includes(true),
  ),
  department: object({
    id: string().required('Vous devez sélectionner un département'),
  }),
  isDisplayedOnSite: boolean(),
  name: string().required('Vous devez renseigner le nom du modèle'),
  pictureId: string(),
  quantities: array(boolean()).test(
    'at-least-one-quantity',
    'Vous devez sélectionner au moins une quantité',
    (value: FormData['quantities']) => value.includes(true),
  ),
})

export const ModelCreationModal: FC<Props> = ({
  defaultDeptId,
  departments,
  materials,
  model,
  onClose,
  onSubmit: propsOnSubmit,
  submitButtonText,
  title,
}) => {
  const deptOptions = useMemo(
    () =>
      sortDepartments(departments.departments).map(dept => ({
        label: dept.name,
        value: dept.id,
      })),
    [departments],
  )

  const { control, formState, handleSubmit, setValue, watch } = useForm<
    FormData
  >({
    defaultValues: getDefaultValues(materials, defaultDeptId)(model),
    mode: 'onChange',
    validationSchema,
  })
  const handlePictureIdReset = useCallback(() => {
    setValue('pictureId', undefined, true)
  }, [setValue])
  const onSubmit = useCallback(
    (data: FormData) => {
      return propsOnSubmit(transformFormData(materials)(data))
    },
    [materials, propsOnSubmit],
  )
  return (
    <Modal onClose={formState.isSubmitting ? undefined : onClose} title={title}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <RowSpaceBetween>
          <Col50>
            <StyledInputLabel htmlFor="dept">Département</StyledInputLabel>
            <Controller
              as={
                <StyledSelect
                  id="dept"
                  isDisabled={formState.isSubmitting}
                  options={deptOptions}
                  placeholder="Sélectionner un département"
                />
              }
              control={control}
              name="department.id"
            />
            <StyledInputLabel htmlFor="name">Nom du modèle</StyledInputLabel>
            <Controller
              as={
                <InputText
                  disabled={formState.isSubmitting}
                  id="name"
                  placeholder="Nom du modèle"
                />
              }
              control={control}
              name="name"
            />
          </Col50>
          <Controller
            as={
              <ImageResizer
                disabled={formState.isSubmitting}
                hoverMessage="Déposer une photo"
                message="Glisser-déposer la photo produit"
                modalSize="small"
                modalTitle="Redimensionner la photo produit"
                onReset={handlePictureIdReset}
                placeholder={
                  <ModelPlaceholder deptId={watch('department.id') as string} />
                }
                title="Photo produit"
              />
            }
            control={control}
            name="pictureId"
            valueName="source"
          />
        </RowSpaceBetween>
        <BorderRow>
          <StyledInputLabel>Quantités</StyledInputLabel>
          <RowSpaceBetween>
            {ModelQuantities.map((quantity, index) => (
              <Controller
                as={
                  <CheckboxWithLabel id={`quantity-${quantity}`}>
                    <UIText>{quantity}</UIText>
                  </CheckboxWithLabel>
                }
                control={control}
                key={quantity}
                name={`quantities[${index}]`}
                valueName="checked"
              />
            ))}
          </RowSpaceBetween>
        </BorderRow>
        <BorderRow>
          <StyledInputLabel>Matières</StyledInputLabel>
          <RowSpaceBetween>
            {materials.materials.map((material, index) => (
              <Controller
                as={
                  <CheckboxWithLabel id={`material-${material.id}`}>
                    <RowItemCenter>
                      <DotWrapper>
                        <ColorDot color={material.color} />
                      </DotWrapper>
                      <UIText>
                        {material.name.replace('Cuir précieux -', '')}
                      </UIText>
                    </RowItemCenter>
                  </CheckboxWithLabel>
                }
                control={control}
                key={material.id}
                name={`associatedMaterials[${index}]`}
                valueName="checked"
              />
            ))}
          </RowSpaceBetween>
        </BorderRow>
        <BorderRow>
          <StyledInputLabel htmlFor="isDisplayedOnSite">
            Actif sur le site
          </StyledInputLabel>
          <RowSpaceBetween>
            <UIText>Afficher le modèle</UIText>
            <Controller
              as={
                <Toggler
                  disabled={formState.isSubmitting}
                  id="isDisplayedOnSite"
                />
              }
              control={control}
              name="isDisplayedOnSite"
              valueName="toggled"
            />
          </RowSpaceBetween>
        </BorderRow>
        <RowCenter>
          <PrimaryMediumButton
            disabled={
              !formState.dirty || !formState.isValid || formState.isSubmitting
            }
          >
            {submitButtonText}
          </PrimaryMediumButton>
        </RowCenter>
      </Form>
    </Modal>
  )
}

const Form = styled.form({
  display: 'flex',
  flexDirection: 'column',
})

const Row = styled.div({
  display: 'flex',
})

const RowItemCenter = styled(Row)({
  alignItems: 'center',
})

const Col50 = styled.div({
  width: '50%',
})

const BorderRow = styled.div(({ theme }) => ({
  borderBottom: `1px solid ${theme.colors.greys.light20}`,
  padding: `${theme.space.s30} 0`,
  marginTop: theme.space.s30,
}))

const DotWrapper = styled.div(({ theme }) => ({
  marginRight: theme.space.s30,
}))

const StyledSelect = styled(Select)(({ theme }) => ({
  marginBottom: theme.space.s80,
}))

const StyledInputLabel = styled(Label)(({ theme }) => ({
  marginBottom: theme.space.s30,
}))

const RowSpaceBetween = styled(Row)({
  justifyContent: 'space-between',
})

const RowCenter = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: theme.space.s80,
}))

function getDefaultValues(
  materials: MaterialsArrayType,
  defaultDeptId?: string,
): (model?: ModelType) => DeepPartial<FormData> {
  return model => ({
    associatedMaterials: materials.materials.map(
      m1 => model?.associatedMaterials.some(m2 => m2.id === m1.id) ?? false,
    ),
    department: { id: model?.department.id ?? defaultDeptId },
    isDisplayedOnSite: model?.isDisplayedOnSite ?? true,
    name: model?.name ?? '',
    pictureId: model?.pictureUrl ?? undefined,
    quantities: ModelQuantities.map(
      q => model?.quantities.includes(q) ?? false,
    ),
  })
}

function transformFormData(
  materials: MaterialsArrayType,
): (data: FormData) => CreateModelType {
  return data => ({
    associatedMaterials: materials.materials
      .filter((_m, index) => data.associatedMaterials[index])
      .map(m => ({ id: m.id })),
    department: data.department,
    isDisplayedOnSite: data.isDisplayedOnSite,
    name: data.name,
    pictureId: data.pictureId ?? null,
    quantities: ModelQuantities.filter(
      (_q, index) => data.quantities[index],
    ).map(q => q.toLocaleString()),
  })
}
