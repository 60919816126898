import React from 'react'

type Props = {
  fill?: string
  className?: string
}

const AddIcon: React.FC<Props> = ({ className, fill = '#ffffff' }) => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill={fill}
    className={className}
  >
    <defs>
      <path
        id="addIcon"
        d="M38.383 21.383v5.734a.883.883 0 0 1-1.766 0v-5.734h-5.734a.883.883 0 0 1 0-1.766h5.734v-5.734a.883.883 0 0 1 1.766 0v5.734h5.734a.883.883 0 0 1 0 1.766h-5.734z"
      />
    </defs>
    <use
      fillRule="nonzero"
      transform="translate(-30 -13)"
      xlinkHref="#addIcon"
    />
  </svg>
)

export default AddIcon
