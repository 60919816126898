import React, { FC } from 'react'

type Props = Readonly<{
  className?: string
}>

export const BinIcon: FC<Props> = ({ className }) => (
  <svg className={className} height="26" viewBox="0 0 26 26" width="26">
    <defs>
      <path
        d="M2.2 6.245c.48 0 .517.443.52.51v.01L3.24 23.4c0 .485.453.97.936 1.033l.104.006h11.44c.485 0 .97-.453 1.033-.936l.007-.103.52-16.637s.007-.46.41-.515l.11-.004h.52c.48 0 .517.443.52.51v.01l-.663 16.855c-.05 1.257-1.043 2.29-2.246 2.374l-.158.006H4.227c-1.22 0-2.261-.981-2.393-2.218l-.011-.163L1.16 6.764s.022-.498.52-.52c.217-.01 0 0 .52 0zM10 8.32c.365 0 .498.09.517.423l.003.097v12.48c0 .412-.122.52-.52.52-.365 0-.498-.091-.517-.423l-.003-.097V8.84c0-.411.122-.52.52-.52zm4.16-.52c.364.01.507.093.52.423v.097l-.52 12.999c-.012.404-.132.52-.52.52-.366-.01-.508-.093-.52-.424v-.096l.52-13c.011-.411.128-.524.52-.52zm-8.32 0c.371-.01.49.09.516.423l.005.097.52 12.999c.011.412-.122.508-.52.52-.354 0-.485-.096-.514-.417l-.006-.103-.52-13c-.012-.411.121-.507.52-.52zM12.6 0c1.1 0 1.56 1.04 1.56 1.56v2.08h5.209c.068.003.511.04.511.52v.52c0 .52-.52.52-.52.52H.64s-.52 0-.52-.52v-.52c0-.532.52-.52.52-.52h5.2V1.56C5.84.52 6.298 0 7.4 0zm-.454 1.56H7.854c-.26.003-.454.04-.454.52v1.56h5.2V2.08c0-.52-.229-.52-.52-.52z"
        id="bin-icon"
      />
    </defs>
    <use
      fill="#FC4D21"
      fillRule="evenodd"
      transform="translate(3)"
      xlinkHref="#bin-icon"
    />
  </svg>
)
