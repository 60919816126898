/** @jsx jsx */

import { InterpolationWithTheme, jsx } from '@emotion/core'
import { FC } from 'react'

type Props = Readonly<{
  className?: string
  css?: InterpolationWithTheme<any>
}>

const PlaceholderHorseIcon: FC<Props> = ({ className, css }) => (
  <svg
    width="87"
    height="88"
    viewBox="0 0 87 88"
    className={className}
    css={css}
  >
    <path
      fill="#FFF"
      fillRule="evenodd"
      d="M24.74.387c13.242-1.646 25.377 1.962 36.066 10.722 3.282 2.69 6.4 6.524 9.78 10.73C83.476 37.89 87 60.716 87 68.063c0 1.74-5.197 1.169-5.256.054C80.53 45.627 68.368 24.06 57.54 15.183c-7.877-6.454-16.616-9.78-26.05-9.927 3.695 3.825 8.258 9.858 9.121 16.98.174 1.438-.832 2.744-2.258 2.923-1.423.176-2.716-.847-2.89-2.284-.142-1.168-.425-2.308-.802-3.41-10.28 9.972-18.894 22.746-25.63 38.034-5.233 11.877-5.192 16.71.194 22.49.87.933 3.986 3.237 7.893 2.677 4.22-.598 8.255-4.482 11.682-11.215 2.033-3.994 6.433-5.538 11.089-7.157 1.237-.433 2.482-.87 3.703-1.352l.01-.005.026-.01c.004 0 .008 0 .013-.002 5.658-2.237 10.825-5.494 12.546-14.212.282-1.418 1.648-2.34 3.051-2.058 1.406.284 2.316 1.665 2.036 3.086-1.968 9.96-7.784 14.447-13.577 17.127.962 3.91 2.005 11.802-2.749 18.572-.31.441-.728.756-1.191.933-.78.301-1.692.212-2.426-.312-1.166-.84-1.44-2.476-.612-3.657 3.284-4.678 2.77-10.386 2.095-13.585-.417.147-.831.291-1.23.433-3.807 1.328-7.09 2.474-8.17 4.598-3.539 6.953-7.718 11.377-12.462 13.2-1.01.389-2.046.659-3.108.811-5.857.837-10.545-2.295-12.39-4.276-7.76-8.321-6.395-16.34-1.16-28.22 7.267-16.494 16.67-30.208 27.963-40.816-3.537-5.573-8.647-9.378-8.72-9.432-.866-.631-1.259-1.734-.993-2.78.266-1.044 1.14-1.816 2.195-1.95zM8.567 66.977c.943-1.078 2.73-1.298 3.989-.49 3.875 2.486 3.46 6.848 3.402 7.34-.117.942-.846 1.699-1.813 2.014-.4.13-.84.185-1.293.147-1.555-.127-2.7-1.318-2.565-2.653.002-.02.173-2.093-1.146-2.94-1.26-.808-1.516-2.338-.574-3.418zm23.518-33.976c1.656.048 2.96 1.43 2.913 3.089-.047 1.657-1.43 2.958-3.087 2.909-1.656-.05-2.959-1.43-2.91-3.087.047-1.658 1.428-2.959 3.084-2.91z"
    />
  </svg>
)

export default PlaceholderHorseIcon
