import * as t from 'io-ts'

export const SiteType = t.type({
  id: t.string,
  name: t.string,
  code: t.string,
})
export type SiteType = t.TypeOf<typeof SiteType>

export const SitesArrayType = t.readonlyArray(SiteType, 'Sites')
export type SitesArrayType = t.TypeOf<typeof SitesArrayType>

export function addSite(array: SitesArrayType, site: SiteType): SitesArrayType {
  return array.concat(site)
}

export function getSiteName(
  sites: SitesArrayType,
  siteId: string,
): string | undefined {
  return sites.find(s => s.id === siteId)?.name
}

export function removeSite(
  array: SitesArrayType,
  site: SiteType,
): SitesArrayType {
  return array.filter(s => s.id !== site.id)
}

export function sortSites(sites: SitesArrayType): SitesArrayType {
  return sites.slice().sort((a, b) => a.name.localeCompare(b.name))
}
