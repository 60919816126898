/** @jsx jsx */

import { InterpolationWithTheme, jsx } from '@emotion/core'
import { FC } from 'react'

type Props = Readonly<{
  className?: string
  css?: InterpolationWithTheme<any>
  fill?: string
}>

const PlaceholderModelIcon: FC<Props> = ({
  className,
  css,
  fill = 'white',
}) => (
  <svg
    width="70"
    height="75"
    viewBox="0 0 70 75"
    className={className}
    css={css}
  >
    <path
      fill={fill}
      fillRule="evenodd"
      d="M65.849 33.223l-3.266 32.08c-.203 1.778-1.683 5.136-3.517 5.135H11.15c-1.832.001-3.496-3.357-3.7-5.136L4.035 31.174c-.221-1.93.543-3.455 2.516-3.672.09-.01 19.089.145 56.998.464 2.635 0 2.63 2.149 2.3 5.257zM46.43 24.502H24.533v-3.39c-.003-6.674 5.158-10.997 10.967-10.997s10.93 4.323 10.93 10.998v3.389zM18 21.422c0-10.028 6.65-17.92 17.411-17.92C46.172 3.502 53 11.092 53 21.12v3.382h-3.236V21.12C49.76 12.778 43.966 6.78 35.5 6.783c-8.462.003-14.46 6-14.463 14.337v3.382H18v-3.08zM35.5 0c14.308 0 20.738 9.267 20.749 20.762v3.74h6.922c.247 0 .769-.027 1.015 0 4.666.496 6.221 2.465 5.73 8.109l-4.058 36.133c-.371 3.273-3.712 5.752-7.105 5.758H11.15c-3.4.003-6.742-2.477-7.114-5.758L.04 32.29a6.304 6.304 0 01-.04-.718c0-3.576.555-7.07 6.55-7.07h8.134v-3.74C14.696 9.267 21.192 0 35.5 0zm-3.717 37.502H22v3.232h3.307v19.55H22v3.218h9.783v-3.218h-3.259v-9.782h13.06v9.782H38.25v3.218H48v-3.218h-3.178v-19.55H48v-3.232h-9.75v3.232h3.334v6.5h-13.06v-6.5h3.259v-3.232z"
    />
  </svg>
)

export default PlaceholderModelIcon
