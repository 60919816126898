import React, { FC } from 'react'

import styled from '~/design/styled'

import { TableLayout, TableLayoutProvider } from './TableLayoutContext'

type Props = Readonly<{
  className?: string
  layout: TableLayout
}>

export const Table: FC<Props> = ({ children, className, layout }) => (
  <TableLayoutProvider layout={layout}>
    <View className={className}>{children}</View>
  </TableLayoutProvider>
)

const View = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.white,
  borderRadius: theme.borderRadius.small,
  boxShadow: theme.boxShadows.table,
  display: 'flex',
  flexDirection: 'column',
}))
