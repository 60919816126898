import React from 'react'

type Props = {
  className?: string
}
const DeadlineIcon: React.FC<Props> = ({ className }) => (
  <svg width="26" height="26" viewBox="0 0 26 26" className={className}>
    <defs>
      <path
        id="calendarIcon"
        d="M19.768 5.98c0 .44-.34.78-.78.78s-.78-.34-.78-.78.34-.78.78-.78.78.34.78.78zM4.42 2.08c.412-.006.788.368.788.78 0 .412-.376.786-.788.78H2.868A1.29 1.29 0 0 0 1.56 4.948V9.36h22.88V4.948a1.29 1.29 0 0 0-1.308-1.308H21.58c-.412.006-.788-.368-.788-.78 0-.412.376-.786.788-.78h1.552A2.88 2.88 0 0 1 26 4.948v17.664a2.88 2.88 0 0 1-2.868 2.868H2.868A2.88 2.88 0 0 1 0 22.612V4.948A2.88 2.88 0 0 1 2.868 2.08zm20.02 8.84H1.56v11.692a1.29 1.29 0 0 0 1.308 1.308h20.264a1.29 1.29 0 0 0 1.308-1.308V10.92zM18.988.52c.431 0 .78.35.78.78v2.478a2.353 2.353 0 0 1 1.56 2.202 2.352 2.352 0 0 1-2.34 2.34 2.352 2.352 0 0 1-2.34-2.34c0-1.01.656-1.877 1.56-2.202V1.3c0-.43.35-.78.78-.78zm-11.96 0c.431 0 .78.35.78.78v2.478a2.353 2.353 0 0 1 1.56 2.202 2.352 2.352 0 0 1-2.34 2.34 2.352 2.352 0 0 1-2.34-2.34c0-1.01.656-1.877 1.56-2.202V1.3c0-.43.35-.78.78-.78zm0 4.68c-.44 0-.78.34-.78.78s.34.78.78.78.78-.34.78-.78-.34-.78-.78-.78zm9.352-3.12a.78.78 0 1 1 0 1.56H9.62a.78.78 0 1 1 0-1.56z"
      />
    </defs>
    <use fill="#FC4D21" fillRule="evenodd" xlinkHref="#calendarIcon" />
  </svg>
)

export default DeadlineIcon
