import React from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { ClosedDatesBlock } from '~/components/parameters/closed-dates-block'
import { CuttingZonesBlock } from '~/components/parameters/cutting-zones-block'
import { DeadlinesBlock } from '~/components/parameters/deadlines-block'
import { LeatherFlowBlock } from '~/components/parameters/leather-flow-block'
import { MaterialCallStepBlock } from '~/components/parameters/material-call-step-block'
import { MaximumQuantityPerOfBlock } from '~/components/parameters/maximum-quantity-per-of-block'
import { PasswordBlock } from '~/components/parameters/password-block'
import { SiteRemovalBlock } from '~/components/parameters/site-removal-block'
import { useToaster } from '~/components/Toaster'
import { ScreenLoader } from '~/components/screenLoader'
import { useSiteConfiguration } from '~/shared/contexts/SiteConfigurationContext'
import { useAdministrator } from '~/shared/contexts/AdministratorContext'
import {
  SiteProperties,
  UpdateSitePropertiesFn,
} from '~/shared/hooks/useSiteProperties'
import { getSiteName } from '~/shared/models/sites'

type Params = Readonly<{
  id: string
}>

const ParametersScreen = (props: SiteProperties) => {
  const history = useHistory()
  const params = useParams<Params>()

  const {
    data: { currentUser },
    mutators,
  } = useAdministrator()
  const {
    data: { departments, workshops, materials },
  } = useSiteConfiguration()

  const { showToast } = useToaster()

  const siteName = getSiteName(currentUser.configurableSites, params.id)

  const updateSiteProperties: UpdateSitePropertiesFn = async properties => {
    try {
      await props.updateSiteProperties(properties)
      showToast({
        intent: 'success',
        message: 'La configuration du site a bien été modifiée',
      })
    } catch (error) {
      showToast({
        intent: 'failure',
        message: "La configuration du site n'a pu être modifiée",
      })
    }
  }

  const removeSite = async () => {
    try {
      await mutators.removeSite(params.id)
      showToast({
        intent: 'success',
        message: `Le site ${siteName ?? ''} a bien été supprimé`,
      })
      history.push('/sites')
    } catch (error) {
      showToast({
        intent: 'failure',
        message: `Le site ${siteName ?? ''} n'a pu être supprimé`,
      })
    }
  }

  if (props.parameters) {
    return (
      <>
        <CuttingZonesBlock
          workshops={workshops}
          cuttingZones={props.parameters.cuttingZones}
          updateSiteProperties={updateSiteProperties}
        />
        <DeadlinesBlock
          deadlines={props.parameters.deadlines}
          updateSiteProperties={updateSiteProperties}
        />
        <MaximumQuantityPerOfBlock
          departments={departments}
          maximumQuantityPerOF={props.parameters.maximumQuantityPerOF}
          updateSiteProperties={updateSiteProperties}
        />
        <MaterialCallStepBlock
          departments={departments}
          materialCallStep={props.parameters.materialCallStep}
          updateSiteProperties={updateSiteProperties}
        />
        <LeatherFlowBlock
          materials={materials}
          leatherFlow={props.parameters.flowTypes}
          updateSiteProperties={updateSiteProperties}
        />
        <ClosedDatesBlock
          closedDates={props.parameters.closedDates}
          updateSiteProperties={updateSiteProperties}
        />
        <PasswordBlock
          workshopManagerCredentials={
            props.parameters.workshopManagerCredentials
          }
          updateSiteProperties={updateSiteProperties}
        />
        {currentUser.status === 'superadmin' && (
          <SiteRemovalBlock removeSite={removeSite} siteName={siteName} />
        )}
      </>
    )
  } else if (props.parametersError) {
    return (
      <>
        Une erreur s'est produite lors de la récupération de la configuration du
        site
      </>
    ) // TODO: improve error handling
  } else {
    return <ScreenLoader />
  }
}
export default ParametersScreen
