import React, { FC, useCallback, useMemo } from 'react'

import { Table, TableCell, TableHeader, TableRow } from '~/components/Table'
import { Clickable } from '~/components/clickable'
import { ColorDot } from '~/components/colorDot'
import { Emblem } from '~/components/Emblem'
import DeleteIcon from '~/design/icons/deleteIcon'
import EditIcon from '~/design/icons/editIcon'
import { ModelPlaceholder } from '~/components/models/ModelPlaceholder'

import styled from '~/design/styled'
import {
  MaterialsArrayType,
  ModelType,
  ModelsArrayType,
  sortModels,
} from '~/shared/models/models'

type ModelsListProps = Readonly<{
  materials: MaterialsArrayType
  models: ModelsArrayType
  deptId: string
  removeModel: (data: { id: string; name: string }) => void
  updateModel: (model: ModelType) => void
}>

export const ModelsList: FC<ModelsListProps> = ({
  materials,
  models,
  deptId,
  removeModel,
  updateModel,
}) => {
  const layout = useMemo(
    () => [
      { flex: 3 },
      { flex: 3 },
      { flex: 3 },
      { flex: 1.5, justifyContent: 'flex-end' },
    ],
    [],
  )
  const sortedDesigns = useMemo(() => sortModels(models.designs), [models])
  return (
    <Table layout={layout}>
      <TableHeader>
        <TableCell>Modèle</TableCell>
        <TableCell>Matières</TableCell>
        <TableCell>Quantités</TableCell>
        <TableCell>Actions</TableCell>
      </TableHeader>
      {sortedDesigns.map(design => (
        <ModelsListItem
          key={design.id}
          design={design}
          materials={materials}
          deptId={deptId}
          removeModel={removeModel}
          updateModel={updateModel}
        />
      ))}
    </Table>
  )
}

type ModelsListItemProps = Readonly<{
  design: ModelType
  materials: MaterialsArrayType
  deptId: string
  removeModel: (data: { id: string; name: string }) => void
  updateModel: (model: ModelType) => void
}>

const ModelsListItem: FC<ModelsListItemProps> = ({
  design,
  materials,
  deptId,
  removeModel,
  updateModel,
}) => {
  const hidden = !design.isDisplayedOnSite
  const getMaterialColor = useCallback(
    (id: string) => materials.materials.find(m => m.id === id)?.color,
    [materials],
  )
  const handleDelete = useCallback(() => {
    removeModel({ id: design.id, name: design.name })
  }, [design, removeModel])

  const handleUpdate = useCallback(() => {
    updateModel(design)
  }, [design, updateModel])
  return (
    <TableRow>
      <TableCellHideable hidden={hidden}>
        <EmblemStyled
          placeholder={<ModelPlaceholder deptId={deptId} />}
          source={design.pictureUrl ?? undefined}
        />
        <DesignName>{design.name}</DesignName>
      </TableCellHideable>
      <TableCellHideable hidden={hidden}>
        {design.associatedMaterials.map(m => (
          <Material color={getMaterialColor(m.id)} key={m.id} />
        ))}
      </TableCellHideable>
      <TableCellHideable hidden={hidden}>
        {design.quantities.join(' - ')}
      </TableCellHideable>
      <TableCellActions>
        <Clickable onClick={handleUpdate}>
          <EditIconStyled />
        </Clickable>
        <Clickable onClick={handleDelete}>
          <DeleteIconStyled />
        </Clickable>
      </TableCellActions>
    </TableRow>
  )
}

const TableCellHideable = styled(TableCell)<Readonly<{ hidden: boolean }>>(
  ({ hidden }) => ({
    opacity: hidden ? 0.3 : 1,
  }),
)

const EmblemStyled = styled(Emblem)(({ theme }) => ({
  flexShrink: 0,
  marginInlineEnd: theme.space.s40,
}))

const DesignName = styled.span(({ theme }) => ({
  color: theme.colors.text,
  fontWeight: theme.fontWeights.default.medium,
  textTransform: 'uppercase',
}))

const Material = styled(ColorDot)(({ theme }) => ({
  flexShrink: 0,
  ':not(:last-child)': {
    marginInlineEnd: theme.space.s35,
  },
}))

const TableCellActions = styled(TableCell)(({ theme }) => ({
  '> *': {
    flexShrink: 0,
  },
  '> :not(:last-child)': {
    marginInlineEnd: theme.space.s60,
  },
}))

const EditIconStyled = styled(EditIcon)(({ theme }) => ({
  fill: theme.colors.black,
}))

const DeleteIconStyled = styled(DeleteIcon)(({ theme }) => ({
  fill: theme.colors.black,
}))
