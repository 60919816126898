/** @jsx jsx */

import { InterpolationWithTheme, jsx } from '@emotion/core'
import { FC } from 'react'

type Props = Readonly<{
  className?: string
  css?: InterpolationWithTheme<any>
}>

const PlaceholderFashionIcon: FC<Props> = ({ className, css }) => (
  <svg
    width="96"
    height="60"
    viewBox="0 0 96 60"
    className={className}
    css={css}
  >
    <path
      fill="#FFF"
      d="M48 0c12.351 0 23.362 1.743 32.152 4.887C89.548 8.26 96 12.772 96 18v24c0 5.21-5.812 9.103-15.208 12.474C72.002 57.62 60.352 60 48 60c-12.351 0-24.002-2.381-32.792-5.526C5.812 51.103 0 46.21 0 41V19C0 13.772 6.452 8.26 15.848 4.887 24.638 1.743 35.648 0 48 0zm44 26c-2.463 2.235-6.529 4.493-11.189 6.16C72.021 35.304 60.371 37 48 37c-12.37 0-23.983-1.696-32.773-4.84C10.567 30.493 6.463 28.235 4 26v14c0 6.8 22 16 44 16s44-9 44-15zM48 40c3.314 0 6 2.703 6 6 0 3.314-2.686 6-6 6-3.297 0-6-2.686-6-6s2.686-6 6-6zm0 3c-1.658 0-3 1.342-3 3s1.342 3 3 3 3-1.342 3-3-1.342-3-3-3zm22-3c2.202 0 4 1.79 4 4s-1.798 4-4 4-4-1.79-4-4 1.798-4 4-4zm-44 0c2.202 0 4 1.79 4 4s-1.798 4-4 4-4-1.79-4-4 1.798-4 4-4zm44 3c-.464 0-1 .412-1 1 0 .588.536 1 1 1 .464 0 1-.412 1-1 0-.588-.536-1-1-1zm-44 0c-.464 0-1 .412-1 1 0 .588.536 1 1 1 .482 0 1-.412 1-1 0-.588-.536-1-1-1zm22-17c-9.756 0-19.328 1.935-27 4 7.199 1.97 16.505 3 27 3 10.495 0 19.801-1.03 27-3-7.672-2.065-17.263-4-27-4zm0-22C23 4 4 13 4 19c0 3.145 4.35 6.348 11 9 8.809-3.22 20.44-6 33-6 12.56 0 24.172 2.78 33 6 6.63-2.652 11-5.855 11-9 0-6-19-15-44-15z"
    />
  </svg>
)

export default PlaceholderFashionIcon
