import { InterpolationWithTheme } from '@emotion/core'
import React, { CSSProperties, Children, FC } from 'react'

import styled from '~/design/styled'

type Props = Readonly<{
  className?: string
  css?: InterpolationWithTheme<any>
  style?: CSSProperties
}>

export const TableCell: FC<Props> = ({ children, className, css, style }) => (
  <View className={className} css={css} style={style}>
    {Children.map(children, child =>
      typeof child === 'string' ? <TableText>{child}</TableText> : child,
    )}
  </View>
)

const View = styled.div({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  minWidth: 0,
  '*': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
})

const TableText = styled.span()
