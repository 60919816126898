import React, { FC, useState } from 'react'

import { Table, TableCell, TableRow } from '~/components/Table'
import styled from '~/design/styled'
import { MaterialsArrayType } from '~/shared/models/models'
import { FlowTypes } from '~/shared/models/siteProperties'
import { UpdateSitePropertiesFn } from '~/shared/hooks/useSiteProperties'
import { getLeatherFlowLabel } from './leatherFlow'

import { ParameterBlockTemplate } from '../ParameterBlockTemplate'
import { LeatherFlowModal } from './leatherFlowModal'
import LeatherIcon from '~/design/icons/leatherIcon'
import { ColorDot } from '~/components/colorDot'

type Props = Readonly<{
  materials: MaterialsArrayType
  leatherFlow: FlowTypes | null
  updateSiteProperties: UpdateSitePropertiesFn
}>

export const LeatherFlowBlock: FC<Props> = ({
  materials,
  leatherFlow,
  updateSiteProperties,
}) => {
  const [modalVisible, setModalVisible] = useState(false)

  const openModal = () => {
    setModalVisible(true)
  }
  const closeModal = () => {
    setModalVisible(false)
  }

  const submitAndClose = async (data: FlowTypes) => {
    await updateSiteProperties({ flowTypes: data })
    setModalVisible(false)
  }

  return (
    <>
      <ParameterBlockTemplate
        buttonLabelType={leatherFlow === null ? 'fill' : 'modify'}
        icon={<LeatherIcon />}
        onButtonClick={openModal}
        title="Distribution de matière"
      >
        <Table layout={[{ flex: 1 }, { marginLeft: 'auto' }]}>
          {materials.materials.map(material => (
            <TableRow key={material.id}>
              <TableCell>
                <Row>
                  <ColorDot color={material.color} />
                  <Label>{material.name}</Label>
                </Row>
              </TableCell>
              <TableCell>
                {getLeatherFlowLabelOrDefault(leatherFlow, material.id)}
              </TableCell>
            </TableRow>
          ))}
        </Table>
      </ParameterBlockTemplate>
      {modalVisible && (
        <LeatherFlowModal
          materials={materials}
          leatherFlow={leatherFlow}
          onClose={closeModal}
          onSubmit={submitAndClose}
        />
      )}
    </>
  )
}

const getLeatherFlowLabelOrDefault = (
  leatherFlow: FlowTypes | null,
  matId: string,
): string => {
  const value = leatherFlow?.find(q => q.material.id === matId)?.value
  return value === undefined ? '-' : getLeatherFlowLabel(value)
}

const Row = styled.div({
  display: 'flex',
  alignItems: 'center',
})

const Label = styled.div(({ theme }) => ({
  color: theme.colors.text,
  fontWeight: theme.fontWeights.default.medium,
  marginLeft: theme.space.s40,
}))
