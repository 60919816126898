import React, { useState } from 'react'
import styled from '~/design/styled'

import { Table, TableCell, TableRow } from '~/components/Table'
import { ParameterBlockTemplate } from '../ParameterBlockTemplate'
import UpdatePasswordModal from '~/components/parameters/password-block/UpdatePasswordModal'
import LockIcon from '~/design/icons/lockIcon'
import { UpdateSitePropertiesFn } from '~/shared/hooks/useSiteProperties'

type PasswordBlockProps = {
  workshopManagerCredentials: string | null
  updateSiteProperties: UpdateSitePropertiesFn
}
export const PasswordBlock = ({
  workshopManagerCredentials,
  updateSiteProperties,
}: PasswordBlockProps) => {
  const [isModalOpen, setModalState] = useState<boolean>(false)
  const closeModal = () => setModalState(false)

  const handlePasswordSubmit = (workshopManagerCredentials: string) =>
    updateSiteProperties({ workshopManagerCredentials })

  const formType = workshopManagerCredentials === null ? 'fill' : 'modify'

  return (
    <>
      <ParameterBlockTemplate
        title="Mot de passe responsable d'atelier"
        icon={<LockIcon />}
        onButtonClick={() => setModalState(true)}
        buttonLabelType={formType}
      >
        <Table layout={[{ flex: 1 }, { marginLeft: 'auto' }]}>
          <TableRow>
            <TableCell>
              <RowLabel>Mot de passe</RowLabel>
            </TableCell>
            <TableCell>{workshopManagerCredentials ?? '-'}</TableCell>
          </TableRow>
        </Table>
      </ParameterBlockTemplate>

      {isModalOpen && (
        <UpdatePasswordModal
          formType={formType}
          onClose={closeModal}
          onSubmit={handlePasswordSubmit}
        />
      )}
    </>
  )
}

const RowLabel = styled.div(({ theme }) => ({
  fontWeight: theme.fontWeights.default.medium,
  color: theme.colors.text,
}))
