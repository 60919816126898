/** @jsx jsx */

import { InterpolationWithTheme, jsx } from '@emotion/core'
import {
  PrimaryMediumButton,
  SecondaryMediumButton,
} from '@hermes/design-system'
import { FC, SyntheticEvent, useCallback, useState } from 'react'

import { Theme } from '~/design/theme'
import styled from '~/design/styled'
import { Modal, ModalProps } from '~/components/modal'

import { Area, createImage, cropImage } from './ImageCroppingTools'
import { ImageCroppingView } from './ImageCroppingView'

export type ImageCroppingModalProps = Readonly<
  Pick<ModalProps, 'size' | 'title'> & {
    onCancel: () => void
    onConfirm: (source: string) => void
    source: string
  }
>

const aspect = 1
const fillStyle = 'white'
const maxSize = 512
const minZoom = 0.5
const restrictPosition = false

export const ImageCroppingModal: FC<ImageCroppingModalProps> = ({
  onCancel,
  onConfirm,
  size,
  source,
  title,
}) => {
  const [crop, setCrop] = useState<Area | undefined>()

  const handleCropComplete = useCallback(
    (_croppedArea: Area, croppedAreaPixels: Area) => {
      setCrop(croppedAreaPixels)
    },
    [],
  )

  const handleCancel = useCallback(
    (event: SyntheticEvent) => {
      event.preventDefault()
      onCancel()
    },
    [onCancel],
  )

  const handleConfirm = useCallback(
    async (event: SyntheticEvent) => {
      event.preventDefault()
      if (crop === undefined) {
        return source
      }
      const image = await createImage(source)
      const destSize =
        crop.width > maxSize
          ? { height: maxSize, width: maxSize }
          : { height: crop.height, width: crop.width }
      const croppedBlob = await cropImage(image, crop, destSize, fillStyle)
      const croppedObjectUrl = URL.createObjectURL(croppedBlob)
      onConfirm(croppedObjectUrl)
    },
    [crop, onConfirm, source],
  )

  return (
    <Modal backdrop={false} size={size} title={title}>
      <ImageCroppingView
        aspect={aspect}
        css={cssImageCroppingView}
        minZoom={minZoom}
        onCropComplete={handleCropComplete}
        restrictPosition={restrictPosition}
        source={source}
      />
      <ModalActions>
        <SecondaryMediumButton css={cssModalAction} onClick={handleCancel}>
          Annuler
        </SecondaryMediumButton>
        <PrimaryMediumButton css={cssModalAction} onClick={handleConfirm}>
          Valider
        </PrimaryMediumButton>
      </ModalActions>
    </Modal>
  )
}

const cssImageCroppingView: InterpolationWithTheme<Theme> = theme => ({
  alignSelf: 'center',
  borderRadius: theme.borderRadius.small,
  height: '380px',
  marginBottom: theme.layout.l45,
  overflow: 'hidden',
  width: '480px',
})

const ModalActions = styled.div({
  alignItems: 'center',
  alignSelf: 'center',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
})

const cssModalAction: InterpolationWithTheme<Theme> = theme => ({
  margin: `0px ${theme.layout.l10}`,
  width: '140px',
})
