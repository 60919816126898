import { InputText, PrimaryMediumButton, Select } from '@hermes/design-system'
import React, { FC, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { array, object, string } from 'yup'

import { Hint } from '~/components/hint'
import { Label } from '~/components/inputs'
import { Modal, ModalProps } from '~/components/modal'
import styled from '~/design/styled'
import { SitesArrayType, sortSites } from '~/shared/models/sites'

type Props = Readonly<
  Pick<ModalProps, 'onClose'> & {
    onSubmit: (data: FormData) => Promise<void>
    sites: SitesArrayType
  }
>

export type FormData = Readonly<{
  id: string
  adminIds: readonly string[]
}>

const validationSchema = object<FormData>({
  id: string().required('Vous devez sélectionner une maroquinerie'),
  adminIds: array(string())
    .compact()
    .required('Vous devez renseigner au minimum un administrateur'),
})

const maxAdminIdsCount = 5

export const AddSiteModal: FC<Props> = ({ onClose, onSubmit, sites }) => {
  const ids = useMemo(
    () => sortSites(sites).map(site => ({ label: site.name, value: site.id })),
    [sites],
  )

  const { control, formState, handleSubmit } = useForm<FormData>({
    mode: 'onChange',
    validationSchema,
  })

  return (
    <Modal
      onClose={formState.isSubmitting ? undefined : onClose}
      title="Nouvelle maroquinerie"
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <StyledInputLabel htmlFor="id">Maroquinerie</StyledInputLabel>
        <Controller
          as={
            <StyledSelect
              id="id"
              isDisabled={formState.isSubmitting}
              options={ids}
              placeholder="Sélectionnez le code de dépôt"
            />
          }
          control={control}
          name="id"
        />
        <StyledInputLabel htmlFor="adminId">
          Administrateurs du site
        </StyledInputLabel>
        {[...new Array(maxAdminIdsCount)].map((_, index) => {
          const name = `adminIds[${index}]`
          return (
            <Controller
              as={
                <StyledInputText
                  {...(index === 0 ? { id: 'adminId' } : undefined)}
                  disabled={formState.isSubmitting}
                  placeholder="Renseignez un compte Atlas"
                />
              }
              control={control}
              key={name}
              name={name}
            />
          )
        })}
        <StyledHint>
          Vous devez renseigner au minimum un administrateur
        </StyledHint>
        <StyledButton
          disabled={
            !formState.dirty || !formState.isValid || formState.isSubmitting
          }
        >
          Ajouter
        </StyledButton>
      </Form>
    </Modal>
  )
}

const Form = styled.form({
  display: 'flex',
  flexDirection: 'column',
})

const StyledInputLabel = styled(Label)(({ theme }) => ({
  marginBottom: theme.space.s30,
}))

const StyledSelect = styled(Select)(({ theme }) => ({
  marginBottom: theme.space.s80,
}))

const StyledInputText = styled(InputText)(({ theme }) => ({
  marginBottom: theme.space.s40,
}))

const StyledHint = styled(Hint)(({ theme }) => ({
  alignSelf: 'center',
  marginBottom: theme.layout.l55,
  marginTop: `calc(${theme.space.s80} - ${theme.space.s40})`,
  width: '68%',
}))

const StyledButton = styled(PrimaryMediumButton)({
  alignSelf: 'center',
  width: '34.16%',
})
