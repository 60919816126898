export function formatDateTime(date: Date): string {
  return new Intl.DateTimeFormat('FR', {
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    month: 'numeric',
    year: 'numeric',
  }).format(date)
}

export function formatHumanDate(date: Date): string {
  return new Intl.DateTimeFormat('FR', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  }).format(date)
}
