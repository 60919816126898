import React, { FC } from 'react'
import { Radio, RadioGroup } from '~/components/Radio/index'
import { TableCell, TableRow } from '~/components/Table'
import styled from '~/design/styled'

interface Props {
  workshopId: string
  workshopName: string
  nbCuttingZones: number
  onChange?: (value: number) => void
  value?: number
}

export const WorkshopRow: FC<Props> = ({
  workshopId,
  workshopName,
  nbCuttingZones,
  onChange,
  value,
}) => {
  const nbCuttingZonesArray = Array.from(new Array(nbCuttingZones))
  return (
    <RadioGroup
      name={workshopId}
      onChange={value => onChange?.(parseInt(value))}
      value={value?.toString()}
    >
      <TableRowStyled>
        <TableCellStyled>{workshopName}</TableCellStyled>
        {nbCuttingZonesArray.map((_radioCell, index) => (
          <TableCell key={`${workshopId}-${index + 1}`}>
            <Radio
              id={`${workshopId}-${index + 1}`}
              value={(index + 1).toString()}
            />
          </TableCell>
        ))}
        <TableCell>
          <Radio id={`${workshopId}-d`} value="-1" />
        </TableCell>
      </TableRowStyled>
    </RadioGroup>
  )
}

const TableCellStyled = styled(TableCell)(({ theme }) => ({
  color: theme.colors.text,
  fontWeight: theme.fontWeights.default.medium,
}))

const TableRowStyled = styled(TableRow)(({ theme }) => ({
  width: '100%',
  borderBottom: `1px solid ${theme.colors.greys.light25}`,
}))
