import { InterpolationWithTheme } from '@emotion/core'
import { UIText } from '@hermes/design-system'
import React, { FC } from 'react'

import HintIcon from '~/design/icons/hintIcon'
import styled from '~/design/styled'

type Props = Readonly<{
  className?: string
  css?: InterpolationWithTheme<any>
}>

export const Hint: FC<Props> = ({ children, className, css }) => {
  return (
    <Container className={className} css={css}>
      <HintIcon />
      <Text>{children}</Text>
    </Container>
  )
}

const Container = styled.div(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.colors.greys.light10,
  borderRadius: theme.borderRadius.small,
  display: 'flex',
  padding: `${theme.space.s40} ${theme.space.s55}`,
}))

const Text = styled(UIText)(({ theme }) => ({
  color: theme.colors.black,
  flex: 1,
  fontSize: theme.fontSizes.small,
  fontWeight: theme.fontWeights.default.medium,
  marginInlineStart: theme.space.s40,
}))
