import React, { FC } from 'react'

type Props = Readonly<{
  className?: string
  fill?: string
}>

const HintIcon: FC<Props> = ({ className, fill = '#FC4D21' }) => (
  <svg width="13" height="13" className={className}>
    <defs>
      <path
        id="hintIcon"
        d="M6.5 0A6.502 6.502 0 0113 6.5 6.502 6.502 0 016.5 13 6.502 6.502 0 010 6.5 6.502 6.502 0 016.5 0zm0 5.043a.804.804 0 00-.806.806v4.06a.805.805 0 101.612.001V5.85a.804.804 0 00-.806-.807zm0-2.759a.85.85 0 00-.848.85.846.846 0 00.848.848.85.85 0 00.848-.849.846.846 0 00-.848-.849z"
      />
    </defs>
    <use fill={fill} xlinkHref="#hintIcon" />
  </svg>
)

export default HintIcon
