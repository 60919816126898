import React, { useCallback, useState } from 'react'

import { Hint } from '~/components/hint'
import { Layout } from '~/components/layout'
import { useToaster } from '~/components/Toaster'
import {
  AddSiteModal,
  FormData as AddSiteModalFormData,
} from '~/components/sites/addSiteModal'
import { HeaderSites } from '~/components/sites/headerSites'
import { SitesList } from '~/components/sites/sitesList'
import { useAdministrator } from '~/shared/contexts/AdministratorContext'
import { getSiteName } from '~/shared/models/sites'

const SitesScreen = () => {
  const {
    data: { currentUser, unconfiguredSites },
    mutators: { createSite },
  } = useAdministrator()
  const { showToast } = useToaster()

  const [addSiteModalShown, setAddSiteModalShown] = useState(false)
  const showAddSiteModal = useCallback(() => {
    setAddSiteModalShown(true)
  }, [])
  const hideAddSiteModal = useCallback(() => {
    setAddSiteModalShown(false)
  }, [])

  const submitSite = useCallback(
    async (data: AddSiteModalFormData) => {
      const siteName = getSiteName(unconfiguredSites, data.id)
      try {
        await createSite(data)
        showToast({
          intent: 'success',
          message: `Le site ${siteName ?? ''} a bien été créé`,
        })
      } catch (error) {
        showToast({
          intent: 'failure',
          message: `Le site ${siteName ?? ''} n'a pu être créé`,
        })
      } finally {
        setAddSiteModalShown(false)
      }
    },
    [createSite, showToast, unconfiguredSites],
  )

  const addSiteStatus =
    currentUser.status === 'superadmin'
      ? unconfiguredSites.length === 0
        ? 'disabled'
        : 'enabled'
      : 'hidden'

  return unconfiguredSites.length > 0 ||
    currentUser.configurableSites.length > 0 ? (
    <Layout>
      <HeaderSites
        addSiteStatus={addSiteStatus}
        onAddSiteClicked={showAddSiteModal}
      />
      <SitesList sites={currentUser.configurableSites} />
      {addSiteModalShown ? (
        <AddSiteModal
          onClose={hideAddSiteModal}
          onSubmit={submitSite}
          sites={unconfiguredSites}
        />
      ) : (
        <></>
      )}
    </Layout>
  ) : (
    <Layout>
      <Hint>Vous n'êtes rattaché à aucune maroquinerie.</Hint>
    </Layout>
  )
}

export default SitesScreen
