import React from 'react'
import styled from '~/design/styled'
import { AddingBtn } from '~/components/addingBtn'
import { BackNav } from '~/components/backNav'

type Props = Readonly<{
  addModelsDisabled: boolean
  onAddModelsClicked: () => void
  backLink: {
    url: string
    label?: string
  }
}>

export const HeaderModels: React.FC<Props> = ({
  addModelsDisabled,
  onAddModelsClicked,
  backLink,
}) => {
  return (
    <HeaderModelsStyled>
      <BackNavStyled
        pageTitle={`Département ${backLink.label?.toLocaleLowerCase()}`}
        route={backLink.url}
      />
      <AddingBtn
        label="Ajouter un modèle"
        onAdding={onAddModelsClicked}
        disabled={addModelsDisabled}
      />
    </HeaderModelsStyled>
  )
}

const HeaderModelsStyled = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
})

const BackNavStyled = styled(BackNav)(({ theme }) => ({
  fontSize: theme.fontSizes.title,
  height: '32px',
  marginBottom: '0px',
  marginTop: theme.layout.l35,
}))
