import useHttp from '~/shared/apis/useHttp'
import { useParams } from 'react-router'

import {
  SitePropertiesType,
  PartialSiteProperties,
} from '~/shared/models/siteProperties'
import { getSiteProperties, putSiteProperties } from '~/shared/apis/routes'
import { ErrorObject } from '~/shared/apis/http-errors'

export type UpdateSitePropertiesFn = (
  newProperties: PartialSiteProperties,
) => Promise<void>
export type SiteProperties = {
  parameters?: SitePropertiesType
  updateSiteProperties: UpdateSitePropertiesFn
  parametersError?: ErrorObject
}
export function useSiteProperties(): SiteProperties {
  const { id: siteId } = useParams()

  const {
    data: parameters,
    mutate,
    error: parametersError,
  } = useHttp(
    siteId !== undefined ? ['siteProperties', siteId] : null,
    (_, siteId) => getSiteProperties(siteId),
  )

  const updateSiteProperties = async (
    newProperties: Partial<SitePropertiesType>,
  ) => {
    if (siteId !== undefined) {
      const data = await putSiteProperties(siteId, newProperties)
      mutate(data, false)
    }
  }

  return { parameters, updateSiteProperties, parametersError }
}
