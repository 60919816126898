import React, { FC } from 'react'

import styled from '~/design/styled'

type Props = Readonly<{
  className?: string
  htmlFor?: string
}>

export const Label: FC<Props> = ({ children, className, htmlFor }) => (
  <StyledLabel className={className} htmlFor={htmlFor}>
    {children}
  </StyledLabel>
)

const StyledLabel = styled.label(({ theme }) => ({
  color: theme.colors.greys.light30,
  fontFamily: theme.fonts.default,
  fontSize: theme.fontSizes.legend,
  fontWeight: theme.fontWeights.default.bold,
  display: 'inline-block',
}))
