import { RouterTabs } from '@hermes/design-system'
import React, { FC } from 'react'
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from 'react-router-dom'

import { BackNav } from '~/components/backNav'
import { Layout } from '~/components/layout'
import styled from '~/design/styled'
import AdminsScreen from '~/pages/site/adminsScreen'
import ModelsDeptsScreen from '~/pages/site/modelsDeptsScreen'
import ModelsScreen from '~/pages/site/models/modelsScreen'
import ParametersScreen from '~/pages/site/parametersScreen'
import WorkersScreen from '~/pages/site/workersScreen'
import WorkshopsScreen from '~/pages/site/workshopsScreen'
import SiteValidationScreen from '~/pages/site/siteValidationScreen'
import { useAdministrator } from '~/shared/contexts/AdministratorContext'
import { SiteConfigurationProvider } from '~/shared/contexts/SiteConfigurationContext'
import { useSiteProperties } from '~/shared/hooks/useSiteProperties'
import { getSiteName } from '~/shared/models/sites'

import { ParametersBanner } from './ParametersBanner'

type Params = Readonly<{
  id: string
}>

const SiteScreen: FC = () => {
  const history = useHistory()
  const { params, path, url } = useRouteMatch<Params>()

  const {
    data: { currentUser },
  } = useAdministrator()
  const siteProperties = useSiteProperties()

  const routes = [
    ...(currentUser.status === 'superadmin'
      ? [{ label: 'Administrateurs', to: `${url}/administrators` }]
      : []),
    { label: 'Configuration', to: `${url}/parameters` },
    { label: 'Modèles', to: `${url}/models-departments` },
    { label: 'Ateliers', to: `${url}/workshops` },
    { label: 'Artisans', to: `${url}/workers` },
    { label: 'Validation', to: `${url}/validation` },
  ]

  const shouldDisplayParametersBanner = !siteProperties.parameters?.isCompleted
  const shouldDisplayClosingDatesBanner =
    siteProperties.parameters?.isCompleted &&
    siteProperties.parameters?.closedDates?.find(date => date > new Date()) ===
      undefined
  const navigateToParametersTab = () => {
    history.push(`${url}/parameters`)
  }

  return (
    <Layout>
      <BackNav
        pageTitle={getSiteName(currentUser.configurableSites, params.id)}
        route="/sites"
      />

      <SiteConfigurationProvider siteId={params.id} status={currentUser.status}>
        {shouldDisplayParametersBanner && (
          <ParametersBanner onClick={navigateToParametersTab}>
            Pensez à paramétrer la maroquinerie, sans cela l’application Flux
            Tiré ne fonctionnera pas dans les ateliers.
          </ParametersBanner>
        )}
        {shouldDisplayClosingDatesBanner && (
          <ParametersBanner onClick={navigateToParametersTab}>
            Pensez à renseigner les dates de fermeture du site.
          </ParametersBanner>
        )}
        <Tabs routes={routes} />
        <Switch>
          <Route path={`${path}/administrators`}>
            <AdminsScreen />
          </Route>
          <Route exact path={`${path}/models-departments`}>
            <ModelsDeptsScreen />
          </Route>
          <Route path={`${path}/models-departments/:deptId/models`}>
            <ModelsScreen />
          </Route>
          <Route path={`${path}/workshops`}>
            <WorkshopsScreen
              cuttingZones={siteProperties.parameters?.cuttingZones}
            />
          </Route>
          <Route path={`${path}/workers`}>
            <WorkersScreen />
          </Route>
          <Route path={`${path}/validation`}>
            <SiteValidationScreen />
          </Route>
          <Route path={`${path}/parameters`}>
            <ParametersScreen {...siteProperties} />
          </Route>
          <Route>
            <Redirect to={routes[0].to} />
          </Route>
        </Switch>
      </SiteConfigurationProvider>
    </Layout>
  )
}

const Tabs = styled(RouterTabs)(({ theme }) => ({
  borderBottom: `1px solid ${theme.colors.greys.light20}`,
}))

export default SiteScreen
