/** @jsx jsx */

import { InterpolationWithTheme, jsx } from '@emotion/core'
import { FC } from 'react'

type Props = Readonly<{
  className?: string
  css?: InterpolationWithTheme<any>
}>

const PlaceholderBeltIcon: FC<Props> = ({ className, css }) => (
  <svg
    width="100"
    height="44"
    viewBox="0 0 100 44"
    className={className}
    css={css}
  >
    <path
      fill="#FFF"
      d="M94 7H73V5c-.092-2.969-2.02-4.991-5-5H31.713C28 0 26 2 26 6v1H6c-4 0-6 2-6 6v18c0 4 2 6 6 6h20v1c.009 3.034 2 6 6 6h36.287c3.026-.009 4.704-1.966 4.713-5v-3h21c3.75 0 6-2.224 6-5V13c0-4-2-6-6-6zM26 33H6c-1.257 0-2-.727-2-2V13c0-1.273.743-2 2-2h20v6c-3 0-4 2.644-4 5s1 5 4 5v6zm2-13h9c1.026 0 2 .895 2 2s-.974 2-2 2h-9c-1.024-.003-1.997-.897-2-2 .003-1.103.976-1.997 2-2zm11-3v-4h21v18H39v-3.924C42 27 43 24.285 43 22c0-2.285-1-5-4-5zm30 21.75c0 .625-.62 1.25-1.238 1.25H32c-1 0-2-1-2-2V27h5v6c.003 1 1.007 1.997 2 2h25c.993-.003 1.997-1 2-2V11c-.003-1-1.007-1.997-2-2H37c-1.238 0-2 1-2 2v6h-5V7c0-1.25 1.238-2 2.476-2H68c.619 0 1 .375 1 1v32.75zM96 30c-.003 1.024-.989 1.997-2 2H73V11h21c1.011.003 1.997.976 2 2v17z"
    />
  </svg>
)

export default PlaceholderBeltIcon
